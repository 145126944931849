import { ActionCreator } from 'redux';
import { IScope } from '@elixir/fx'

import {
  IAddTeamRolesAction,
  IDeleteTeamRolesAction,
  TeamSettingsActionTypes,
  IGetTeamRolesAction,
  IGetUserSchedules,
  IEditTeamRolesAction,
  IGetTeamUsersAction,
  IEditTeamUsersAction,
  ISaveSchedule,
  IDeleteSchedules,
  IGetTimeZones,
  IUpdateGeneralSettingsAction,
  IGetGeneralSettingsAction,
  IGetIcmContacts,
  IGetIcmOnCallSchedule,
} from './Types';
import {
  RoleDetailsCollection,
  UserDetailsCollection,
  ScheduleDetailsCollection,
  GeneralSettings,
  IcmOnCallPosition,
  IcmScheduleType,
  UserScheduleDetails,
} from '../Models/teamsettings.data';

/**
 * Edit team user action.
 * @param teamsTeamId TeamsId of the team.
 * @param userDetails UserDetails to edit.
 * @param scope Scope of the action.
 */
export const editTeamUsersAction: ActionCreator<IEditTeamUsersAction> = (teamsTeamId: string,
  userDetails: UserDetailsCollection, scope: IScope) => ({
    type: TeamSettingsActionTypes.EDIT_TEAM_USERS,
    payload: {
      teamsTeamId,
      userDetails,
      scope
    }
  })

/**
 * Get team users action.
 * @param teamsTeamId TeamsId of the team.
 * @param scope Scope of the action.
 */
export const getTeamUsersAction: ActionCreator<IGetTeamUsersAction> = (teamsTeamId: string, scope: IScope) => ({
  type: TeamSettingsActionTypes.GET_TEAM_USERS,
  payload: {
    teamsTeamId: teamsTeamId,
    scope: scope
  }
})

/**
 * Get team roles action.
 * @param teamsTeamId TeamsId of the team.
 * @param scope Scope of the action.
 */
export const getTeamRolesAction: ActionCreator<IGetTeamRolesAction> = (teamsTeamId: string, scope: IScope) => ({
  type: TeamSettingsActionTypes.GET_TEAM_ROLES,
  payload: {
    teamsTeamId: teamsTeamId,
    scope: scope
  }
})

/**
 * Add new team roles action.
 * @param teamsTeamId TeamsId of the team.
 * @param roleDetailsCollection RoleDetails collection to add.
 * @param scope Scope of the action.
 */
export const addTeamRolesAction: ActionCreator<IAddTeamRolesAction> = (teamsTeamId: string,
  roleDetailsCollection: RoleDetailsCollection, scope: IScope) => ({
    type: TeamSettingsActionTypes.ADD_TEAM_ROLES,
    payload: {
      teamsTeamId: teamsTeamId,
      roleDetailsCollection: roleDetailsCollection,
      scope: scope,
    }
  })

/**
 * Edit team roles action.
 * @param teamsTeamId TeamsId of the team.
 * @param roleDetailsCollection RoleDetails collection to edit.
 * @param scope Scope of the action.
 */
export const editTeamRolesAction: ActionCreator<IEditTeamRolesAction> = (teamsTeamId: string,
  roleDetailsCollection: RoleDetailsCollection, scope: IScope) => ({
    type: TeamSettingsActionTypes.EDIT_TEAM_ROLES,
    payload: {
      teamsTeamId: teamsTeamId,
      roleDetailsCollection: roleDetailsCollection,
      scope: scope,
    }
  })

/**
 * Delete team roles action.
 * @param teamsTeamId TeamsId of the team.
 * @param roleDetailsCollection RoleDetails collection to delete.
 * @param scope Scope of the action.
 */
export const deleteTeamRolesAction: ActionCreator<IDeleteTeamRolesAction> = (teamsTeamId: string,
  roleDetailsCollection: RoleDetailsCollection, scope: IScope) => ({
    type: TeamSettingsActionTypes.DELETE_TEAM_ROLES,
    payload: {
      teamsTeamId: teamsTeamId,
      roleDetailsCollection: roleDetailsCollection,
      scope: scope,
    }
  })

/**
 * Gets the user schedules on a team
 * @param teamsTeamId The team whose schedules are being retrieved for
 * @param scope The scope of the container in which schedules are being displayed
 */
export const getUserSchedules: ActionCreator<IGetUserSchedules> = (teamsTeamId: string, scope: IScope) => ({
  type: TeamSettingsActionTypes.GET_USER_SCHEDULES,
  payload: {
    teamsTeamId,
    scope
  }
})

/**
 * Creates or updates schedules
 * 
 * @param teamsTeamId Team schedule belongs to
 * @param schedules The schedules to save
 */
export const saveSchedules: ActionCreator<ISaveSchedule> = (teamsTeamId: string, schedules: UserScheduleDetails, unselectedSchedules?: UserScheduleDetails) => ({
  type: TeamSettingsActionTypes.SAVE_SCHEDULE,
  payload: {
    teamsTeamId,
    schedules,
    unselectedSchedules
  }
})

/**
 * Deletes schedules
 * 
 * @param teamsTeamId Team schedule belongs to
 * @param schedules The schedules to save
 */
export const deleteSchedules: ActionCreator<IDeleteSchedules> = (teamsTeamId: string, schedules: ScheduleDetailsCollection) => ({
  type: TeamSettingsActionTypes.DELETE_SCHEDULE,
  payload: {
    teamsTeamId,
    schedules,
  }
})

/**
 * Gets IcM contacts by alias
 * @param aliases The aliases of the contacts to be retrieved
 */
export const getIcmContacts: ActionCreator<IGetIcmContacts> = (aliases: string[], scope: IScope) => ({
  type: TeamSettingsActionTypes.GET_ICM_CONTACTS,
  payload: {
    aliases,
    scope
  }
})

/**
 * Gets an IcM on-call schedule
 */
export const getIcmOnCallSchedule: ActionCreator<IGetIcmOnCallSchedule> = (contactId: number, teamId: number, onCallPosition: IcmOnCallPosition, onCallScheduleType: IcmScheduleType, scope: IScope) => ({
  type: TeamSettingsActionTypes.GET_ICM_ONCALL_SCHEDULE,
  payload: {
    contactId,
    teamId,
    onCallPosition,
    onCallScheduleType,
    scope
  }
})

/**
 * Gets the available timezones on Ava
 */
export const getTimezones: ActionCreator<IGetTimeZones> = () => ({
  type: TeamSettingsActionTypes.GET_TIME_ZONES,
  payload: {
  }
})

export const updateGeneralSettingsAction: ActionCreator<IUpdateGeneralSettingsAction> = (teamsTeamId: string, generalSettings: GeneralSettings) => ({
    type: TeamSettingsActionTypes.UPDATE_GENERAL_SETTINGS,
    payload: {
        teamsTeamId,
        generalSettings
    }
})

/**
 * Gets the general settings for the given team
 * @param teamsTeamId 
 */
export const fetchGeneralSettingsAction: ActionCreator<IGetGeneralSettingsAction> = (teamsTeamId: string, scope: IScope) => ({
  type: TeamSettingsActionTypes.GET_GENERAL_SETTINGS,
  payload: {
      teamsTeamId,
      scope
  }
})
