import React from 'react';
import { CommandButton } from '@fluentui/react';
import { HomeTileHeader } from '../HomeTileHeader/HomeTileHeader';
import './WelcomeTile.scss'
import { ElxTooltip } from '@elixir/components';

const AVA_DOCS = 'https://azsupportdocs.azurewebsites.net/ava/articles/GettingStarted.html';

export const WelcomeTile = (): JSX.Element => {
    const title = 'Get started';
    const textContent = 'Learn how to get started with Ava';
    const tooltipText = 'View get started steps'

    return (
        <div>
            <div className="home-tile welcome-tile">
                <HomeTileHeader title={title}></HomeTileHeader>
                <div className="welcome-text">{textContent}</div>
                <div className="tile-action">
                <ElxTooltip content={tooltipText}>
                    <CommandButton iconProps={{ iconName: 'View' }} text={'View get started steps'}  href={AVA_DOCS}/>
                </ElxTooltip>
                </div>
            </div>
        </div>
    );
}