import React from 'react';
import { ElxIconButton } from '@elixir/components';
import { IContextualMenuProps } from '@fluentui/react';
import './HomeTileHeader.scss';

interface IHomeTileHeaderProps {
    title: string,
    description?: string,
    menuProps?: IContextualMenuProps
}

export const HomeTileHeader = (props: IHomeTileHeaderProps): JSX.Element => {
    const title = props.title;
    const description = props.description 
        ? <div className="description ms-fontSize-16">{props.description}</div>
        : undefined;

    const moreMenu = props.menuProps ? 
        <ElxIconButton 
            text='Menu'
            iconProps={{iconName: 'MoreVertical'}} 
            onRenderMenuIcon={() => null} 
            menuProps={props.menuProps}
        /> : undefined;

    return(
        <div>
            <div className="home-header-section">
                <div className="header ms-fontSize-18">{title}</div>
                {moreMenu}
            </div>
            {description}
        </div>
    );
}