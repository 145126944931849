import { Http, uxAddContainerMessageAction, IScopedAction } from '@elixir/fx';
import { ITrackingContext, getLogger } from '@elixir/telemetry';
import { MessageBarType } from '@fluentui/react';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, all, takeLatest } from '@redux-saga/core/effects';
import { IGetUserTeamsAction, AvaActionTypes, IGetTeamAction, IUpdateCurrentUserSettingsAction, IGetTeamCasesAction } from './Types';
import { SagaIterator } from '@redux-saga/core';
import { AxiosResponse, AxiosPromise } from 'axios';

import Config from '../../../Config';
import { Teams, AvaResponse, TeamSearchResult, FeatureToggleDetails, UserSettings, UserSettingsDetails, CaseDataDetails } from '../core.data';

import {
    setPageLoading,
    setUserTeams,
    setActiveTeam,
    setTeamUser,
    setIsActiveTeamCanaryEnrolled,
    setCurrentUserSettings,
    setTeamChannels,
    setCaseOfferings,
    setTeamCases,
    showNoTeamDialog,
    initializeContainer
} from '../Store/Slice';
import { setOverview, resetOverview } from '../../TeamOverview/Store/Slice';
import { actions } from '../../TeamSettings/Store/Slice';
import { scopedAuthentication, scopedAuthenticationValue } from '../../../Common/Saga.utility';

const AVA_TEAM_API: string = Config.apiUrl + '/api/v1/teams';
const AVA_FEATURE_TOGGLE_API: string = Config.apiUrl + '/api/v1/featuretoggle/';
const AVA_ME_API: string = Config.apiUrl + '/api/v1/me';

export interface IGetIsActiveTeamCanaryEnrolledPayload {
    teamsTeamId: string;
}

export interface IUpdateIsActiveTeamCanaryEnrolledPayload {
    teamsTeamId: string;
    isEnrolled: boolean;
}

/**
 * Inital call to API. Gets list of all teams current user is on, sets an inital active team, 
 * and determines user's current role on that team.
 * @param action The get action defined to initate this effect
 */
function* getUserTeams(action: IGetUserTeamsAction): SagaIterator {
    try {
        // Set loading state of page to true
        yield put(initializeContainer({isLoading: true, displayError: false}));

        // Get all the teams the current user is on
        const res: AxiosResponse<AvaResponse<Teams>> = yield call(Http.get, action, `${AVA_TEAM_API}`);

        // Update state to contain all list of teams user is on
        yield put(setUserTeams(res.data.data));

        if (res.data.data.teams.length < 1) {
            yield put(showNoTeamDialog(true));
            return;
        }

        // Update state to reflect first team on list as active team
        yield put(setActiveTeam(res.data.data.teams[0]));

        // Get more details from search endpoint
        const teamSearchResult: AxiosResponse<AvaResponse<TeamSearchResult>> = 
            yield call (buildTeamQuery, action, res.data.data.teams[0].teamsTeamId, action.payload.queryString);

        // Update details on the current team user
        yield put(setTeamUser(teamSearchResult.data.data.currentUser));
        yield scopedAuthentication(teamSearchResult.data.data.currentUser, action.payload.scope);

        if(scopedAuthenticationValue(teamSearchResult.data.data.currentUser, action.payload.scope)){
            // Based on query string, storing values
            const query = action.payload.queryString?.split(',');

            if(query?.some(q => q === 'overview')) {
                yield put(setOverview(teamSearchResult.data.data.overview));
            }
            if(query?.some(q => q === 'schedules')) {
                yield put(actions.setUserSchedules({schedules: teamSearchResult.data.data.schedules}));
            }
            if(query?.some(q => q === 'users')) {
                yield put(actions.setTeamUsers({userDetails: teamSearchResult.data.data.users}));
            }
            if(query?.some(q => q === 'channels')) {
                yield put(setTeamChannels(teamSearchResult.data.data.channels));
            }
            if(query?.some(q => q === 'offerings')) {
                yield put(setCaseOfferings(teamSearchResult.data.data.offerings));
            }
            if(query?.some(q => q === 'roles')) {
                yield put(actions.setTeamRoles({roleDetails: teamSearchResult.data.data.roles}));
            }
            if(query?.some(q => q === 'settings')) {
                yield put(actions.setTeamSettings(teamSearchResult.data.data.settings));
            }
            if(query?.some(q => q === 'canary')) {
                yield put(setIsActiveTeamCanaryEnrolled(teamSearchResult.data.data.isTeamCanaryEnrolled));
            }

            // Get the current user's settings
            const userSettingsResult: AxiosResponse<AvaResponse<UserSettingsDetails>> = yield call(Http.get, action, `${AVA_ME_API}/settings/`);

            const userSettings: UserSettings = {
                isOutOfOffice: userSettingsResult.data.data.isOof,
                isBulkNotifyEnabled: userSettingsResult.data.data.isBulkNotifyEnabled
            };
            yield put(setCurrentUserSettings(userSettings));
        }
    } catch (err: any) {
            yield put(uxAddContainerMessageAction({
                type: MessageBarType.error,
                message: err.response?.data?.Message
                || 'An error occurred while fetching user\'s teams, please try again later.',
            }, action.payload.scope));
            getLogger().error(err);
    }
    finally{
        // Set the current loading state to false
        yield put(setPageLoading(false));
    }
}

/**
 * Given a Teams team id, this effect gets information regarding that team from Ava.
 * @param action Get team action
 */
function* getTeam(action: IGetTeamAction): SagaIterator {
    // Set loading state of page to true
    yield put(initializeContainer({isLoading: true, displayError: false}));

    try {
        if (action.payload.switchTeam) {
            yield put(resetOverview());
        }

        // Get more details from search endpoint
        const teamSearchResult: AxiosResponse<AvaResponse<TeamSearchResult>> = 
            yield call (buildTeamQuery, action, action.payload.teamsTeamId, action.payload.queryString);

        // Update details on the current team user
        yield put(setTeamUser(teamSearchResult.data.data.currentUser));
        yield scopedAuthentication(teamSearchResult.data.data.currentUser, action.payload.scope);

        // Update state to reflect first team on list as active team
        yield put(setActiveTeam(teamSearchResult.data.data));

        if (scopedAuthenticationValue(teamSearchResult.data.data.currentUser, action.payload.scope)){
            // Based on query string, storing values
            const query = action.payload.queryString?.split(',');

            if(query?.some(q => q === 'overview')) {
                yield put(setOverview(teamSearchResult.data.data.overview));
            }
            if(query?.some(q => q === 'schedules')) {
                yield put(actions.setUserSchedules({schedules: teamSearchResult.data.data.schedules}));
            }
            if(query?.some(q => q === 'users')) {
                yield put(actions.setTeamUsers({userDetails: teamSearchResult.data.data.users}));
            }
            if(query?.some(q => q === 'channels')) {
                yield put(setTeamChannels(teamSearchResult.data.data.channels));
            }
            if(query?.some(q => q === 'offerings')) {
                yield put(setCaseOfferings(teamSearchResult.data.data.offerings));
            }
            if(query?.some(q => q === 'roles')) {
                yield put(actions.setTeamRoles({roleDetails: teamSearchResult.data.data.roles}));
            }
            if(query?.some(q => q === 'settings')) {
                yield put(actions.setTeamSettings(teamSearchResult.data.data.settings));
            }
            if(query?.some(q => q === 'canary')) {
                yield put(setIsActiveTeamCanaryEnrolled(teamSearchResult.data.data.isTeamCanaryEnrolled));
            }
        }
    } catch (err: any) {
            yield put(uxAddContainerMessageAction({
                type: MessageBarType.error,
                message: err.response?.data?.Message
                || 'An error occurred while fetching team, please try again later.',
            }, action.payload.scope));
        
            getLogger().error(err);
    }
    finally{
        // Set the current loading state to false
        yield put(setPageLoading(false));
    }
}

export function* updateIsActiveTeamCanaryEnrolled(action: PayloadAction<IUpdateIsActiveTeamCanaryEnrolledPayload> & ITrackingContext): SagaIterator {
    try {
        // isEnrolled indicates the new state
        const featureToggleUpdateRequest = {
            Scope: "Team",
            TeamId: action.payload.teamsTeamId,
            IsEnrolled: action.payload.isEnrolled
        };
        yield call(Http.patch, action, `${AVA_FEATURE_TOGGLE_API}/CanaryFeatures/`, featureToggleUpdateRequest);

        const getFeatureToggleResult: AxiosResponse<AvaResponse<FeatureToggleDetails>> = 
            yield call(Http.get, action, `${AVA_FEATURE_TOGGLE_API}/CanaryFeatures/?scope=team&teamid=${action.payload.teamsTeamId}`);

        yield put(setIsActiveTeamCanaryEnrolled(getFeatureToggleResult.data.data.isEnrolled));

    } catch (err: any) {
        getLogger().error(err);
    }
}

/**
 * Updates settings for the current user.
 * @param action Update user settings action
 */
export function* updateCurrentUserSettings(action: IUpdateCurrentUserSettingsAction): SagaIterator {
    try {
        const userSettings = action.payload.userSettings;
        yield call(Http.patch, action, `${AVA_ME_API}/settings/`, 
            {IsOof: userSettings.isOutOfOffice, IsBulkNotifyEnabled: userSettings.isBulkNotifyEnabled});
        const updateSettingsResult: AxiosResponse<AvaResponse<UserSettingsDetails>> = 
            yield call(Http.get, action, `${AVA_ME_API}/settings/`);
        
        userSettings.isOutOfOffice = updateSettingsResult.data.data.isOof;
        userSettings.isBulkNotifyEnabled = updateSettingsResult.data.data.isBulkNotifyEnabled
        yield put(setCurrentUserSettings(userSettings));

    } catch (err: any) {
        getLogger().error(err);   
    }
}

/**
 * Get all cases for the current team.
 * @param action Team cases action.
 */
function* getTeamCases(action: IGetTeamCasesAction): SagaIterator {
    yield put(initializeContainer({isLoading: true, displayError: false}));
    try {
        // Get all the cases the current team
        const res: AxiosResponse<AvaResponse<CaseDataDetails[]>> = yield call(Http.get, action, `${AVA_TEAM_API}/${action.payload.teamsTeamId}/cases`);

        getLogger().trace("Team Cases: ", res.data.data);

        // Update state to contain list of team cases
        yield put(setTeamCases(res.data.data));
    } catch (err: any) {
        yield put(uxAddContainerMessageAction({
            type: MessageBarType.error,
            message: err.response?.data?.Message
            || 'An error occurred while fetching team cases, please try again later.',
        }, action.payload.scope));

        getLogger().error(err);
    }
    finally {
        // Set the current loading state to false
        yield put(setPageLoading(false));
    }
}

/**
 * Builds a request url for the team endpoint
 * @param action The action that triggered the calling effect
 * @param teamsTeamId The target team
 * @param queryString Comma delimited string
 */
function buildTeamQuery(action: IScopedAction, teamsTeamId: string, queryString?: string): AxiosPromise {
    let teamApi = `${AVA_TEAM_API}/${teamsTeamId}/`;
    teamApi += queryString ? `?include=${queryString}` : '';

    return Http.get(action, teamApi);
}



function* watchCoreActions(): IterableIterator<any> {
    yield all([
        yield takeLatest(AvaActionTypes.GET_USER_TEAMS, getUserTeams),
        yield takeLatest(AvaActionTypes.GET_TEAM, getTeam),
        yield takeLatest(AvaActionTypes.UPDATE_IS_ACTIVE_TEAM_CANARY_ENROLLED, updateIsActiveTeamCanaryEnrolled),
        yield takeLatest(AvaActionTypes.UPDATE_CURRENT_USER_SETTINGS, updateCurrentUserSettings),
        yield takeLatest(AvaActionTypes.GET_TEAM_CASES, getTeamCases),
    ]);
}

// tslint:disable-next-line: no-any
function* coreSaga(): IterableIterator<any> {
    yield watchCoreActions();
}

export default coreSaga;
