import React from 'react';
import { IGeneralTileProps } from '../General';
import { AutoArchiveDetails } from '../../../Models/teamsettings.data';
import { SettingsTileHeader } from '../SettingsTileHeader/SettingsTileHeader';
import '../General.scss';

interface IAutoArchiveTileProps extends IGeneralTileProps {
    settings: AutoArchiveDetails
}

export const AutoArchiveTile = (props: IAutoArchiveTileProps): JSX.Element => {
    const title = 'Auto archive';
    const statusMessage = props.settings.isEnabled ?
        `Requests will be automatically archived after ${props.settings.hours} hours.` :
        'Auto archive is disabled.';

    return (
        <div className="setting-tile simple-tile">
            <div className="simple-tile-header">
                <SettingsTileHeader title={title} tab={1} onEdit={props.onEdit} />
            </div>
            <div className="ms-Grid ms-sm9 ms-lg9 simple-tile-description" dir="ltr">
                <div className="ms-Grid-col simple-tile-text">
                    {statusMessage}
                </div>
            </div>
        </div>
    );
}