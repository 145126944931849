import { IElixirState } from '@elixir/fx';
import { ICoreState } from './State';

const coreSelector = (state: IElixirState) => state.modules['core'] as ICoreState;

/**
 * Used to abstractly get values from the store
 */
export const coreSelectors = {
  /**
   * Gets the current user
   */
  getCurrentUser: (state: IElixirState) => coreSelector(state).currentTeamUser,
  /**
   * Gets the current user's global settings
   */
  getCurrentUserSettings: (state: IElixirState) => coreSelector(state).currentTeamUserSettings,
  /**
   * Gets the active team
   */
  getActiveTeam: (state: IElixirState) => coreSelector(state).activeTeam,
  /** 
   * Gets the show contact support flag
   */
  getShowContactSupportDialog: (state: IElixirState) => coreSelector(state).showContactSupport,
  /**
   * Gets whether the page is loading or not
   */
  getPageLoading: (state: IElixirState) => coreSelector(state).pageLoading,
  /**
   * Gets all the teams user is in
   */
  getUserTeams: (state: IElixirState) => coreSelector(state).teams,
  /**
   * Gets indicator for active team canary enrollment
   */
  getIsActiveTeamCanaryEnrolled: (state: IElixirState) => coreSelector(state).isActiveTeamCanaryEnrolled,
  /**
     * Gets all the channels in a team
     */
  getChannels: (state: IElixirState) => coreSelector(state).channels,
  /**
   * Gets all the available case offerings
   */
  getCaseOfferings: (state: IElixirState) => coreSelector(state).offerings,
  /**
   * Gets indicator for no teams available dialog
   */
  getShowNoTeamAvailableDialog: (state: IElixirState) => coreSelector(state).showNoTeamDialog,
  /**
   * Gets all cases for current team
   */
  getTeamCases: (state: IElixirState) => coreSelector(state).teamCases,
    /**
   * Gets indicator for auto-refresh.
   */
  getAutoRefreshEnabled: (state: IElixirState) => coreSelector(state).autoRefreshEnabled,
  /**
   * Gets the error message for error page.
   */
  getErrorMessage: (state: IElixirState) => coreSelector(state).errorMessage,
  /**
   * Gets the error page toggle
   */
  getDisplayError: (state: IElixirState) => coreSelector(state).displayError,
};