import { IScopedAction, IScope } from '@elixir/fx';

/**
 * Ava Overview action type.
 */
export enum AvaOverviewActionTypes {
  GET_PENDING_CASES = '$ava/avaoverview/GET_PENDING_CASES',
  GET_MY_CASES = '$ava/avaoverview/GET_MY_CASES',
}

/**
 * Ava Overview actions.
 */
export type AvaOverviewActions = IGetPendingCasesAction | IGetMyCasesAction;

export interface IGetPendingCasesAction extends IScopedAction {
  type: AvaOverviewActionTypes.GET_PENDING_CASES,
  payload: {
    scope: IScope,
  }
}

export interface IGetMyCasesAction extends IScopedAction {
  type: AvaOverviewActionTypes.GET_MY_CASES,
  payload: {
    scope: IScope,
  }
}
