import { IAvaOverviewState } from './State';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CaseDataDetails } from '../../Core/core.data';

/**
 * Initial state for the avaOverview core state.
 */
const initialState: IAvaOverviewState = {
};

const avaOverviewSlice = createSlice({
    name: '$ava_overview',
    initialState,
    reducers: {
        setPendingCases(state: IAvaOverviewState, action: PayloadAction<CaseDataDetails[]>): void {
            state.pendingCases = action.payload;
        },
        setMyCases(state: IAvaOverviewState, action: PayloadAction<CaseDataDetails[]>): void {
            state.myCases = action.payload;
        },
    }
});

export const {
    setPendingCases,
    setMyCases,
} = avaOverviewSlice.actions;

export default avaOverviewSlice.reducer;
