import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { coreSelectors } from '../../../Core/Store/Selectors';
import { avaOverviewSelectors } from '../../Store/Selectors';
import { createScope } from '@elixir/fx';
import { ElxScopedTableContainer } from '@elixir/fx/lib/components/Container/Variations';
import { ElxLink, IElxColumn } from '@elixir/components';
import { IColumn, SelectionMode } from '@fluentui/react';
import { CaseDataDetails, avaContainers } from '../../../Core/core.data';
import { fetchPendingCasesAction } from '../../Store/Actions';
import { renderAutoRefreshToggleSubActions } from '../../../PageHeader/Components/AutoRefreshToggle/AutoRefreshToggle';
import {
    getCasesTableActions,
    getTeamFilter,
    getChannelFilter,
    getSeverityFilter,
    getOfferingFilter,
    convertOffering,
    getTimeWaitedForHelp,
    CASE_REFRESH_WAIT_TIME,
    getLifeAndSafetyString,
    getTimeDifferenceInMinutes,
    sortCaseNumber,
    sortWaitTime,
} from '../../../../Common/Case.utility';
import {
    getTruncatedTime,
    renderEmptyView,
} from '../../../../Common/Core.utility';
import { AvaErrorBoundary } from '../../../Core/Containers/AvaErrorBoundary/AvaErrorBoundary';
import './PendingCases.scss';
import { mainTabFix } from '../../../../Common/TabIndexFix';
import { NotificationMessage } from '../../../Core/Containers/NotificationBanner/NotificationBanner'

/**
 * Pending cases page table.
 */
export interface PendingCasesTable {
    caseNumber: JSX.Element,
    teamChannel: string,
    caseTitle: string,
    created: string,
    expiredTimers: number,
    lifeAndSafety: string,
    waitTime: string,
    severity: string,
    offering: string,
    teamsUrl: string,
    raveUrl?: string,
    serviceDeskUrl?: string,
    ascUrl?: string,
    dynamicsUrl?: string,
    lifeAndSafetyPriority?: number
}

/**
 * Columns for the pending cases table
 */
const columns: IElxColumn[] = [
    {
        key: 'column_CaseNumber',
        name: 'Case Number',
        fieldName: 'caseNumber',
        minWidth: 100,
        maxWidth: 120,
        isResizable: true,
        sortBy: (column: IElxColumn) => {
            return (a: any, b: any) => {
                return sortCaseNumber(column, a, b);
            };
        }
    },
    {
        key: 'column_TeamChannel',
        name: 'Team/Channel',
        fieldName: 'teamChannel',
        minWidth: 100,
        maxWidth: 180,
        isResizable: true,
    },
    {
        key: 'column_CaseTitle',
        name: 'Case Title',
        fieldName: 'caseTitle',
        minWidth: 100,
        maxWidth: 400,
        isResizable: true,
    },
    {
        key: 'column_Created',
        name: 'Created (UTC)',
        fieldName: 'created',
        minWidth: 100,
        maxWidth: 110,
        isResizable: true
    },
    {
        key: 'column_SupportEngineer',
        name: 'Support Engineer',
        fieldName: 'supportEngineer',
        minWidth: 140,
        maxWidth: 200,
        isResizable: true,
    },
    {
        key: 'column_ExpiredTimers',
        name: 'Expired timers',
        fieldName: 'expiredTimers',
        minWidth: 105,
        maxWidth: 110,
        isResizable: true
    },
    {
        key: 'column_LifeAndSafety',
        name: 'Life & Safety',
        fieldName: 'lifeAndSafety',
        minWidth: 100,
        maxWidth: 160,
        isResizable: true
    },
    {
        key: 'column_WaitTime',
        name: 'Wait Time',
        fieldName: 'waitTime',
        minWidth: 80,
        maxWidth: 100,
        isResizable: true,
        sortBy: (column: IElxColumn) => {
            return (a: any, b: any) => {
                return sortWaitTime(column, a, b);
            };
        }
    },
    {
        key: 'column_Severity',
        name: 'Severity',
        fieldName: 'severity',
        minWidth: 60,
        maxWidth: 100,
        isResizable: true
    },
    {
        key: 'column_Offering',
        name: 'Offering',
        fieldName: 'offering',
        minWidth: 120,
        maxWidth: 220,
        isResizable: true
    }
];

const emptyColumns: IColumn[] = []

const scope = createScope(avaContainers.AVA_PENDING_CASES);

/**
 * This container is the Pending Cases on the portal
 */
export const PendingCases = (): JSX.Element => {
    const dispatch = useDispatch();

    const isPageLoading = useSelector(coreSelectors.getPageLoading);
    const pendingCasesSelector = useSelector(avaOverviewSelectors.getPendingCases);
    const pendingCases = pendingCasesSelector || [];
    const items = getPendingCasesTable(pendingCases);
    const autoRefreshEnabled = useSelector(coreSelectors.getAutoRefreshEnabled) || false;

    const [visibleHeader, setVisibleHeader] = React.useState(true);

    // Fetch pending cases for the first time.
    React.useEffect(() => {
        setVisibleHeader(true);
        dispatch(fetchPendingCasesAction(scope));
        mainTabFix();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Auto-refresh every 30secs to fetch pending cases 
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (!isPageLoading && autoRefreshEnabled) {
                setVisibleHeader(true);
                dispatch(fetchPendingCasesAction(scope));
            }
        }, CASE_REFRESH_WAIT_TIME);

        return () => clearInterval(interval)
    });

    return (
        <AvaErrorBoundary>
            <ElxScopedTableContainer
                containerProps={{
                    headerText: 'Pending cases',
                    onRenderSubHeader: () => { return (<div className="subHeader">Cases that require your expertise across all of your scheduled channels</div>) },
                    isLoading: isPageLoading,
                    subActions: renderAutoRefreshToggleSubActions(),
                    message: NotificationMessage
                }}
                tableProps={{
                    columns: visibleHeader ? columns : emptyColumns,
                    items: sortCases(items),
                    stickyHeader: true,
                    actions: getCasesTableActions(),
                    actionsName: visibleHeader ? '' : ' ',
                    selectionMode: items.length > 0 ? SelectionMode.single : SelectionMode.none
                }}
                searchBoxProps={{
                    filters: [
                        getTeamFilter(pendingCases),
                        getChannelFilter(pendingCases),
                        getSeverityFilter(pendingCases),
                        getOfferingFilter(pendingCases),
                    ],
                    maxInlineFilters: 4
                }}
                onRenderEmpty={() => {
                    if (!isPageLoading) { setVisibleHeader(false); }
                    return renderEmptyView("No pending cases found.")
                }
                }
            >
            </ElxScopedTableContainer>
        </AvaErrorBoundary>
    );
}

/**
 * Sort cases as below:
 * 1. Life&Safety Cases:
 *      a. Priority - ascending
 *      b. Severity - ascending ('1', 'A', 'B', 'C')
 *      c. ExpiredTimers - descending (max expired timers to min)
 * 2. Non-Life&Safety Cases:
 *      a. Severity - ascending
 *      b. ExpiredTimers - descending
 * @param cases 
 */
function sortCases(cases: PendingCasesTable[]): PendingCasesTable[] {
    return cases.sort((a, b) => {
        if (b.lifeAndSafetyPriority !== undefined && a.lifeAndSafetyPriority !== undefined) {
            if (b.lifeAndSafetyPriority === a.lifeAndSafetyPriority) {
                if (b.severity === a.severity) {
                    return (b.expiredTimers - a.expiredTimers);
                }
                return a.severity.localeCompare(b.severity);
            }
            return (a.lifeAndSafetyPriority - b.lifeAndSafetyPriority);
        }
        else if (b.lifeAndSafetyPriority !== undefined) {
            return 1;
        }
        else if (a.lifeAndSafetyPriority !== undefined) {
            return -1;
        }
        else {
            if (b.severity === a.severity) {
                return (b.expiredTimers - a.expiredTimers);
            }
            return a.severity.localeCompare(b.severity);
        }
    })
}

/**
 * Method to map pending cases data to pending cases table.
 * @param pendingCases Pending cases data.
 */
function getPendingCasesTable(pendingCases: CaseDataDetails[]): PendingCasesTable[] {
    var result: PendingCasesTable[] = [];
    if (pendingCases) {
        result = pendingCases
            .map(c => ({
                caseNumber: <ElxLink href={c.linkToCase} target='_blank'>{c.caseNumber}</ElxLink>,
                caseId: c.caseNumber,
                teamChannel: c.team.name + '/' + c.channelName,
                caseTitle: c.title,
                created: getTruncatedTime(c.timeCreated),
                supportEngineer: c.supportEngineer.name,
                expiredTimers: c.expiredTimerCount < 0 ? 0 : c.expiredTimerCount,
                lifeAndSafety: getLifeAndSafetyString(c),
                waitTime: getTimeWaitedForHelp(c),
                severity: c.severity,
                offering: convertOffering(c.offering),
                teamsUrl: c.linkToCase,
                raveUrl: c.toolsUrls['rave'],
                serviceDeskUrl: c.toolsUrls['sd'],
                ascUrl: c.toolsUrls['asc'],
                dynamicsUrl: c.toolsUrls['dfM'],
                lifeAndSafetyPriority: c.customerProgramPriority,
                timeWaitedInMinutes: getTimeWaitedForHelp(c, getTimeDifferenceInMinutes)
            }));
    }
    return result;
}
