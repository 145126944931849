import { ActionCreator } from 'redux';
import { IScope } from '@elixir/fx'
import {
  IGetPendingCasesAction,
  IGetMyCasesAction,
  AvaOverviewActionTypes,
} from './Types';

/**
 * Fetch all pending cases for the current user.
 * @param scope The scope of the container this action was fired from.
 */
export const fetchPendingCasesAction: ActionCreator<IGetPendingCasesAction> = (scope: IScope) => ({
  type: AvaOverviewActionTypes.GET_PENDING_CASES,
  payload: {
      scope,
  }
})

/**
* Fetch all cases the current user owns.
* @param scope The scope of the container this action was fired from.
*/
export const fetchMyCasesAction: ActionCreator<IGetMyCasesAction> = (scope: IScope) => ({
  type: AvaOverviewActionTypes.GET_MY_CASES,
  payload: {
      scope,
  }
})
