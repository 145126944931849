/**
 * This will fix issue with Elixir main tab accessibility
 * @param None
 * @returns None
 */
export const mainTabFix = () => {
    if(document.getElementsByTagName("main").length > 0){
		document.getElementsByTagName("main")[0].tabIndex = -1
	}
}
