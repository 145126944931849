import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Toggle } from '@fluentui/react';
import { UserDetails } from '../../../Core/core.data';
import { coreSelectors } from '../../../Core/Store/Selectors';
import { updateIsActiveTeamCanaryEnrolledAction } from '../../../Core/Store/Actions';
import '../Toggle.scss';

interface IPreviewToggleProps {
    user?: UserDetails
}

/**
 * Provides the toggle switch for Ava feature preview toggle.
 */
export const PreviewToggle = (props: IPreviewToggleProps): JSX.Element => {
    const dispatch = useDispatch();
    const isTeamCanaryEnrolled = useSelector(coreSelectors.getIsActiveTeamCanaryEnrolled) ?? false;
    const activeTeamId = useSelector(coreSelectors.getActiveTeam)?.teamsTeamId;

    return(
        <div className="toggle">
            <Toggle
                label={
                    <span>Preview Features (
                        <Link
                            key={1}
                            target="_blank"
                            href={`https://aka.ms/avadocs#preview-features`}
                        >
                            Learn more about Preview Features
                        </Link>
                        ):
                    </span>                    
                }
                disabled={!props.user?.isAdmin || isTeamCanaryEnrolled === undefined}
                checked={isTeamCanaryEnrolled}
                inlineLabel
                onText="On"
                offText="Off"
                onChange={
                    (e: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                        dispatch(updateIsActiveTeamCanaryEnrolledAction(activeTeamId, checked));
                    }
                }
            />
        </div>
    );
}