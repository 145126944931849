import React from 'react';
import moment from 'moment';
import { IColumn, SelectionMode } from '@fluentui/react';
import { ElxTable } from '@elixir/components';
import { IcmOnCallShift } from '../../Models/teamsettings.data';
import './IcmSchedule.scss';

export interface IcmScheduleProps {
    title?: string,
    icmOnCallShifts?: IcmOnCallShift[],
}

interface IcmOnCallShiftItem {
    startTime: string,
    endTime: string
};

const dateTimeFormat = 'dddd, YYYY-MM-DD HH:mm UTC';

const columns: IColumn[] = [
    {
        key: 'column_StartTime',
        name: 'Start time',
        fieldName: 'startTime',
        minWidth: 120,
        maxWidth: 250,
        isResizable: false
    },
    {
        key: 'column_EndTime',
        name: 'End time',
        fieldName: 'endTime',
        minWidth: 120,
        isResizable: false
    }
];

/**
 * Implements the IcM schedule component.
 */
export const IcmOnCallSchedule = (props: IcmScheduleProps): JSX.Element => {
    let title = props?.title;
    
    const [onCallShiftItems, setOnCallShiftItems] = React.useState<IcmOnCallShiftItem[] | undefined>();
    
    React.useEffect(() => {
        const items = (props.icmOnCallShifts?.map(shift => (
            {
                startTime: getDateTimeSpanString(shift.startTime),
                endTime: getDateTimeSpanString(shift.endTime)
            }
        )));
        setOnCallShiftItems(items);
    }, [props.icmOnCallShifts]);

    let table = null;
    
    if (props.icmOnCallShifts && props.icmOnCallShifts?.length > 0) {
        table = <ElxTable 
            columns={columns}
            items={onCallShiftItems || []}
            selectionMode={SelectionMode.none}
            ariaLabelForGrid="IcM OnCall Schedule"
        />;
    }

    return(
        <div className="icm-schedule">
            <div className="heading ms-fontSize-13" aria-live="assertive">{title}</div>
            {table}
        </div>
    );
}

function getDateTimeSpanString(dateTime: Date): string {
    const dt = moment(dateTime);
    return dt.format(dateTimeFormat);
}