import {FontIcon} from '@fluentui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { coreSelectors } from '../../Store/Selectors';
import { ElxScopedContainer, createScope } from '@elixir/fx';

import { PropsWithChildren } from '@elixir/components';

import './AvaErrorBoundary.scss';
import { avaContainers } from '../../core.data';
import { PageHeader } from '../../../PageHeader/Containers/PageHeader';

const schedulesScope = createScope(avaContainers.AVA_ERROR);

export const AvaErrorBoundary = (props: PropsWithChildren<{}>) : JSX.Element => {
    const message = useSelector(coreSelectors.getErrorMessage);
    const displayError = useSelector(coreSelectors.getDisplayError);
    const isPageLoading = useSelector(coreSelectors.getPageLoading);

    const renderError = () : JSX.Element => {
        return( 
            <ElxScopedContainer
                headerText = ""
                isLoading = { isPageLoading }
            >
                <div className="error">
                    <FontIcon iconName="Sad" className="icon" />
                    <h3>{message}</h3>
                </div>
            </ElxScopedContainer>
        );
    }

    const renderBody = !displayError ? props.children : renderError();

    return (<div>{renderBody}</div>);
}