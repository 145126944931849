import { ScheduleDetailsCollection, IcmOnCallPosition } from "../../Models/teamsettings.data";

/**
 * Takes the length of a list and a label object and creates a display that either comes out as
 * 
 * 'Label' OR 'Label +length-1'
 * 
 * @param label The label used for the display
 * @param length Length of the list the label came from
 */
export function createScheduleDisplay(label: string, length: number): string {
    return length > 1 ? `${label} +${length - 1}` : label;
}

/**
 * Check if the schedule collection is valid to be saved.
 * @param schedules schedules to be validated.
 */
export function checkIfValidSchedule(schedules: ScheduleDetailsCollection[] | undefined): boolean | undefined {
    return schedules &&
        (schedules?.length || 0) > 0 &&
        (schedules?.every(s => s.user && s.scheduleDetails && s.scheduleDetails.length > 0 &&
            s.scheduleDetails.every(details => details.channel && details.offering &&
                ((details.start && details.end && details.timeZone && details.daysOfWeek) ||
                    (details.icmSchedule?.icmTeam && details.icmSchedule.icmTeam.id > 0 &&
                        details.icmSchedule?.icmOnCallRole && details.icmSchedule?.icmOnCallRole.valueOf() !== IcmOnCallPosition.None))
            )));
}