import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { IDropdownOption } from '@fluentui/react';
import { ElxDropdown } from '@elixir/components';
import { ISchedulePanelProps, IcmTeam, IcmOnCallPosition, IcmScheduleType, IcmContact } from '../../Models/teamsettings.data';
import { teamSettingsSelectors } from '../../Store/Selectors';
import { getIcmContacts, getIcmOnCallSchedule } from '../../Store/Actions';
import { actions } from '../../Store/Slice';
import { IcmOnCallSchedule, IcmScheduleProps } from '../IcmOnCallSchedule/IcmOnCallSchedule';
import '../ScheduleDetailsComponents.scss';
import { coreSelectors } from '../../../Core/Store/Selectors';

const onCallRoleOptions: IDropdownOption[] = [
    {
        key: '1',
        text: 'Primary',
        data: IcmOnCallPosition.Primary
    },
    {
        key: '2',
        text: 'Backup',
        data: IcmOnCallPosition.Backup
    },
];

/**
 * Implements the IcM schedule component.
 */
export const IcmScheduleDetails = (props: ISchedulePanelProps): JSX.Element => {
    const title = 'Integrate IcM on-call schedule';
    const teamPlaceholder = 'Select team';
    const rolePlaceholder = 'Select role';
    const userPlaceholder = 'Select a user';
    const dispatch = useDispatch();
    
    const isPageLoading = useSelector(coreSelectors.getPageLoading);
    const schedulesToSave = useSelector(teamSettingsSelectors.getSchedulesToSave);
    const selectedIcmContacts = useSelector(teamSettingsSelectors.getSelectedIcmContacts);
    const selectedTeam = useSelector(teamSettingsSelectors.getSelectedIcmTeam);
    const selectedOnCallRole = useSelector(teamSettingsSelectors.getSelectedIcmOnCallRole);
    const shifts = useSelector(teamSettingsSelectors.getSelectedIcmOnCallShifts);

    const [commonTeams, setCommonTeams] = React.useState<IcmTeam[] | undefined>();
    const [teamOptions, setTeamOptions] = React.useState<IDropdownOption[] | []>();
    const [selectedTeamKey, setSelectedTeamKey] = React.useState<string>('');
    const [selectedRoleKey, setSelectedRoleKey] = React.useState<string>('');
    const [selectedUser, setSelectedUser] = React.useState<IcmContact | undefined>();
    const [icmOnCallScheduleProps, setIcmOnCallScheduleProps] = React.useState<IcmScheduleProps>({});
    const [userDropdownDisabled, setUserDropdownDisabled] = React.useState<boolean>(true);

    React.useEffect(() => {
        if(schedulesToSave && schedulesToSave.length > 0) {
            const updatedSchedules = _.cloneDeep(schedulesToSave);
    
            updatedSchedules.forEach(s => {
                const alias = s.user.alias.substring(0, s.user.alias.indexOf('@'));
                const contactId = selectedIcmContacts?.find(c => { return c.alias?.toLocaleLowerCase() === alias })?.id || 0;

                s.scheduleDetails.forEach(details => {
                    details.timeZone = undefined;
                    details.daysOfWeek = undefined;
                    details.start = undefined;
                    details.end = undefined;
                    details.icmSchedule = {
                        icmContactId: contactId,
                        icmTeam: selectedTeam,
                        icmOnCallRole: selectedOnCallRole,
                        icmScheduleType: IcmScheduleType.All,
                        icmOnCallShifts: undefined
                    };
                });
            });
    
            dispatch(actions.setSchedulesToSave(updatedSchedules));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIcmContacts, selectedTeam, selectedOnCallRole]);

    React.useEffect(() => {
        if (schedulesToSave) {
            const userAliases = schedulesToSave.map(s => s.user.alias.substring(0, s.user.alias.indexOf('@'))) || [];
            dispatch(getIcmContacts(userAliases.filter((value, index, self) => { return self.indexOf(value) === index; })));
        }

        // Clear selected icm properties on enter
        dispatch(actions.setSelectedIcmOnCallShifts([]));
        dispatch(actions.setSelectedIcmOnCallRole(IcmOnCallPosition.None));
        dispatch(actions.setSelectedIcmTeam({id: 0, tenant: {id: 0}}));

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);  

    React.useEffect(() => {
        if (selectedIcmContacts && selectedIcmContacts.length > 0) {
            let teams = _.cloneDeep(selectedIcmContacts[0].teams);

            for (let i = teams.length-1; i >= 0; i--) {
                const id = teams[i].id;

                for (let j = 1; j < selectedIcmContacts.length; j++) {
                    if (!selectedIcmContacts[j].teams.find(t => {
                        return t.id === id
                    })) {
                        teams.splice(i, 1);
                        break;
                    }
                }
            }

            setCommonTeams(teams);
        }
    }, [selectedIcmContacts]);
    
    React.useEffect(() => {
        if (commonTeams) {
            const options: IDropdownOption[] = commonTeams.map(team => (
                {
                    key: `team_options_${team.id}`,
                    text: `${team.tenant.name} / ${team.name}`,
                    data: team
                }
            ));
            setTeamOptions(options);
        }
    }, [commonTeams]);

    React.useEffect(() => {
        if (shifts && selectedUser) {
            const role = onCallRoleOptions.find(r => r.key === selectedOnCallRole?.toString());
            const icmProps: IcmScheduleProps = {
                title: (shifts?.length > 0 || isPageLoading) ? '' : `The selected user has no scheduled ${role?.text} on-call shifts on ${selectedTeam?.tenant.name}/${selectedTeam?.name}.`,
                icmOnCallShifts: shifts 
            };
            setIcmOnCallScheduleProps(icmProps);
        }
    }, [shifts, selectedOnCallRole, selectedTeam, selectedUser, isPageLoading]);

    React.useEffect(() => {
        const disabled = !selectedTeam || !selectedOnCallRole;
        setUserDropdownDisabled(disabled);

        if (!disabled && selectedUser) {
            dispatch(getIcmOnCallSchedule(selectedUser.id, selectedTeam?.id, selectedOnCallRole, 'All'));
        }
    }, [dispatch, selectedTeam, selectedOnCallRole, selectedUser]);

    // Gets the user's name from the Ava object
    // Some contact names from IcM are incorrect (e.g. 'billg billg' instead of 'Bill Gates')
    function getUserName(alias: string): string {
        const user = schedulesToSave?.find(s => s.user.alias.startsWith(alias + '@'))?.user;
        return user?.name || '';
    }

    const userOptions: IDropdownOption[] = selectedIcmContacts?.map(contact => (
        {
            key: `user_options_${contact.alias}`,
            text: getUserName(contact.alias),
            data: contact
        }
    )) || [];
    
    const onTeamChange = (option?: IDropdownOption) => {
        const key = (option?.key || '').toString();
        dispatch(actions.setSelectedIcmTeam(option?.data));
        setSelectedTeamKey(key);
    }
    
    const onOnCallRoleChange = (option?: IDropdownOption) => {
        const key = (option?.key || '').toString();
        dispatch(actions.setSelectedIcmOnCallRole(option?.data));
        setSelectedRoleKey(key);
    }
    
    const onContactChange = (option?: IDropdownOption) => {
        dispatch(getIcmOnCallSchedule(option?.data?.id, selectedTeam?.id, selectedOnCallRole, 'All'));
        setSelectedUser(option?.data);
    };

    return (
        <div>
            <div className="component-header component-title" role="heading" aria-level={2}>{title}</div>
            <div className="ms-fontSize-13">
                <form className="team-settings-tile">
                    <ElxDropdown
                        label="Service / Team"
                        multiSelect={false}
                        options={teamOptions || []}
                        selectedKey={selectedTeamKey}
                        placeHolder={teamPlaceholder}
                        disabled={false}
                        onChange={(e, option, index) => onTeamChange(option)}
                    />
                    <ElxDropdown
                        label="On-Call Role"
                        multiSelect={false}
                        options={onCallRoleOptions}
                        selectedKey={selectedRoleKey}
                        placeHolder={rolePlaceholder}
                        disabled={false}
                        onChange={(e, option, index) => onOnCallRoleChange(option)}
                    />
                    <div className="component-section-header component-title">Review schedules</div>
                    <div className="component-section-header-text">Here are the next five schedules pulled for validation</div>
                    <div>
                        <ElxDropdown
                            label="User"
                            options={userOptions}
                            emptyOption={false}
                            placeholder={userPlaceholder}
                            disabled={userDropdownDisabled}
                            onChange={(e, option, index) => onContactChange(option)}
                        />
                        <IcmOnCallSchedule {...icmOnCallScheduleProps} />
                    </div>
                </form>
            </div>
        </div>
    );
}