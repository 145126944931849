import { RoleDetails, UserDetails, ChannelDetails, CaseOfferingDetails, PermissionDetails } from '../../Core/core.data'
import { IPanelProps } from '../../TopNavBar/Models/topnav.data';
import { ScheduleView, UsersTableView } from './teamsettings.view';

export enum OrgType {
    Unknown,
    PG,
    CSS
};

export const scheduleTypes: ScheduleType[] = [
    {
        key: "Weekly",
        label: "Weekly"
    },
    {
        key: "AlwaysOn",
        label: "24/7"
    },
    {
        key: "IcM",
        label: "IcM on-call"
    },
];

/**
 * RoleDetails collection interface.
 */
export interface RoleDetailsCollection {
    /**
     * RoleDetails collection.
     */
    roleDetails: Array<RoleDetails>
}

/**
 * UserDetails collection interface.
 */
export interface UserDetailsCollection {
    /**
     * UserDetails collection.
     */
    userDetails: Array<UserDetails>
}

/**
 * Collection of user schedule details. That is user details and all their schedules.
 */
export interface UserScheduleDetails {
    /**
     * The user and all of the schedules.
     */
    schedules: Array<ScheduleDetailsCollection>,
}

/**
 * A collection of schedule details
 */
export interface ScheduleDetailsCollection {
    /**
     * User who schedule belongs to
     */
    user: UserDetails,
    /**
     * Collection of schedule details
     */
    scheduleDetails: Array<ScheduleDetails>
}

/**
 * Details pertaining to a user's schedules
 */
export interface ScheduleDetails {
    /**
     * Id of this schedule entry. If undefined, considered a new schedule
     */
    scheduleId?: number,
    /**
     * Type of this schedule entry. Weekly, AlwaysOn, or IcM
     */
    scheduleType: string,
    /**
     * Channel schedule is for
     */
    channel?: ChannelDetails,
    /**
     * Case offering the schedule is for
     */
    offering?: CaseOfferingDetails,
    /**
     * Start time
     */
    start?: string,
    /**
     * End time
     */
    end?: string,
    /**
     * Time zone schedule is for
     */
    timeZone?: string,
    /**
     * Comma delimited string that says what days a user is available on
     */
    daysOfWeek?: string,

    icmSchedule?: IcmSchedule,

    /**
     * The role override for a given schedule
     */
    role?: RoleDetails,
}

/**
 * Role panel properties interface.
 */
export interface IRolePanelProps extends IPanelProps {
    /**
     * Role.
     */
    role?: RoleDetails,
    /**
     * TeamsId of the team.
     */
    teamsTeamId: string
}

/**
 * User panel properties interface.
 */
export interface IUserPanelProps extends IPanelProps {
    /**
     * Users.
     */
    users?: UsersTableView[],
}

/**
 * Schedules to modifiy
 */
export interface ISchedulePanelProps {
    /**
     * The schedule itself (if being edited)
     */
    schedule?: ScheduleView[],
}

/**
 * Details a schedule type
 */
export interface ScheduleType {
    /**
     * The key (identifier) of the schedule type
     */
    [key: string]: string,
    /**
     * The user-facing name of the schedule type
     */
    label: string,
}

/**
 * IcM on-call schedule positions (roles)
 */
export enum IcmOnCallPosition {
    None,
    Primary,
    Backup,
    All
}

export const icmOnCallPositionRanges = [
    [null, null],
    [0, 0],
    [1, null],
    [0, null]
]

/**
 * IcM on-call schedule types
 */
export enum IcmScheduleType {
    None,
    NoSubstitution,
    SubstituteForContact,
    SubstituteByContact,
    All
}

export interface IcmOnCallShift {
    startTime: Date,
    endTime: Date
}

export interface IcmSchedule {
    icmContactId: number,
    icmTeam?: IcmTeam,
    icmOnCallRole?: IcmOnCallPosition,
    icmOnCallShifts?: IcmOnCallShift[],
    icmScheduleType?: IcmScheduleType,
}

/**
 * Details an IcM user
 */
export interface IcmContact {
    /**
     * The IcM ID of the contact
     */
    id: number,
    /**
     * The alias of the contact
     */
    alias: string,
    /**
     * The first name of the contact
     */
    firstName: string,
    /**
     * The last name of the contact
     */
    lastName: string,
    /**
     * The email address of the contact
     */
    emailAddress?: string,
    /**
     * The teams of which the contact is a member
     */
    teams: IcmTeam[],
}

/**
 * Details an IcM tenant (service)
 */
export interface IcmTenant {
    /**
     * The ID of the tenant
     */
    id: number,
    /**
     * The GUID of the service
     */
    tenantGuid?: string,
    /**
     * The name of the service
     */
    name?: string,
    /**
     * The description of the service
     */
    description?: string,
}

/**
 * Details an IcM team
 */
export interface IcmTeam {
    /**
     * The ID of the team
     */
    id: number,
    /**
     * The name of the team
     */
    name?: string,
    /**
     * The service to which the team belongs
     */
    tenant: IcmTenant
}

/**
 * Details an IcM team
 */
export interface IcmTeamInfo {
    /**
     * The ID of the team
     */
    teamId: number,
    /**
     * The name of the team
     */
    teamName: string,
    /**
     * The ID of the service to which the team belongs
     */
    serviceId: number,
    /**
     * The name of the service to which the team belongs
     */
    serviceName: string,
    /**
     * The email address of the team
     */
    teamEmailAddress: string,
}

export interface IcmContactOnCallSlot {
    teamInfo: IcmTeamInfo,

    onCallPosition: IcmOnCallPosition,
    
    startTime: Date,
    
    endTime: Date,
    
    substituteForContact: IcmContact,
    
    substitutedByContact: IcmContact,
    
    substitutionId: string,
    
    substitutionCollectionId: string
}

export interface IcmContactOnCallScheduleRequest {
    teamIds: number[],

    maxSlotsPerTeam?: number,

    startTime: Date,

    endTime?: Date,

    onCallPositionRange: (number | null)[],

    scheduleType: string[],

    top?: number,

    skip?: number
}

/**
 * Details a timezone
 */
export interface Timezone {
    /**
     * The standard long name. Based in part off of Windows labels
     */
    label: string,
    /**
     * The IANA standardized name
     */
    ianaName: string
}

export interface ScheduleNotificationDetails {
    /**
     * The team's channel id for the channel
     */
    teamsChannelId: string,
    /**
     * The name of the channel
     */
    name: string,
    /**
     * Flag indicating whether schedule notifications are enabled on the channel
     */
    areNotificationsEnabled: boolean,
}

export interface GeneralSettings {
    /**
     * Permissions on a team
     */
    permissions: Array<PermissionDetails>,
    /**
     * Determines whether auto archive is enabled
     */
    autoArchive: boolean,
    /**
     * How long until cases auto archive
     */
    autoArchiveHours: number,
    /**
     * Determines whether notification delay is enabled
     */
    notificationDelay: boolean,
    /**
     * How long until the first notification to be delayed
     */
    notificationDelayMinutes: number,
    /**
     * Case notification intervals per severity
     */
    teamSettingsCaseNotificationTimes: TeamSettingsCaseNotificationTimes,
    /**
     * Shows channels and whether schedule notifications are enabled on them.
     */
    scheduleNotificationDetails: Array<ChannelNotificationDetails>,
}

export interface AutoArchiveDetails {
    isEnabled: boolean,
    hours: number
}

export interface NotificationDelayDetails {
    isEnabled: boolean,
    minutes: number
}

export interface NotificationIntervals {
    [name: string]: NotificationInterval;
}

/**
 * Notification interval model.
 */
export interface NotificationInterval {
    /**
     * Interval for premier offering.
     */
    premier: number,
    /**
     * Interval for bc offering.
     */
    broad: number,
    /**
     * Interval for arr offering.
     */
    arr: number
}

export interface ChannelNotificationDetails {
    /**
     * The name of the channel
     */
    name: string,
    /**
     * The teams channel id
     */
    teamsChannelId: string,
    /**
     * Are notifications enabled for this channel
     */
    areNotificationsEnabled: boolean
}

/**
 * Schedules to modifiy
 */
export interface IGeneralSettingsPanelProps {
    /**
     * General team settings
     */
    settings?: GeneralSettings,
    /**
     * Selected master-detail tab index
     */
    selectedTabIndex: number
}

/**
 * An enum that maps to a general settings edit panel tab
 */
export enum GeneralSettingsTabs {
    /**
     * Tab 0: Notification intervals
     */
    NotificationIntervals,
    /**
     * Tab 1: Auto archive enabled
     */
    AutoArchive,
    /**
     * Tab 2: Permissions for roles
     */
    Permissions,
    /**
     * Tab 3: Schedule notifications enabled
     */
    ScheduleNotifications,
}

interface SeveritySettings {
    sev1: number,
    sevA: number,
    sevB: number,
    sevC: number
}

interface PrioritySettings {
    high: number,
    normal: number
}

export interface TeamSettingsCaseNotificationTimes {
    chat: SeveritySettings,
    premier: SeveritySettings,
    bc: SeveritySettings,
    arr: SeveritySettings,
    community: PrioritySettings,
    noID: PrioritySettings
}

const DEFAULT_SEVERITY_VALUES : SeveritySettings = {sev1: 0, sevA: 0, sevB: 0, sevC: 0};
const DEFAULT_PRIORITY_VALUES : PrioritySettings = {high: 0, normal: 0};
export const DEFAULT_CASE_NOTIFICATION_TIMES : TeamSettingsCaseNotificationTimes = {
    chat: DEFAULT_SEVERITY_VALUES,
    premier: DEFAULT_SEVERITY_VALUES, 
    arr: DEFAULT_SEVERITY_VALUES, 
    bc: DEFAULT_SEVERITY_VALUES, 
    community: DEFAULT_PRIORITY_VALUES, 
    noID: DEFAULT_PRIORITY_VALUES
} 