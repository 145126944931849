import { Overview } from "../TeamOverview/Models/dashboard.data";
import { ScheduleDetailsCollection } from "../TeamSettings/Models/teamsettings.data";
import moment from "moment";

/**
 * Ava container scope names.
 */
export enum avaContainers {
    AVA_HOME = '$ava-home',
    AVA_MY_CASES = '$ava-my-cases',
    AVA_PENDING_CASES = '$ava-pending-cases',
    AVA_DASHBOARD = '$ava-dashboard',
    AVA_TEAM_CASES = '$ava-team-cases',
    AVA_GENERAL_SETTINGS = '$ava-general-settings',
    AVA_SCHEDULE_SETTINGS = '$ava-schedule-settings',
    AVA_USER_SETTINGS = '$ava-user-settings',
    AVA_ROLE_SETTINGS = '$ava-role-settings',
    AVA_ERROR = '$ava-error',
}

/**
 * Generic permission denied message.
 */
export const permissionDenied: string = 'Your role does not have permission to access this page. Contact your team administrator for access.';

export const daysOfWeek: string[] = moment.weekdays().map((day) => {
    return day.substring(0, 3);
});

/**
 * Details pertaining to a team
 */
export interface Team {
    /**
     * The team's MS Teams ID
     */
    teamsTeamId: string,
    /**
     * The name of the team
     */
    name: string,
    /**
     * The channels for the team.
     */
    channels: ChannelDetails[],
    /**
     * Count of users scheduled
     */
    usersScheduled?: number,
    /**
     * Count of open cases
     */
    openCases?: number,
}

/**
 * Details pertaining to a role, permission and whether that permission is enabled or disabled
 */
export interface PermissionDetails {
    /**
     * The Ava permission id
     */
    permission: number,
    /**
     * The user role's id
     */
    userRoleId: number,
    /**
     * Is this permission enabled for the user
     */
    isEnabled: boolean,
    /**
     * The name of the role
     */
    roleName: string,
    /**
     * Description text for this permission
     */
    description: string
}

/**
 * A collection of teams returned from the Ava API
 */
export interface Teams {
    /**
     * A collection of all the Teams available in Ava for the requesting user
     */
    teams: Array<Team>
}

/**
 * Details regarding a user role
 */
export interface RoleDetails {
    /**
     * The ID associated with this role
     */
    id?: number,
    /**
     * The displayed name of a role
     */
    name: string,
    /**
     * A description of the role
     */
    description: string,
    /**
     * A value associated with how a member of a role is notified
     */
    // TODO: Make an enum to map to this 
    notifyType: number,
    /**
     * Determines whether a role is a default Ava role
     */
    isDefaultRole: boolean,
    /**
     * The view permissions a user has for the portal
     */
    viewPermissions?: Array<PermissionDetails>,
    /**
     * Indicates whether the role has been deleted
     */
    isDeleted: boolean
}

/**
 * Details regarding an Ava user.
 */
export interface UserDetails {
    /**
     * An Ava user's MS Team's ID
     */
    teamsUserId: string,
    /**
     * A user's Ava generated ID
     */
    userId: number,
    /**
     * Information on what a user's role is
     */
    role: RoleDetails,
    /**
     * A user's name
     */
    name: string,
    /**
     * A user's alias
     */
    alias: string,
    /**
     * Is the user out of office
     */
    isOutOfOffice: boolean,
    /**
     * Is the user an administrator
     */
    isAdmin: boolean,
    /**
     * The ID of the user's group type
     */
    userGroupId: number,
    /**
     * The name of the user's group type (PG, CSS, etc.)
     */
    userGroupName: string
}

/**
 * Ava user global settings
 */
export interface UserSettings {
    /**
     * True if the user is out of office; otherwise, false
     */
    isOutOfOffice?: boolean,
    /**
     * True if the user has enabled bulk notification; otherwise false
     */
    isBulkNotifyEnabled?: boolean,
}

/**
 * Ava user settings object returned by the Ava service
 */
export interface UserSettingsDetails {
    /**
     * The user's AAD object ID
     */
    userAadObjectId: string,
    /**
     * True if the user is out of office; otherwise, false
     */
    isOof: boolean,
    /**
     * True if the user has enabled bulk notification; otherwise, false
     */
    isBulkNotifyEnabled: boolean
}

/**
 * Details pertaining to a channel
 */
export interface ChannelDetails {
    /**
     * The team's MS Teams Channel ID.
     */
    teamsChannelId: string,
    /**
     * The display name of the channel
     */
    name: string
}

/**
 * Details pertaining to a case offering
 */
export interface CaseOfferingDetails {
    /**
     * The case offering id
     */
    id: string,
    /**
     * The display name of the offering
     */
    name: string
}

/**
 * The Ava Response model
 */
export interface AvaResponse<T> {
    /**
     * Data payload sent from Ava
     */
    data: T,
    /**
     * Message returned from API
     */
    message: string,
    /**
     * Maps to a http status code. Sent by Ava
     */
    statusCode: number,
    /**
     * Determines whether the status code sent is a success
     */
    isSuccessStatusCode: boolean,
    /**
     * Determines whether the status code sent is a client error
     */
    isClientErrorStatusCode: boolean,
    /**
     * Determines whether the status code sent is a server error
     */
    isServerErrorStatusCode: boolean
}

/**
 * Search results from the Ava team endpoint
 */
export interface TeamSearchResult {
    /**
     * The team's MS Teams ID
     */
    teamsTeamId: string,
    /**
     * A description of the role
     */
    name: string,
    /**
     * Details pertaining to the requesting user with regards to the requested team
     */
    currentUser: UserDetails,
    /**
     * A list of all schedules on a team
     */
    schedules: ScheduleDetailsCollection[],
    /**
     * A list of all users on a team
     */
    users: UserDetails[],
    /**
     * All settings regarding a team
     */
    settings: any,
    /**
     * A list of all roles on a team
     */
    roles: any,
    /**
     * A list of all channels on a team
     */
    channels: ChannelDetails[],
    /**
     * A list of all available case offerings
     */
    offerings: CaseOfferingDetails[],
    /**
     * A list of all SME Channels on a team
     */
    smeChannels: any
    /**
     * Contains an overview of the selected team
     */
    overview: Overview,
    /**
     * Is the team enrolled in the Canary feature toggle
     */
    isTeamCanaryEnrolled: boolean
}

/**
 * Maps a offering with a count
 */
export interface CaseOfferingCount {
    /**
     * The number associated with arr offering (could be count, number of hours, etc.)
     */
    arr: number,
    /**
     * The number associated with premier offering (could be count, number of hours, etc.)
     */
    premier: number,
    /**
     * The number associated with broad commercial offering (could be count, number of hours, etc.)
     */
    broad: number
}

/**
 * Details of a feature toggle
 */
export interface FeatureToggleDetails {
    /**
     * The id of the feature toggle
     */
    featureToggleId: string,
    /**
     * The scope of the feature toggle (e.g. System, Team, User)
     */
    featureToggleScope: string,
    /**
     * The id of the team to query for the feature toggle state.
     * Applicable to "Team" scoped queries
     */
    teamId: string,
    /**
     * The id of the user to query for the feature toggle state.
     * Applicable to "User" scoped queries
     */
    userId: number | undefined,
    /**
     * Gets or sets if this feature toggle is enabled.
     * Can only be set at a "System" scope, use "IsEnrolled" for "Team" and "User scoped toggles
     */
    isEnabled: boolean,
    /**
     * Gets or sets if this feature toggle is hidden.
     * A feature toggle that is hidden will not be visible to a Team or User (System toggles will always be visible by super admins).
     * NOTE: A hidden feature toggle can still be edited if IsEditable is true.
     */
    isHidden: boolean,
    /**
     * Gets or sets if the feature toggle enrollment can be changed by a Team or User.
     * Can only be set at a "System" scope.
     */
    isEditable: boolean,
    /**
     * Gets or sets if this feature toggle is enrolled to the specified team/user.
     * Applicable to "Team" and "User" scopes, value will be null for "System" scoped feature toggles
     */
    isEnrolled: boolean | undefined
}

export interface DateTimeSpan {
    start: Date,
    end: Date
}

export interface UserCheckingDetails {
    aadObjectId: string,
    teamsUserId: string
}

/**
 * Case data details
 */
export interface CaseDataDetails {
    caseNumber: string,
    severity: string,
    offering: string,
    assignedUser: string,
    linkToCase: string,
    timeCreated: string,
    conversationId: string,
    tenantId: string,
    serviceUrl: string,
    expiredTimerCount: number,
    channelName: string,
    team: Team,
    archived?: boolean,
    archivalStatus?: string,
    userChecking?: UserCheckingDetails,
    customerProgramPriority?: number,
    customerProgramType?: string,
    title: string,
    toolsUrls: {[id: string] : string},
    timeCaseReopened?: string,
    timeFirstChecked?: string,
    timeLastChecked?: string,
    timeArchived?: string,
    supportEngineer: UserDetails,
    pgStatus?: string
}

/**
 * View permissions in Ava
 */
export enum AvaPermissions {
    viewDashboard = 11001,
    viewTeamSettings = 12001,
    viewUserManagement = 13001,
    viewTeamRoles = 16001,
}