import { IScopedAction, IScope, IAction, } from '@elixir/fx';
import {
  RoleDetailsCollection,
  UserDetailsCollection,
  ScheduleDetailsCollection,
  GeneralSettings,
  IcmOnCallPosition,
  IcmScheduleType,
  UserScheduleDetails,
} from '../Models/teamsettings.data';

/**
 * Team settings action type.
 */
export enum TeamSettingsActionTypes {
  GET_TEAM_ROLES = '$ava/teamsettings/GET_TEAM_ROLES',
  GET_TEAM_USERS = '$ava/teamsettings/GET_TEAM_USERS',
  ADD_TEAM_ROLES = '$ava/teamsettings/ADD_TEAM_ROLES',
  EDIT_TEAM_ROLES = '$ava/teamsettings/EDIT_TEAM_ROLES',
  DELETE_TEAM_ROLES = '$ava/teamsettings/DELETE_TEAM_ROLES',
  GET_USER_SCHEDULES = '$ava/teamsettings/GET_USER_SCHEDULES',
  EDIT_TEAM_USERS = '$ava/teamsettings/EDIT_TEAM_USERS',
  SAVE_SCHEDULE = '$ava/teamsettings/SAVE_SCHEDULE',
  DELETE_SCHEDULE = '$ava/teamsettings/DELETE_SCHEDULE',
  GET_ICM_CONTACTS = '$ava/teamsettings/GET_ICM_CONTACTS',
  GET_ICM_ONCALL_SCHEDULE = '$ava/teamsettings/GET_ICM_ONCALL_SCHEDULE',
  GET_TIME_ZONES = '$ava/teamsettings/GET_TIME_ZONES',
  UPDATE_GENERAL_SETTINGS = "$core/ava/UPDATE_GENERAL_SETTINGS",
  GET_GENERAL_SETTINGS = "$ava/teamsettings/GET_GENERAL_SETTINGS",
}

/**
 * Team settings actions.
 */
export type TeamSettingsActions = IGetTeamRolesAction 
  | IGetTeamUsersAction
  | IAddTeamRolesAction | IEditTeamRolesAction | IDeleteTeamRolesAction
  | IGetUserSchedules | IEditTeamUsersAction
  | ISaveSchedule | IDeleteSchedules
  | IGetIcmContacts | IGetIcmOnCallSchedule
  | IGetTimeZones 
  | IUpdateGeneralSettingsAction | IGetGeneralSettingsAction;

/**
 * Get team roles action interface.
 */
export interface IGetTeamRolesAction extends IScopedAction {
  type: TeamSettingsActionTypes.GET_TEAM_ROLES,
  payload: {
    /**
     * TeamsId of the team.
     */
    teamsTeamId: string,
    /**
     * Get team roles scope.
     */
    scope: IScope
  }
}

/**
 * Get team users action interface.
 */
export interface IGetTeamUsersAction extends IScopedAction {
  type: TeamSettingsActionTypes.GET_TEAM_USERS,
  payload: {
    /**
     * TeamsId of the team.
     */
    teamsTeamId: string,
    /**
     * Get team users scope.
     */
    scope: IScope
  }
}


/**
 * Add team roles interface.
 */
export interface IAddTeamRolesAction extends IScopedAction {
  type: TeamSettingsActionTypes.ADD_TEAM_ROLES,
  payload: {
    /**
     * TeamsId of the team.
     */
    teamsTeamId: string,
    /**
     * RoleDetails collection.
     */
    roleDetailsCollection: RoleDetailsCollection,
    /**
     * Add team roles scope.
     */
    scope: IScope
  }
}

/**
 * Edit team roles interface.
 */
export interface IEditTeamRolesAction extends IScopedAction {
  type: TeamSettingsActionTypes.EDIT_TEAM_ROLES,
  payload: {
    /**
     * TeamsId of the team.
     */
    teamsTeamId: string,
    /**
     * RoleDetails collection.
     */
    roleDetailsCollection: RoleDetailsCollection,
    /**
     * Edit team roles scope.
     */
    scope: IScope
  }
}

/**
 * Delete team roles interface.
 */
export interface IDeleteTeamRolesAction extends IScopedAction {
  type: TeamSettingsActionTypes.DELETE_TEAM_ROLES,
  payload: {
    /**
     * TeamsId of the team.
     */
    teamsTeamId: string,
    /**
     * RoleDetails collection.
     */
    roleDetailsCollection: RoleDetailsCollection,
    /**
     * Delete team roles scope.
     */
    scope: IScope
  }
}

/**
 * Get the schedules on a team
 */
export interface IGetUserSchedules extends IScopedAction {
  type: TeamSettingsActionTypes.GET_USER_SCHEDULES,
  payload: {
    /**
     * Id of the team
     */
    teamsTeamId: string,
    /**
     * The elixir container's scope (Used to alter container)
     */
    scope: IScope
  }
}

/**
 * Edit team users interface.
 */
export interface IEditTeamUsersAction extends IScopedAction {
  type: TeamSettingsActionTypes.EDIT_TEAM_USERS,
  payload: {
    /**
     * TeamsId of the team.
     */
    teamsTeamId: string,
    /**
     * Updated users.
     */
    userDetails: UserDetailsCollection,
    /**
     * Edit team users scope.
     */
    scope: IScope
  }
}

/**
 * Saves any new or updated schedules
 */
export interface ISaveSchedule extends IAction {
  type: TeamSettingsActionTypes.SAVE_SCHEDULE,
  payload: {
    /**
     * The team these schedules belong to
     */
    teamsTeamId: string,
    /**
     * Schedules to save
     */
    schedules: UserScheduleDetails,
    /** 
     * Schedules to delete  
     */
    unselectedSchedules?: UserScheduleDetails
  }
}

/**
 * Deletes the sepcified schedules
 */
export interface IDeleteSchedules extends IAction {
  type: TeamSettingsActionTypes.DELETE_SCHEDULE,
  payload: {
    /**
     * The team these schedules belong to
     */
    teamsTeamId: string,
    /**
     * Schedules to delete
     */
    schedules: ScheduleDetailsCollection,
  }
}

/**
 * Get the IcM contacts
 */
export interface IGetIcmContacts extends IScopedAction {
  type: TeamSettingsActionTypes.GET_ICM_CONTACTS,
  payload: {
    /**
     * Aliases of the contacts
     */
    aliases: string[],
    /**
     * The elixir container's scope (Used to alter container)
     */
    scope: IScope
  }
}

/**
 * Get a contact's on-call schedule for the specified team
 */
export interface IGetIcmOnCallSchedule extends IScopedAction {
  type: TeamSettingsActionTypes.GET_ICM_ONCALL_SCHEDULE,
  payload: {
    /**
     * ID of the contact
     */
    contactId: number,
    /**
     * ID of the team
     */
    teamId: number,
    /**
     * The on-call role filter
     */
    onCallPosition: IcmOnCallPosition,
    /**
     * The on-call schedule type filter
     */
    onCallScheduleType: IcmScheduleType,
    /**
     * The elixir container's scope (Used to alter container)
     */
    scope: IScope
  }
}

/**
 * Gets timezones available in Ava
 */
export interface IGetTimeZones extends IAction {
  type: TeamSettingsActionTypes.GET_TIME_ZONES,
  payload: {
  }
}

/***
 * Updates the general settings via the Ava API
 */
export interface IUpdateGeneralSettingsAction extends IAction {
  type: TeamSettingsActionTypes.UPDATE_GENERAL_SETTINGS,
  payload: {
    teamsTeamId: string,
    generalSettings: GeneralSettings
  }
}

/**
 * Get general settings for the given team
 */
export interface IGetGeneralSettingsAction extends IScopedAction {
  type: TeamSettingsActionTypes.GET_GENERAL_SETTINGS,
  payload: {
    teamsTeamId: string,
    scope: IScope
  }
}


