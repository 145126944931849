import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createScope,
  getFrameworkInstance,
  IScope,
  uxSendNotificationAction,
  ElxScopedContainer,
} from "@elixir/fx";
import { ElxScopedTableContainer } from "@elixir/fx/lib/components/Container/Variations";

import {
  ITableAction,
  FilterTypes,
  ISearchBoxDropdownFilter,
  ElxDialog,
  IElxContainerProps,
  MatchTypes,
  IContainerAction,
} from "@elixir/components";

import { PageHeader } from "../../PageHeader/Containers/PageHeader";
import { coreSelectors } from "../../Core/Store/Selectors";
import { teamSettingsSelectors } from "../Store/Selectors";
import { RoleLabel } from "../../PageHeader/Components/RoleLabel/RoleLabel";
import {
  getTeamRolesAction,
  getTeamUsersAction,
  deleteTeamRolesAction,
} from "../Store/Actions";
import { IColumn, IDropdownOption } from "@fluentui/react";
import {
  RoleDetails,
  UserDetails,
  Team,
  avaContainers,
} from "../../Core/core.data";
import {
  RoleDetailsCollection,
  IRolePanelProps,
} from "../Models/teamsettings.data";
import { ModifyRolePanel } from "./ModifyRolePanel";
import { Dispatch } from "redux";
import { PreviewToggle } from "../../PageHeader/Components/PreviewToggle/PreviewToggle";
import { actions } from "../Store/Slice";
import { renderEmptyView } from "../../../Common/Core.utility";
import { AvaErrorBoundary } from "../../Core/Containers/AvaErrorBoundary/AvaErrorBoundary";
import "./Roles.scss";
import { mainTabFix } from "../../../Common/TabIndexFix";
import { NotificationMessage } from '../../Core/Containers/NotificationBanner/NotificationBanner'

/**
 * Role settings page table.
 */
export interface RoleTable {
  role: string;
  notificationtype: string;
  description: string;
  data: RoleDetails;
}

const rolesScope = createScope(avaContainers.AVA_ROLE_SETTINGS);

/**
 * Role settings page.
 */
export const Roles = (): JSX.Element => {
  const isPageLoading = useSelector(coreSelectors.getPageLoading);
  const user = useSelector(coreSelectors.getCurrentUser);
  const dispatch = useDispatch();
  const activeTeam = useSelector(coreSelectors.getActiveTeam);
  const [peviousTeamId, setPreviousTeamId] = React.useState(
    activeTeam?.teamsTeamId
  );

  // Get all the roles for the active team.
  const roleDetails = useSelector(
    teamSettingsSelectors.getTeamRoles
  ).roleDetails;

  React.useEffect(() => {
    mainTabFix();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // Check if the team ID has changed
    if (activeTeam?.teamsTeamId !== peviousTeamId) {
      // Update the current team ID state
      setPreviousTeamId(activeTeam?.teamsTeamId);

      // If the page is not loading, dispatch the fetchTeamAction
      if (activeTeam) {
        dispatch(getTeamRolesAction(activeTeam.teamsTeamId, rolesScope));
        dispatch(getTeamUsersAction(activeTeam.teamsTeamId, rolesScope));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTeam, dispatch]);

  // Filter options for notification type.
  const notifyTypeFilterOptions = getNotifyFilterOptions(roleDetails);

  // Get columns for the role settings page.
  const columns = getColumns();

  // Populate items for the role settings page.
  const items = getRoleTable(roleDetails);

  // Notification Type filter
  const notificationTypeFilter: ISearchBoxDropdownFilter = {
    type: FilterTypes.Dropdown,
    key: "notificationtype",
    label: "Notification Type",
    matchType: MatchTypes.Exact,
    filterProps: {
      multiSelect: true,
      options: notifyTypeFilterOptions,
      dropdownWidth: 200,
    },
    displayInline: true,
  };

  // Action buttons 'Edit' and 'Delete'
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const tableActions: ITableAction[] = [
    {
      key: "actionEdit",
      text: "Edit",
      iconProps: { iconName: "Edit" },
      disableBulkAction: true,
      onAction: (item) =>
        renderOnEdit(item, roleDetails, setPanelProps, rolesScope, activeTeam),
    },
    {
      key: "actionDelete",
      text: "Delete",
      iconProps: { iconName: "Delete", style: { color: "#A80000" } },
      disableBulkAction: false,
      onAction: (item) =>
        renderOnDelete(
          [item],
          teamUsers,
          setShowDeleteDialog,
          setRolesToDelete,
          dispatch
        ),
      onBulkAction: (items) =>
        renderOnDelete(
          items,
          teamUsers,
          setShowDeleteDialog,
          setRolesToDelete,
          dispatch
        ),
    },
  ];

  // // Populate team users for the active team.
  const teamUsers = useSelector(teamSettingsSelectors.getTeamUsers).userDetails;
  if (!isPageLoading && activeTeam && teamUsers.length === 0) {
    dispatch(getTeamUsersAction(activeTeam.teamsTeamId, rolesScope));
  }

  const [panelProps, setPanelProps] = React.useState({} as IRolePanelProps);

  // List of roles to be deleted.
  const [rolesToDelete, setRolesToDelete] = React.useState({
    roleDetails: [],
  } as RoleDetailsCollection);

  const actionBtnProps: IContainerAction[] = [
    {
      key: "1",
      text: "Add new role",
      onClick: () => {
        if (activeTeam) {
          setPanelProps({
            teamsTeamId: activeTeam.teamsTeamId,
            panelScope: rolesScope,
          });
          getFrameworkInstance().store.dispatch(
            actions.showModifyRolePanel(true)
          );
        }
      },
    },
    {
      key: "2",
      text: "View documentation",
      href: "https://azsupportdocs.azurewebsites.net/ava/articles/GettingStarted.html#onboarding-steps",
      target: "_blank",
      rel: "noopener noreferrer",
    },
  ];

  const displayError = useSelector(coreSelectors.getDisplayError);

  // Table container
  return (
    <ElxScopedContainer
      headerText=""
      onRenderSubHeader={() => {
        return (
          <div className="subHeader">
            View and configure the roles available in your team
          </div>
        );
      }}
      actions={displayError ? undefined : actionBtnProps}
      subActions={renderSubActions(user)}
      onRenderHeader={(): JSX.Element => {
        return (
          <PageHeader containerScope={rolesScope} actions={actionBtnProps} />
        );
      }}
    >
      <AvaErrorBoundary>
        <ElxScopedTableContainer
          containerProps={{
            className: "hideHeader",
            isLoading: isPageLoading,
          }}
          tableProps={{
            columns,
            items: items.sort((a: RoleTable, b: RoleTable) =>
              a.role > b.role ? 1 : -1
            ),
            stickyHeader: true,
            actions: tableActions,
            ariaLabelForGrid: "View and configure the roles",
          }}
          searchBoxProps={{
            filters: [notificationTypeFilter],
          }}
          onRenderEmpty={() => renderEmptyView("No roles found.")}
        >
          <ElxDialog
            hidden={!showDeleteDialog}
            dismissable={true}
            dialogContentProps={{
              title: "Delete role",
            }}
            primaryButtonProps={{
              text: "Delete",
              onClick: () =>
                onDeleteRoles(dispatch, rolesScope, rolesToDelete, activeTeam),
            }}
            cancelButtonProps={{
              text: "Cancel",
            }}
            onDismiss={() =>
              onDialogDismiss(setShowDeleteDialog, setRolesToDelete)
            }
          >
            <div>
              Are you sure you want to delete this role? Once you delete this,
              the item will not be able to be recovered.
            </div>
          </ElxDialog>

          <ModifyRolePanel {...panelProps} />
        </ElxScopedTableContainer>
      </AvaErrorBoundary>
    </ElxScopedContainer>
  );
};

/**
 * Render action to display current role of the user.
 * @param user Current user.
 * @returns Label element
 */
function renderSubActions(user?: UserDetails): JSX.Element[] {
  const subActions: JSX.Element[] = [];

  if (user?.isAdmin) {
    subActions.push(<PreviewToggle user={user} />);
  }

  subActions.push(<RoleLabel user={user} />);

  return subActions;
}

/**
 * Render action to edit selected role.
 * @param item Item to edit.
 * @param setPanelProps Set state for setting Add/Edit role panel props.
 * @param activeTeam Current team.
 * @param scope Current scope.
 */
function renderOnEdit(
  item: any,
  roleDetails: RoleDetails[],
  setPanelProps: React.Dispatch<React.SetStateAction<IRolePanelProps>>,
  scope: IScope,
  activeTeam?: Team
) {
  const role = roleDetails.filter((r) => r.name === item.role)[0];
  if (activeTeam) {
    setPanelProps({
      role: role,
      teamsTeamId: activeTeam.teamsTeamId,
      panelScope: scope,
    });
    getFrameworkInstance().store.dispatch(actions.showModifyRolePanel(true));
  }
}

/**
 * Render action on Delete of a role.
 * @param items Items to be deleted.
 * @param users All users for the team.
 * @param setShowDeleteDialog Set state for showing delete confirmation dialog.
 * @param setRolesToDelete Set state for updating roles to be deleted.
 * @param dispatch Dispatch function.
 */
function renderOnDelete(
  items: RoleTable[],
  users: UserDetails[],
  setShowDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setRolesToDelete: React.Dispatch<React.SetStateAction<RoleDetailsCollection>>,
  dispatch: Dispatch
) {
  var roles = items.map((item) => item.data);
  let isDefaultRolePresent = roles.some((r) => r.isDefaultRole);
  if (!isDefaultRolePresent && !checkIfUsersInSelectedRoles(users, roles)) {
    setRolesToDelete({ roleDetails: roles });
    setShowDeleteDialog(true);
  } else {
    dispatch(
      uxSendNotificationAction({
        // eslint-disable-next-line no-multi-str
        message:
          "Default Member role or roles having users assigned cannot be removed. \
                Assign users to another role and try again.",
      })
    );
  }
}

/**
 * Function to delete selected roles.
 * @param dispatch Dispatch.
 * @param activeTeam Active team.
 * @param rolesToDelete All the roles to be deleted.
 * @param scope Current scope.
 */
function onDeleteRoles(
  dispatch: Dispatch,
  scope: IScope,
  rolesToDelete: RoleDetailsCollection,
  activeTeam?: Team
) {
  if (activeTeam) {
    dispatch(
      deleteTeamRolesAction(activeTeam.teamsTeamId, rolesToDelete, scope)
    );
  }
}

/**
 * Function called when on dismissing the delete dialog.
 * @param setShowDeleteDialog Set state function for delete dialog.
 * @param setRolesToDelete Set state function for rolesToDelete array.
 */
function onDialogDismiss(
  setShowDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setRolesToDelete: React.Dispatch<React.SetStateAction<RoleDetailsCollection>>
) {
  // Clear the roles to be deleted.
  setRolesToDelete({ roleDetails: [] });
  // Dismiss the delete dialog.
  setShowDeleteDialog(false);
}

/**
 * Get the defined columns for the table.
 * @returns Columns
 */
function getColumns(): IColumn[] {
  return [
    {
      key: "column_Role",
      name: "Role",
      fieldName: "role",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column_NotificationType",
      name: "Notification Type",
      fieldName: "notificationtype",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "column_Description",
      name: "Description",
      fieldName: "description",
      minWidth: 100,
      maxWidth: 800,
      isResizable: true,
    },
  ];
}

/**
 * Get all the items to be displayed in table for the given roles.
 * @param roles All the roles in the team
 * @returns Item array to display
 */
export function getRoleTable(roles: RoleDetails[]): RoleTable[] {
  return Array.from(roles)
    .filter((role) => role.isDeleted === false)
    .map((role) => ({
      role: role.name,
      notificationtype: getNotificationType(role.notifyType),
      description: role.description,
      data: role,
    }));
}

/**
 * This method converts the given notification type value to string
 * @param type Notification type of a role enum
 * @returns Notification type string
 */
function getNotificationType(type: number): string {
  var result = "";
  switch (type) {
    case 0:
      result = "No notification";
      break;
    case 1:
      result = "Notify when involved";
      break;
    case 4:
      result = "Notify on new case";
      break;
    default:
      break;
  }
  return result;
}

/**
 * This method is used for getting notification type filter options based on the role details.
 * @param roles All the roles in the team.
 * @returns Dropdown filter options
 */
function getNotifyFilterOptions(roles: RoleDetails[]): IDropdownOption[] {
  return Array.from(
    new Set(roles.map((role) => getNotificationType(role.notifyType)))
  ).map((type) => ({ key: type, text: type }));
}

/**
 * This method is used to check if there are any users present in the given role(s).
 * @param users All the users in the team.
 * @param roles Current role(s) to check for users in role.
 * @returns
 * True: If at least one user is present for the role(s) specified.
 * False: Otherwise
 */
function checkIfUsersInSelectedRoles(
  users: UserDetails[],
  roles: RoleDetails[]
): boolean {
  let roleIds = roles.map((r) => r.id);
  return (
    Array.from(new Set(users.map((u) => u.role))).filter((r) => {
      return r.isDefaultRole || roleIds.includes(r.id);
    }).length > 0
  );
}
