import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MessageBarType } from '@fluentui/react';
import { IScope, uxAddContainerMessageAction, uxClearContainerMessageAction } from '@elixir/fx';
import { ElxPicker, IPickerItem, IElxButtonBaseProps } from '@elixir/components';
import { coreSelectors } from '../../Core/Store/Selectors';
import { Team } from '../../Core/core.data';
import { fetchTeamAction, fetchUserTeamsAction } from '../../Core/Store/Actions';
import './PageHeader.scss';
import * as QueryString from 'query-string';

/**
 * Defines what properties will be passed down to the page header
 */
interface IPageHeaderProps {
    /** 
     * This scope is used to keep track of the containers above for stuff like error banners and such 
     */
    containerScope: IScope,
    /** 
     * Lets the Ava API known what additional data it has to include in it's search request 
     */
    include?: string,

    actions?: IElxButtonBaseProps[]
}

/**
 * Page header that is found on every page of the management portal. Contains the team picker.
 * @param props Takes an elixir scope and an include string. The include string is a comma
 * delimited string that is sent to the Ava API to return additional data. Valid values are:
 * channels, users, roles, smeChannels, settings, overview, and schedules.
 * Elixir scope for error handling at the moment.
 * @see IScope
 */
export const PageHeader = (props: IPageHeaderProps): JSX.Element => {
    
    // Handles the change team event.
    const onChange = (value: IPickerItem<Team>): void => {
        dispatch(fetchTeamAction(value?.data?.teamsTeamId, props.containerScope, props.include));
        setPicked(value);
    }
    
    const searchQuery = QueryString.parse(window.location.search);
    const searchTeamId = searchQuery['teamId'] ? searchQuery['teamId'] as string : undefined;

    // Dispatch hooked used to fire state changes
    const dispatch = useDispatch();
    const teams = useSelector(coreSelectors.getUserTeams);
    const activeTeam = useSelector(coreSelectors.getActiveTeam);

    const [picked, setPicked] = React.useState<IPickerItem<Team>>();

    const selectedKey = picked && picked.key;

    React.useEffect(() => {
        if (!teams.teams.length) {
            dispatch(fetchUserTeamsAction(props.containerScope, props.include));
        }
    }, [dispatch, props.containerScope, props.include, teams.teams.length]);
    
    // If the url has query strings, set the selected team to the query string.
    React.useEffect(() => {
        if (teams.teams.length > 0 && searchTeamId) {
            let selectedTeam = teams.teams.find(t => t.teamsTeamId === searchTeamId);
            let selectedItem = items.find(i => i.data === selectedTeam);
            if (selectedItem) {
                onChange(selectedItem);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTeamId, teams.teams.length]);

    const userSettings = useSelector(coreSelectors.getCurrentUserSettings);

    // Show the OOF banner
    React.useEffect(() => {
        if (userSettings?.isOutOfOffice) {
            dispatch(uxAddContainerMessageAction({
                type: MessageBarType.info,
                message: 'Your status is currently marked as out of office.',
            }, props.containerScope));
        } else {
            
            dispatch(uxClearContainerMessageAction(props.containerScope));
        }
    }, [dispatch, props.containerScope, userSettings]);

    // Set up picker items.
    const items: IPickerItem<Team>[] = Array.from(teams.teams).map((team: Team, i: number) => ({
        key: '' + i,
        text: team.name,
        data: team
    }));

    // Inital value of the teams list is a blank team (for templating reasons).
    // This just checks if we're at the blank value
    if(!selectedKey && activeTeam) {
        setPicked(items.find(team => team.data?.teamsTeamId === activeTeam?.teamsTeamId));
    }

    return (
        <div className="header-container ms-lg12 ms-sm12" dir="ltr">
            <div className="ms-sm12" dir="ltr">
                <ElxPicker
                    items={items}
                    selectedKey={selectedKey}
                    placeHolder={picked?.data?.name}
                    searchBoxProps={{
                        placeholder: "Search by team name"
                    }}
                    onChange={onChange}
                    aria-label="Select a team"
                />
            </div>
        </div>
    );
}