interface paginationProps<T> {
  itemsPerPage: number;
  paginatedResult: T[];
  pageIndex: number;
  totalPages: number;
  setPageIndex: (pageIndex: number) => void;
}

const PaginationFooter = <T,>(props: paginationProps<T>) => {
  const paginationTextStyle = {
    marginTop: "2rem",
    marginRight: "0.5rem",
    marginLeft: "0.5rem",
  };

  const handleNextPage = () => {
    if (props.pageIndex < props.totalPages - 1) {
      props.setPageIndex(props.pageIndex + 1);
    }
  };

  const handlePreviousPage = () => {
    props.setPageIndex(props.pageIndex - 1);
  };

  return (
    <div className="pagination-footer">
      <button onClick={handlePreviousPage} disabled={props.pageIndex === 0}>
        &lt; Previous
      </button>
      <span className="pagination-text" style={paginationTextStyle}>
        Page {Math.min(props.pageIndex + 1, props.totalPages)} of{" "}
        {props.totalPages}
      </span>
      <button
        onClick={handleNextPage}
        disabled={
          props.paginatedResult.length < props.itemsPerPage ||
          props.pageIndex === props.totalPages - 1
        }
      >
        Next &gt;
      </button>
    </div>
  );
};

export default PaginationFooter;
