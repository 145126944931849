import { 
  IGetPendingCasesAction,
  IGetMyCasesAction,
  AvaOverviewActionTypes,
} from "./Types";
import { SagaIterator } from '@redux-saga/core';
import { call, put, all, takeLatest } from '@redux-saga/core/effects';
import { Http, uxAddContainerMessageAction } from "@elixir/fx";
import { getLogger } from '@elixir/telemetry';
import { AxiosResponse } from "axios";
import { AvaResponse } from "../../Core/core.data";
import Config from '../../../Config';
import { setPageLoading, initializeContainer } from '../../Core/Store/Slice';
import { CaseDataDetails } from '../../Core/core.data';
import { setPendingCases, setMyCases } from './Slice';
import { MessageBarType } from '@fluentui/react';

const AVA_TEAM_API: string = Config.apiUrl + '/api/v1/teams';
const AVA_ME_API: string = Config.apiUrl + '/api/v1/me';

/**
 * Get all pending cases for the current user.
 * @param action Pending cases action.
 */
function* getPendingCases(action: IGetPendingCasesAction): SagaIterator {
    yield put(initializeContainer({isLoading: true, displayError: false}));

    try {
        // Get all the pending cases the current user is on
        const res: AxiosResponse<AvaResponse<CaseDataDetails[]>> = yield call(Http.get, action, `${AVA_TEAM_API}/pendingcases/`);

        getLogger().trace("Pending Cases: ", res.data.data);

        // Update state to contain all list of pending cases
        yield put(setPendingCases(res.data.data));
    } catch (err: any) {
        yield put(uxAddContainerMessageAction({
            type: MessageBarType.error,
            message: err.response?.data?.Message
            || 'An error occurred while fetching pending cases, please try again later.',
        }, action.payload.scope));

        getLogger().error(err);
    }
    finally {
        // Set the current loading state to false
        yield put(setPageLoading(false));
    }
}

/**
* Get all cases user owns.
* @param action My cases action.
*/
function* getMyCases(action: IGetMyCasesAction): SagaIterator {
  // Set loading state of page to true
  yield put(initializeContainer({isLoading: true, displayError: false}));

    try {
        // Get all cases the current user is on
        const res: AxiosResponse<AvaResponse<CaseDataDetails[]>> = yield call(Http.get, action, `${AVA_ME_API}/mycases/`);

        getLogger().trace("My Cases: ", res.data.data);

        // Update state to contain all list of my cases
        yield put(setMyCases(res.data.data));
    } catch (err: any) {
        yield put(uxAddContainerMessageAction({
            type: MessageBarType.error,
            message: err.response?.data?.Message
            || 'An error occurred while fetching my cases, please try again later.',
        }, action.payload.scope));

        getLogger().error(err);
    }
    finally {
        // Set the current loading state to false
        yield put(setPageLoading(false));
    }
}

function* watchAvaOverviewActions(): IterableIterator<any> {
    yield all([
        yield takeLatest(AvaOverviewActionTypes.GET_PENDING_CASES, getPendingCases),
        yield takeLatest(AvaOverviewActionTypes.GET_MY_CASES, getMyCases),
    ]);
}

// tslint:disable-next-line: no-any
function* avaOverviewSaga(): IterableIterator<any> {
    yield watchAvaOverviewActions();
}

export default avaOverviewSaga;
