import { ElxPanel, ElxTextField, ElxChoiceGroup, PanelSize, IContainerAction } from '@elixir/components';

import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from 'react-redux';
import { IChoiceGroupOption, mergeStyles } from '@fluentui/react';
import { teamSettingsSelectors } from "../Store/Selectors";
import { addTeamRolesAction, editTeamRolesAction } from "../Store/Actions";
import { RoleDetails } from "../../Core/core.data";
import { RoleDetailsCollection, IRolePanelProps } from '../Models/teamsettings.data';
import { actions } from '../Store/Slice';
import './ModifyRolePanel.scss';

/**
 * This panel is used for add role and edit roles from role settings page.
 * @param props Initial properties to be set in the panel.
 */
export const ModifyRolePanel = (props: IRolePanelProps): JSX.Element => {
  const showPanel = useSelector(teamSettingsSelectors.getShowModifyRolePanel);
  const panelMessage = useSelector(teamSettingsSelectors.getPanelMessage);
  const dispatch = useDispatch();

  const isEditRole = props.role !== undefined;
  const activeRole = props.role === undefined ? "" : props.role.name;
  const headerText = isEditRole ? "Edit " + activeRole : "Add new role";
  const subHeaderText = isEditRole 
    ? "Edit the notification type for this role" 
    : "Add a new role and assign notification";

  // Set states for active role.
  const [activeRoleName, setActiveRoleName] = React.useState(undefined as (string | undefined));
  const [activeRoleDescription, setActiveRoleDescription] = React.useState(undefined as (string | undefined));
  const [selectedNotificationType, setSelectedNotificationType] = React.useState(undefined as (number | undefined));

  // Set state for enable/disable 'Save' button.
  const [enableSave, setEnableSave] = React.useState(false);

  // New role to be added/edited.
  const newRole = { 
      name: activeRoleName, 
      description: activeRoleDescription, 
      notifyType: selectedNotificationType,
      isDefaultRole: false
    } as RoleDetails;

  // Set the passed in values for edit role case for the first time.
  useEffect(() => {
    if (props.role) {
      if (activeRoleName === undefined) {
        setActiveRoleName(props.role.name);
      }
      if (activeRoleDescription === undefined) {
        setActiveRoleDescription(props.role.description);
      }
      if (selectedNotificationType === undefined) {
        setSelectedNotificationType(props.role.notifyType);
      }
    }
  }, [activeRoleDescription, activeRoleName, selectedNotificationType, props]);

  // Notification type options while adding a role
  const addRoleNotificationTypeOptions: IChoiceGroupOption[] = [
    {
        key: '0',
        text: 'No notification',
        onRenderField: (props, render) => {
            const descriptionText = "Users with this notification type will not receive \
            notifications about new questions. It is intended for users who will be \
            creating new questions."
            return renderPanelOptions(render!(props), descriptionText);
        }
    },
    {
        key: '4',
        text: 'Notify on new case',
        onRenderField: (props, render) => {
            const descriptionText = "Users in this role will receive a notification \
            each time a new question is asked in a channel where they are scheduled. \
            It is intended for users who act as the first line of defense, and \
            answer new questions."
            return renderPanelOptions(render!(props), descriptionText);
        }
    },
    { 
        key: '1',
        text: 'Notify when involved',
        onRenderField: (props, render) => {
          const descriptionText = "An expert in this role will receive a notification \
          when another user invokes the command to involve their role in a channel \
          where they are scheduled. It is intended for more specialized roles whose \
          input is not needed on every question."
            return renderPanelOptions(render!(props), descriptionText);
        }
    }
  ];
  
  return (
    <ElxPanel
      headerText={headerText}
      subHeaderText={subHeaderText}
      isOpen={showPanel}
      size={PanelSize.small}
      actions={renderPanelActions(dispatch, enableSave, setActiveRoleName, 
        setActiveRoleDescription, setSelectedNotificationType, setEnableSave, 
        newRole, props)}
      onDismiss={() => onDismiss(dispatch, setActiveRoleName, setActiveRoleDescription, 
        setSelectedNotificationType, setEnableSave)}
      message={panelMessage}
    >
      <div className="role-panel-dropdown-container padding-1">
        <ElxTextField
          label="Role"
          placeholder="Enter a role name"
          disabled={isEditRole}
          required={true}
          defaultValue={props.role === undefined ? undefined : props.role.name}
          onChange={(ev, newValue) => {
            setActiveRoleName(newValue);
            checkForSaveEnable(setEnableSave, newValue, activeRoleDescription, selectedNotificationType);
          }}
        />
        <br />
        <ElxTextField
          label="Description"
          placeholder="Enter a role description"
          required={true}
          multiline={true}
          rows={4}
          defaultValue={props.role === undefined ? undefined : props.role.description}
          onChange={(ev, newValue) => {
            setActiveRoleDescription(newValue);
            checkForSaveEnable(setEnableSave, activeRoleName, newValue, selectedNotificationType);
          }}
        />
        <br />
        <br />
        <ElxChoiceGroup
          label="Notification type"
          required={true}
          options={addRoleNotificationTypeOptions}
          defaultSelectedKey={props.role === undefined ? undefined : props.role.notifyType.toString()}
          onChange={(ev, newOption) => onNotificationTypeChange(setSelectedNotificationType, setEnableSave,
            newOption, activeRoleName, activeRoleDescription)}
        />
      </div>
    </ElxPanel>
  );
}

/**
 * Function to render add role panel action buttons.
 * @param dispatch Dispatch function.
 * @param enableSave Flag to determine if the 'Save' button should be enabled/disabled.
 * @param setActiveRoleName Set state for active role name.
 * @param setActiveRoleDescription Set state for active role description.
 * @param setSelectedNotificationType Set state for selected notification type.
 * @param setEnableSave Set state for enable/disable 'Save'.
 * @param newRole New role to be added/edited.
 * @param props Input properties.
 */
function renderPanelActions(dispatch: Dispatch, enableSave: boolean,
    setActiveRoleName: React.Dispatch<React.SetStateAction<string | undefined>>,
    setActiveRoleDescription: React.Dispatch<React.SetStateAction<string | undefined>>,
    setSelectedNotificationType: React.Dispatch<React.SetStateAction<number | undefined>>,
    setEnableSave: React.Dispatch<React.SetStateAction<boolean>>,
    newRole: RoleDetails, props: IRolePanelProps): IContainerAction[] {
  return [{
      key: '1',
      text: 'Save',
      primary: true,
      disabled: !enableSave,
      onClick: () => onSave(dispatch, props, newRole, setActiveRoleName, setActiveRoleDescription, 
        setSelectedNotificationType, setEnableSave)
    }, {
      key: '2',
      text: 'Cancel',
      onClick: () => onDismiss(dispatch, setActiveRoleName, setActiveRoleDescription, 
        setSelectedNotificationType, setEnableSave)
    },
    ];
}

/**
 * Function to send add/edit role on Save button.
 * @param dispatch Dispatch.
 * @param props Role panel properties.
 * @param newRole New role to set.
 */
function onSave(dispatch: Dispatch, props: IRolePanelProps, newRole: RoleDetails,
  setActiveRoleName: React.Dispatch<React.SetStateAction<string | undefined>>,
    setActiveRoleDescription: React.Dispatch<React.SetStateAction<string | undefined>>,
    setSelectedNotificationType: React.Dispatch<React.SetStateAction<number | undefined>>,
    setEnableSave: React.Dispatch<React.SetStateAction<boolean>>) {
  if (props.role !== undefined) {
    newRole.id = props.role.id;
    const roles = {roleDetails: [newRole]} as RoleDetailsCollection;
    dispatch(editTeamRolesAction(props.teamsTeamId, roles, props.panelScope));
  } else {
    const roles = {roleDetails: [newRole]} as RoleDetailsCollection;
    dispatch(addTeamRolesAction(props.teamsTeamId, roles, props.panelScope));
  }
  setActiveRoleName(undefined);
  setActiveRoleDescription(undefined);
  setSelectedNotificationType(undefined);
  setEnableSave(false);
}

/**
 * Function to render notification type choice groups in add role panel.
 * @param renderOption Current render choice group item.
 * @param descriptionText Description text for the choice group item.
 */
function renderPanelOptions(renderOption: JSX.Element | null, descriptionText: string): JSX.Element {
  const optionRootClass = mergeStyles({ display: 'flex', alignItems: 'baseline', fontWeight: '500' });
  return (
      <div>
          <div className={optionRootClass}>
              {renderOption}
          </div>
          <div className="padding-1">
              {descriptionText}
          </div>
      </div>
  );
}

/**
 * Check for blank or undefined or null string
 * @param str String to be verified.
 */
function isBlank(str?: string) 
{
    return !str || /^\s*$/.test(str);
}

/**
 * Function to check and modify enable/disable save button.
 * @param roleName Current role name.
 * @param roleDescription Current role description.
 * @param notifyType Current notification type.
 * @param setEnableSave Set state function to enable/disable Save button.
 */
function checkForSaveEnable(setEnableSave: React.Dispatch<React.SetStateAction<boolean>>,
  roleName?: string, roleDescription?: string, notifyType?: Number) {
    if (!isBlank(roleName) && !isBlank(roleDescription) && notifyType !== undefined) {
        setEnableSave(true);
    }
    else {
        setEnableSave(false);
    }
}

/**
 * This method is invoked when a selection is made for the notification type.
 * @param option Current selected option.
 * @param activeRoleName Active role name. (In case of edit role, it's constant)
 * @param activeRoleDescription Active role description.
 * @param setSelectedNotificationType Set state function for setting the notification type option.
 * @param setEnableSave Set enable save function to enable/disable Save button.
 */
function onNotificationTypeChange(
    setSelectedNotificationType: React.Dispatch<React.SetStateAction<number | undefined>>,
    setEnableSave: React.Dispatch<React.SetStateAction<boolean>>,
    option?: IChoiceGroupOption,
    activeRoleName?: string, activeRoleDescription?: string) {

    var roleName = activeRoleName;
    var roleDescription = activeRoleDescription;
    var selectedOption = option === undefined ? undefined : parseInt(option.key);
    setSelectedNotificationType(selectedOption);
    checkForSaveEnable(setEnableSave, roleName, roleDescription, selectedOption);
}

/**
 * This functions resets the current state and dismiss the panel.
 * @param setActiveRoleName Set state function for role name.
 * @param setActiveRoleDescription Set state funciton for role description.
 * @param setSelectedNotificationType Set state function for notification type.
 * @param setEnableSave Set state function for enable save.
 */
function onDismiss(dispatch: Dispatch, 
    setActiveRoleName: React.Dispatch<React.SetStateAction<string | undefined>>,
    setActiveRoleDescription: React.Dispatch<React.SetStateAction<string | undefined>>,
    setSelectedNotificationType: React.Dispatch<React.SetStateAction<number | undefined>>,
    setEnableSave: React.Dispatch<React.SetStateAction<boolean>>) {
    dispatch(actions.clearPanelMessage());
    setActiveRoleName(undefined);
    setActiveRoleDescription(undefined);
    setSelectedNotificationType(undefined);
    setEnableSave(false);
    dispatch(actions.showModifyRolePanel(false));
}