import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from 'redux';
import { Checkbox } from '@fluentui/react';
import { ElxScopedPanel, uxSetPanelOpen, IScope } from "@elixir/fx";
import { PanelSize, IContainerAction } from '@elixir/components';
import { IPanelProps } from '../Models/topnav.data';
import { coreSelectors } from "../../Core/Store/Selectors";
import { UserSettings } from "../../Core/core.data";
import { updateCurrentUserSettingsAction } from "../../Core/Store/Actions";
import './UserSettings.scss';

export const UserSettingsPanel = (props: IPanelProps): JSX.Element => {
    const dispatch = useDispatch();

    const currentUserSettings = useSelector(coreSelectors.getCurrentUserSettings);
    const [isOofFlag, setIsOofFlag] = React.useState<boolean | undefined>();
    const [isBulkNotifyEnabledFlag, setIsBulkNotifyEnabledFlag] = React.useState<boolean | undefined>();

    return(
        <ElxScopedPanel
            scope={props.panelScope}
            headerText="User settings"
            size={PanelSize.small}
            actions={renderPanelActions(dispatch, currentUserSettings, 
                isOofFlag, isBulkNotifyEnabledFlag, props.panelScope)}
            onDismiss={() => onDismiss(dispatch, props.panelScope)}
        >
            <Checkbox
                label='Set status as out of office'
                className='user-settings'
                disabled={!currentUserSettings}
                defaultChecked={currentUserSettings?.isOutOfOffice}
                onChange={(ev, checked) => setIsOofFlag(checked)}
            />
            <Checkbox
                label='Enable bulk notifications'
                className='user-settings'
                disabled={!currentUserSettings}
                defaultChecked={currentUserSettings?.isBulkNotifyEnabled}
                onChange={(ev, checked) => setIsBulkNotifyEnabledFlag(checked)}
            />
        </ElxScopedPanel>
    );
}

/**
 * Function to render panel action buttons.
 * @param dispatch Dispatch function.
 * @param currentUserSettings current user settings.
 * @param isOofFlag New isOofFlag status.
 * @param isBulkNotifyEnabledFlag New isBulkNotifyEnabledFlag status.
 * @param panelScope Current panel scope.
 */
function renderPanelActions(dispatch: Dispatch,
    currentUserSettings: UserSettings | undefined, isOofFlag: boolean | undefined,
    isBulkNotifyEnabledFlag: boolean | undefined, panelScope: IScope): IContainerAction[] {
    return [
        {
            key: 'buttonSave',
            text: 'Save and Close',
            primary: true,
            disabled: !currentUserSettings,
            onClick: () => onSave(dispatch, currentUserSettings, isOofFlag, isBulkNotifyEnabledFlag, panelScope)
        }, {
            key: 'buttonCancel',
            text: 'Cancel',
            onClick: () => onDismiss(dispatch, panelScope)
        },
    ];
}

/**
 * Update the user's OOF/BulkNotification status on Save button click.
 * @param dispatch Dispatch.
 * @param currentUserSettings Current settings.
 * @param isOofFlag New isOofFlag.
 * @param isBulkNotifyEnabledFlag New isBulkNotifyEnabledFlag.
 * @param panelScope Current panel scope.
 */
function onSave(dispatch: Dispatch, 
    currentUserSettings: UserSettings | undefined, 
    isOofFlag: boolean | undefined, 
    isBulkNotifyEnabledFlag: boolean | undefined, 
    panelScope: IScope) {
    const updatedUserSettings: UserSettings = {
        isOutOfOffice: isOofFlag ?? currentUserSettings?.isOutOfOffice ?? false,
        isBulkNotifyEnabled: isBulkNotifyEnabledFlag ?? currentUserSettings?.isBulkNotifyEnabled ?? false
    };
    dispatch(updateCurrentUserSettingsAction(updatedUserSettings, panelScope));
    onDismiss(dispatch, panelScope);
}

/**
 * Reset necessary flags on dismiss of the panel.
 * @param dispatch Dispatch.
 * @param panelScope Current panel scope.
 */
function onDismiss(dispatch: Dispatch, panelScope: IScope): void {
    dispatch(uxSetPanelOpen(false, panelScope))
}