import { IAction, IScopedAction, IScope } from '@elixir/fx'

import { UserSettings } from '../core.data';

export enum AvaActionTypes {
    GET_USER_TEAMS = '$core/ava/GET_USER_TEAMS',
    GET_TEAM = '$core/ava/GET_TEAM',
    UPDATE_IS_ACTIVE_TEAM_CANARY_ENROLLED = '$core/ava/UPDATE_IS_ACTIVE_TEAM_CANARY_ENROLLED',
    UPDATE_CURRENT_USER_SETTINGS = '$core/ava/UPDATE_CURRENT_USER_SETTINGS',
    GET_TEAM_CASES = '$core/ava/GET_TEAM_CASES',
    SET_DISPLAY_ERROR = '$core/ava/SET_DISPLAY_ERROR',
}

export type AvaCoreActions = IGetUserTeamsAction | IGetTeamAction
    | IUpdateIsActiveTeamCanaryEnrolledAction
    | IUpdateCurrentUserSettingsAction
    | IGetTeamCasesAction;

export interface IGetUserTeamsAction extends IScopedAction {
    type: AvaActionTypes.GET_USER_TEAMS,
    payload: {
        scope: IScope,
        queryString?: string
    }
}

export interface IGetTeamAction extends IScopedAction {
    type: AvaActionTypes.GET_TEAM,
    payload: {
        teamsTeamId: string,
        queryString?: string,
        scope: IScope,
        switchTeam?: boolean
    }
}

export interface IUpdateIsActiveTeamCanaryEnrolledAction extends IAction {
    type: AvaActionTypes.UPDATE_IS_ACTIVE_TEAM_CANARY_ENROLLED,
    payload: {
        teamsTeamId: string,
        isEnrolled: boolean
    }
}


export interface IUpdateCurrentUserSettingsAction extends IAction {
    type: AvaActionTypes.UPDATE_CURRENT_USER_SETTINGS,
    payload: {
        userSettings: UserSettings
    }
}

export interface IGetTeamCasesAction extends IScopedAction {
    type: AvaActionTypes.GET_TEAM_CASES,
    payload: {
        teamsTeamId: string,
        scope: IScope,
    }
}

export interface IDisplayErrorBoundary {
    display: boolean,
    message?: string,
}

export interface IInitialContainerProps {
    isLoading: boolean,
    displayError?: boolean,
}

export interface IDisplayErrorBoundary {
    display: boolean,
    message?: string,
}
