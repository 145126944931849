/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';
import 'moment-timezone';

import { ISchedulePanelProps } from '../../../Models/teamsettings.data';
import { teamSettingsSelectors } from '../../../Store/Selectors';

import { AlwaysOnScheduleDetails } from '../../../Components/AlwaysOnScheduleDetails/AlwaysOnScheduleDetails';
import { IcmScheduleDetails } from '../../../Components/IcmScheduleDetails/IcmScheduleDetails';
import { WeeklyScheduleDetails } from '../../../Components/WeeklyScheduleDetails/WeeklyScheduleDetails';
import '../../../TeamSettings.scss';
import './ScheduleDetailsTab.scss';

export const ScheduleDetailsTab = (props: ISchedulePanelProps) => {
    const scheduleType = useSelector(teamSettingsSelectors.getSelectedScheduleType) || "Weekly";

    if (scheduleType === "AlwaysOn") {
        return (<AlwaysOnScheduleDetails/>);
    }
    else if (scheduleType === "IcM") {
        return (<IcmScheduleDetails {...props}/>);
    }
    else if (scheduleType === "Weekly") {
        return (<WeeklyScheduleDetails {...props}/>);
    }
    else {
        return (<div></div>)
    }
}