import { IDashboardState } from './State';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Overview } from '../Models/dashboard.data';

const initialState: IDashboardState = {
};

const teamOverviewSlice = createSlice({
    name: '$team_overview',
    initialState,
    reducers: {
        setOverview(state: IDashboardState, action: PayloadAction<Overview | undefined>): void {
            state.overview = action.payload;
        },
        resetOverview(): IDashboardState {
            return initialState;
        },
    }
});

export const {
    setOverview,
    resetOverview,
  } = teamOverviewSlice.actions;
  
  export default teamOverviewSlice.reducer;