import React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { teamSettingsSelectors } from '../../Store/Selectors';
import { actions } from '../../Store/Slice';
import '../ScheduleDetailsComponents.scss';

/**
 * Implements the AlwaysOn schedule component.
 */
export const AlwaysOnScheduleDetails = (): JSX.Element => {
    const title = '24/7 schedule';
    const dispatch = useDispatch();
    const schedulesToSave = useSelector(teamSettingsSelectors.getSchedulesToSave) || [];
    
    let startHour = '00:00';
    let endHour = '23:59';
    let daysOfWeek = 'Sun,Mon,Tue,Wed,Thu,Fri,Sat';
    let timeZone = 'UTC';

    React.useEffect(() => {
        if(schedulesToSave && schedulesToSave.length > 0) {
            const updatedSchedules = _.cloneDeep(schedulesToSave);
    
            updatedSchedules.forEach(s => {
                s.scheduleDetails.forEach(details => {
                    details.daysOfWeek = daysOfWeek;
                    details.start = startHour;
                    details.end = endHour;
                    details.timeZone = timeZone;
                    details.icmSchedule = undefined;
                });
            });
    
            dispatch(actions.setSchedulesToSave(updatedSchedules));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <div>
            <div className="component-header component-title" role="heading" aria-level={2}>{title}</div>
            <div className="component-subtitle">By creating a 24/7 schedule the users selected will be marked as available at all times across the selected channels.</div>
        </div>
    );
}