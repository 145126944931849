import React from 'react';
import { RoleMetrics } from '../../Models/dashboard.data'
import './UserRolesSection.scss';

interface IUserRolesSectionProps {
    roleMetrics?: Array<RoleMetrics>
}

export const UserRolesSection = (props: IUserRolesSectionProps): JSX.Element => {
    return (
        <div className="ms-Grid-col ms-sm3 user-role-section">
            <div className="ms-Grid-row ms-fontSize-16 ms-fontWeight-bold header">
                <span>Users by role</span>
            </div>
            {renderRoleCount(props.roleMetrics ?? [])}
        </div>
    );
}

function renderRoleCount(users: Array<RoleMetrics>): JSX.Element[] {
    const userRoles = [];

    for (let user of users) {
        userRoles.push(
            <div className="ms-Grid-row details" data-testid="role-metrics" key={`${user.role.id}_metrics`}>
                <h2 className="ms-fontSize-13 name" data-testid="role-name">{user.role.name}</h2>
                <div className="ms-fontSize-13 count" data-testid="role-count">{user.count}</div>
            </div>
        );
    }

    return userRoles;
}