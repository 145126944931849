import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { ElxPicker, IPickerItem, ElxDropdown, ElxLink } from '@elixir/components';
import { IDropdownOption } from '@fluentui/react';

import { ISchedulePanelProps, ScheduleDetailsCollection, ScheduleType, scheduleTypes } from '../../../Models/teamsettings.data';
import { teamSettingsSelectors } from '../../../Store/Selectors';
import { coreSelectors } from '../../../../Core/Store/Selectors';
import { ChannelDetails, UserDetails, CaseOfferingDetails, RoleDetails } from '../../../../Core/core.data';
import { actions } from '../../../Store/Slice';
import '../../../TeamSettings.scss';
import { PANEL_INPUT_WIDTH } from '../../../../TeamSettings';

import './BasicDetails.scss';

export const BasicDetails = (props: ISchedulePanelProps): JSX.Element => {
    const dispatch = useDispatch();

    const [selectedOfferings, setSelectedOfferings] = React.useState<(CaseOfferingDetails | undefined)[] | undefined>([]);
    const [selectedChannels, setSelectedChannels] = React.useState<(ChannelDetails | undefined)[] | undefined>([]);
    const [selectedUsers, setSelectedUsers] = React.useState<(UserDetails)[] | undefined>([]);
    const [selectedType, setSelectedType] = React.useState<(ScheduleType) | undefined>();
    const [selectedRoleOverride, setSelectedRoleOverride] = React.useState<(RoleDetails) | undefined>();

    const [selectedOfferingsKeys, setSelectedOfferingsKeys] = React.useState<string[]>([]);
    const [selectedChannelsKeys, setSelectedChannelsKeys] = React.useState<string[]>([]);
    const [selectedUsersKeys, setSelectedUsersKeys] = React.useState<string[]>([]);
    const [selectedTypeKey, setSelectedTypeKey] = React.useState<string>('');

    const [roleOptions, setRoleOptions] = React.useState([] as IDropdownOption[]);
    const [hideAdvancedOptions, setHideAdvancedOptions] = React.useState<boolean>(true);

    // Get the schedules currently being edited, if any
    const schedulesToSave = useSelector(teamSettingsSelectors.getSchedulesToSave);
    const unselectedSchedules = useSelector(teamSettingsSelectors.getUnselectedSchedules);

    const availableRoles = useSelector(teamSettingsSelectors.getTeamRoles);

    React.useEffect(() => {
        var roleOptions = [] as IDropdownOption[];
        availableRoles.roleDetails.forEach((role) => {
            if (role) {
                let option: IDropdownOption = {
                    key: String(role.id),
                    text: role.name,
                    data: role,
                    selected: selectedRoleOverride?.id === role.id
                };
                roleOptions.push(option);
            }
        });
        setRoleOptions(roleOptions);  
    }, [availableRoles, selectedRoleOverride, setRoleOptions]);

    // Flags used for placeholder text
    let sameChannels = true;
    let sameOffering = true;
    let sameType = true;
    let sameRoleOverride = true;

    // Do all the selected rows have the same channels, offering, and schedule type
    if (schedulesToSave && schedulesToSave.length > 1) {
        // Get the first entry and get all of the channels
        const firstChannelSet = new Set<string>();
        schedulesToSave[0].scheduleDetails.forEach(s => {
            firstChannelSet.add(s.channel?.teamsChannelId || '');
        });
        // Convert it to an array
        const firstChannelList = Array.from(firstChannelSet);

        // Go through the rest of the channels in the channel details
        sameChannels = schedulesToSave.every((s, i) => {
            // Skip the first entry, it's already accounted for
            if (i === 0) {
                return true;
            }
            // Get the current available channels
            const currentChannelSet = new Set<string>();
            s.scheduleDetails.forEach(sd => {
                currentChannelSet.add(sd.channel?.teamsChannelId || '');
            });

            // Convert the current channels from a set to an array
            const currentChannelList = Array.from(currentChannelSet);

            return (firstChannelList.length === currentChannelList.length) && firstChannelList.every((c, i) =>  c === currentChannelList[i]);
        });

        // Get the first entry and get all of the offerings
        const firstOfferingSet = new Set<string>();
        schedulesToSave[0].scheduleDetails.forEach(s => {
            firstOfferingSet.add(s.offering?.id || '');
        });
        // Convert it to an array
        const firstOfferingList = Array.from(firstOfferingSet);

        // Go through the rest of the offerings
        sameOffering = schedulesToSave.every((o, i) => {
            // Skip the first entry, it's already accounted for
            if (i === 0) {
                return true;
            }
            // Get the current available offerings
            const currentOfferingSet = new Set<string>();
            o.scheduleDetails.forEach(sd => {
                currentOfferingSet.add(sd.offering?.id || '');
            });

            // Convert the current offerings from a set to an array
            const currentOfferingList = Array.from(currentOfferingSet);

            return (firstOfferingList.length === currentOfferingList.length) && firstOfferingList.every((o, i) => o === currentOfferingList[i]);
        });

        // Get the first entry and get all of the schedule types
        const firstTypeSet = new Set<string>();
        schedulesToSave[0].scheduleDetails.forEach(s => {
            firstTypeSet.add(s.scheduleType);
        });
        // Convert it to an array
        const firstTypeList = Array.from(firstTypeSet);

        // Go through the rest of the type
        sameType = schedulesToSave.every((o, i) => {
            // Skip the first entry, it's already accounted for
            if (i === 0) {
                return true;
            }
            // Get the current available types
            const currentTypeSet = new Set<string>();
            o.scheduleDetails.forEach(sd => {
                currentTypeSet.add(sd.scheduleType);
            });

            // Convert the current types from a set to an array
            const currentTypesList = Array.from(currentTypeSet);

            return (firstTypeList.length === currentTypesList.length) && firstTypeList.every((o, i) => o === currentTypesList[i]);
        });

        if (schedulesToSave[0].scheduleDetails && schedulesToSave[0].scheduleDetails.length > 0) {
            const firstRoleOverride = schedulesToSave[0].scheduleDetails[0].role;

            // Go through the rest of the role overrides
            sameRoleOverride = schedulesToSave.every((o, i) => {
                // Skip the first entry, it's already accounted for
                if (i === 0) {
                    return true;
                }
                // Get the role override
                const currentRoleOverride = o.scheduleDetails[0].role;

                return firstRoleOverride?.id === currentRoleOverride?.id;
            });
        }
    }
    
    // Any changes to schedules being edited/saved should be used to populate selected channels
    React.useEffect(() => {
        if (schedulesToSave && schedulesToSave.length > 0) {
            const availableUsers = new Map<string, UserDetails>();
            const availableOfferings = new Map<string, CaseOfferingDetails | undefined>();
            const availableChannels = new Map<string, ChannelDetails | undefined>();
            const availableTypes = new Map<string, string>();
            const roles = new Map<number | undefined, RoleDetails | undefined>();

            schedulesToSave.forEach(s => { 
                availableUsers.set(s.user?.alias || '', s.user);

                if(s?.scheduleDetails && s?.scheduleDetails[0]) {
                    roles.set(s?.scheduleDetails[0]?.role?.id, s.scheduleDetails[0].role);
                }

                s.scheduleDetails.forEach(details => {
                    availableOfferings.set(details.offering?.id || '', details.offering);
                    availableChannels.set(details.channel?.teamsChannelId || '', details.channel);
                    availableTypes.set(details.scheduleType, details.scheduleType);
                })
            });

            if (availableOfferings.size !== 0) {
                setSelectedOfferings(Array.from(availableOfferings.values()));
            }
            
            if (availableChannels.size !== 0) {
                setSelectedChannels(Array.from(availableChannels.values()));
            }
            
            if (availableChannels.size !== 0 && availableOfferings.size !== 0) {
                setSelectedUsers(Array.from(availableUsers.values()));
            }

            if (availableChannels.size !== 0 && availableOfferings.size !== 0 && 
                availableUsers.size !== 0 && availableTypes.size === 1) {
                let matchingtypes = scheduleTypes.filter(s => s.key === availableTypes.get(s.key));
                if (matchingtypes.length === 1) {
                    setSelectedType(matchingtypes[0]);
                }
            }

            if (roles.size === 1) {
                setSelectedRoleOverride(roles.values().next().value);
            }
        }   
    }, [schedulesToSave, setSelectedOfferings, setSelectedChannels, setSelectedUsers, setSelectedType, setSelectedRoleOverride]);

    // Update the selections on updating the values of selected fields
    React.useEffect(() => {
        const defaultOfferingsSet: Set<string> = new Set<string>();
        const defaultChannelsSet: Set<string> = new Set<string>();

        if (sameChannels || selectedChannels?.length === 0) {
            selectedChannels?.forEach((c: ChannelDetails | undefined) => {
                defaultChannelsSet.add(`channel_options_${c?.teamsChannelId}`);
            });
            setSelectedChannelsKeys(Array.from(defaultChannelsSet));
        }

        if (sameOffering || selectedOfferings?.length === 0) {
            selectedOfferings?.forEach((o: CaseOfferingDetails | undefined) => {
                defaultOfferingsSet.add(`offering_options_${o?.id}`);
            });
            setSelectedOfferingsKeys(Array.from(defaultOfferingsSet));
        }

        if (sameType) {
            setSelectedTypeKey(selectedType?.key || '');
            dispatch(actions.setSelectedScheduleType(selectedType?.key || ''));
        }
        
    }, [dispatch, selectedChannels, selectedOfferings, selectedType, setSelectedChannelsKeys, setSelectedOfferingsKeys, setSelectedTypeKey, sameChannels, sameOffering, sameType]);

    // Monitor changes in users
    React.useEffect(() => {
        const defaultUsersSet: Set<string> = new Set<string>();

        selectedUsers?.forEach((u: UserDetails | undefined) => {
            defaultUsersSet.add(`user_options_${u?.alias}`);
        });

        setSelectedUsersKeys(Array.from(defaultUsersSet));
    }, [selectedUsers, setSelectedUsersKeys])
    
    const disabled = props.schedule ? true : false;

    const usersCollection = useSelector(teamSettingsSelectors.getTeamUsers);

    const offerings = useSelector(coreSelectors.getCaseOfferings) || [];
    const channels = useSelector(coreSelectors.getChannels) || [];
    const users = usersCollection.userDetails;

    const channelsPlaceholder = sameChannels ? 'Select channels' : 'Multiple values';
    const offeringsPlaceholder = sameOffering ? 'Select offerings' : 'Multiple values';
    const typePlaceholder = sameType ? 'Select type' : 'Multiple values';
    const roleOverridePlaceholder = sameRoleOverride ? 'Select role override' : 'Multiple values';

    const offeringOptions: IPickerItem[] = offerings.map(offering => (
        {
            key: `offering_options_${offering.id}`,
            text: offering.name,
            data: offering
        }
    ));
    const channelOptions: IPickerItem[] = channels.map(channel => (
        {
            key: `channel_options_${channel.teamsChannelId}`,
            text: channel.name,
            data: channel
        }
    ));
    const userOptions: IPickerItem[] = users.map(user => (
        {
            key: `user_options_${user.alias}`,
            text: user.name,
            data: user
        }
    ));
    const typeOptions: IDropdownOption[] = scheduleTypes.map(t => (
        {
            key: t.key,
            text: t.label,
            data: t
        }
    ));

    // Maps used to keep track of what schedule details are assigned to a given user
    const timezoneMap = new Map<number, string | undefined>();
    const daysOfWeekMap = new Map<number, string | undefined>();
    const startTimeMap = new Map<number, string | undefined>();
    const endTimeMap = new Map<number, string | undefined>();
    const scheduleTypeMap = new Map<number, string | undefined>();

    if (schedulesToSave && schedulesToSave.length > 0) {
        schedulesToSave.forEach(s => {
            timezoneMap.set(s.user.userId, s.scheduleDetails[0]?.timeZone);
            daysOfWeekMap.set(s.user.userId, s.scheduleDetails[0]?.daysOfWeek);
            startTimeMap.set(s.user.userId, s.scheduleDetails[0]?.start);
            endTimeMap.set(s.user.userId, s.scheduleDetails[0]?.end);
            scheduleTypeMap.set(s.user.userId, s.scheduleDetails[0]?.scheduleType);
        });
    }

    const onSelectedOfferings = (selectedKeys?: string[]) => {
        const selected: CaseOfferingDetails[] = [];
        offeringOptions.forEach(option => {
            if (selectedKeys?.some((x: string | number) => x === option.key)) {
                selected.push(option.data);
            }
        });

        let savedSchedules = _.cloneDeep(schedulesToSave);
        if (props.schedule === undefined || props.schedule.length === 0) {
            savedSchedules?.forEach(s => s.scheduleDetails = []);
        }

        const unselected = schedulesToSave?.map(s => ({
            user: s.user,
            scheduleDetails: s.scheduleDetails.filter(details => !selected.some(o => o.id === details.offering?.id))
        })).filter(s => s.scheduleDetails.length > 0) || [];

        dispatch(actions.setUnselectedSchedules(unselectedSchedules?.concat(unselected) || []));

        // If we're currently holding onto schedules to edit
        if (savedSchedules && savedSchedules.length > 0) {
            // Filter out only those schedules that match these selected offering
            let filteredSchedules: ScheduleDetailsCollection[] = savedSchedules.map(s => ({
                user: s.user,
                scheduleDetails: s.scheduleDetails.filter(details => selected.some(o => o.id === details.offering?.id))
            }));

            // If the filteredSchedules have a user that has an entry that has no schedule details, they probably 
            // didn't have a schedule for these offerings. 
            filteredSchedules?.forEach(s => {
                selected.forEach(offering => {
                    selectedChannels?.forEach(channel => { 
                        if (!s.scheduleDetails.some(details => details.channel?.teamsChannelId === channel?.teamsChannelId && details.offering?.id === offering?.id)) {
                            s.scheduleDetails.push({
                                channel: channel, 
                                offering: offering,
                                daysOfWeek: daysOfWeekMap.get(s.user.userId),
                                end: endTimeMap.get(s.user.userId),
                                start: startTimeMap.get(s.user.userId),
                                timeZone: timezoneMap.get(s.user.userId),
                                scheduleType: scheduleTypeMap.get(s.user.userId) || '0'
                            });
                        }
                    });
                });
            });

            //TODO: Save the unselected schedules to the store
            dispatch(actions.setSchedulesToSave(filteredSchedules));
        }
        setSelectedOfferings(selected);
    };

    const onSelectedChannels = (selectedKeys?: string[]) => {
        const selected: ChannelDetails[] = [];
        channelOptions.forEach(option => {
            if (selectedKeys?.some((x: string | number) => x === option.key)) {
                selected.push(option.data);
            }
        });

        let savedSchedules = _.cloneDeep(schedulesToSave);
        if (props.schedule === undefined || props.schedule.length === 0) {
            savedSchedules?.forEach(s => s.scheduleDetails = []);
        }

        const unselected = schedulesToSave?.map(s => ({
            user: s.user,
            scheduleDetails: s.scheduleDetails.filter(details => !selected.some(c => c.teamsChannelId === details.channel?.teamsChannelId))
        })).filter(s => s.scheduleDetails.length > 0) || [];

        dispatch(actions.setUnselectedSchedules(unselectedSchedules?.concat(unselected) || []));

        // If we're currently holding onto schedules to edit
        if (savedSchedules && savedSchedules.length > 0) {
            // Filter out only those schedules that match these selected channels
            let filteredSchedules: ScheduleDetailsCollection[] = savedSchedules.map(s => ({
                user: s.user,
                scheduleDetails: s.scheduleDetails.filter(details => selected.some(c => c.teamsChannelId === details.channel?.teamsChannelId))
            }));

            filteredSchedules?.forEach(s => {
                selectedOfferings?.forEach(offering => {
                    // If the filteredSchedules have a user that has an entry that has no schedule details, they probably 
                    // didn't have a schedule for these channels. 
                    selected.forEach(channel => { 
                        if (!s.scheduleDetails.some(details => details.channel?.teamsChannelId === channel.teamsChannelId && details.offering?.id === offering?.id)) {
                            s.scheduleDetails.push({
                                channel: channel, 
                                offering: offering,
                                daysOfWeek: daysOfWeekMap.get(s.user.userId),
                                end: endTimeMap.get(s.user.userId),
                                start: startTimeMap.get(s.user.userId),
                                timeZone: timezoneMap.get(s.user.userId),
                                scheduleType: scheduleTypeMap.get(s.user.userId) || '0',
                                role: selectedRoleOverride
                            });
                        }
                    });
                });
            });

            dispatch(actions.setSchedulesToSave(filteredSchedules));
        }
        setSelectedChannels(selected);
    };

    const onSelectedUsers = (selectedKeys?: string[]) => {
        const selected: UserDetails[] = [];
        userOptions.forEach(option => {
            if (selectedKeys?.some((x: string | number) => x === option.key)) {
                selected.push(option.data);
            }
        });

        // Should always be at least an empty list
        if (schedulesToSave) {
            // Filter out only those schedules that match these selected users
            let filteredSchedules: ScheduleDetailsCollection[] = schedulesToSave.filter(s => selected.some(u => u.userId === s.user.userId))
                .filter(f => f.scheduleDetails.length > 0);

            // With the filtered schedules on hand, find which users are new (aren't in the filtered list)
            const newUsers = selected.filter(u => !filteredSchedules.some(s => u.userId === s.user.userId));

            // For all the selected users, 
            newUsers.forEach(u => {
                // If no offerings are available, insert new user with no offering
                if (selectedOfferings && selectedOfferings.length < 1) {
                    filteredSchedules.push({user: u, scheduleDetails: []})
                }
                else {
                    selectedOfferings?.forEach(offering => {
                        const schedule = selectedChannels?.map(channel => ({ 
                            channel: channel,
                            offering: offering,
                            scheduleType: scheduleTypeMap.get(u.userId) || '0',
                            userRole: selectedRoleOverride
                        }));

                        const userSchedule: ScheduleDetailsCollection = {user: u, scheduleDetails: schedule || []}
            
                        filteredSchedules.push(userSchedule);
                    });
                }
            });

            //TODO: Save the unselected schedules to the store
            dispatch(actions.setSchedulesToSave(filteredSchedules));
        }
        else {
            setSelectedUsers(selected);
        }
    };

    const roleOverrideDescription: string = "Setting a role override for a schedule will change the selected users' "
        + " roles on the specified channel for the duration of the schedule.\n\nUse this option if you want users "
        + "to be notified of cases as part of multiple roles. (For example, if you want a user to be an SME on one channel but a PG on a second channel.)";

    const hideRoleOverrideLinkText: string = hideAdvancedOptions ? "Show advanced options" : "Hide advanced options";

    const onScheduleTypeChange = (option?: IDropdownOption) => {
        const key = (option?.key || '').toString();
        dispatch(actions.setSelectedScheduleType(key));
        setSelectedTypeKey(key);        
        setSelectedType(option?.data);

        const updatedSchedulesToSave = _.cloneDeep(schedulesToSave) || [];

        updatedSchedulesToSave?.forEach(s => {
            s.scheduleDetails.forEach(details => {
                details.scheduleType = key
            })
        });

        dispatch(actions.setSchedulesToSave(updatedSchedulesToSave));
    };

    const onRoleOverrideChange = (option?: IDropdownOption) => {
        const roleId = parseInt(option?.key.toString() || '0');
        const selectedRole = availableRoles.roleDetails.find(role => role.id === roleId);

        const updatedSchedulesToSave = _.cloneDeep(schedulesToSave) || [];
        updatedSchedulesToSave?.forEach(s => {
            s.scheduleDetails.forEach(details => {
                details.role = selectedRole
            });
        });

        setSelectedRoleOverride(selectedRole);
        dispatch(actions.setSchedulesToSave(updatedSchedulesToSave));
    }

    const sortPickerOptions = (items: IPickerItem<any>[], sortField: keyof IPickerItem<any>, sortOrder: string[]) => {
        var sortingMap = new Map<string, number>();
        sortOrder.forEach((entry, index) => {
            sortingMap.set(entry, index);
        });
        return items.sort((a, b) => (sortingMap.get(a[sortField]) || 0) - (sortingMap.get(b[sortField]) || 0))
    };

    return(
        <form className="team-settings-tile">
            <ElxPicker
                label="Channels"
                multiSelect={true}
                items={channelOptions.sort((c1, c2) => c1.text.localeCompare(c2.text))}
                onMultiSelectChange={onSelectedChannels}
                selectedKeys={selectedChannelsKeys}
                placeHolder={channelsPlaceholder}
                calloutWidth={PANEL_INPUT_WIDTH}
            />
            <ElxPicker
                label="Offerings"
                multiSelect={true}
                items={sortPickerOptions(offeringOptions, "text", ['Chat', 'ARR', 'Premier', 'BC', 'Community', 'No ID'])}
                onMultiSelectChange={onSelectedOfferings}
                selectedKeys={selectedOfferingsKeys}
                placeHolder={offeringsPlaceholder}
                calloutWidth={PANEL_INPUT_WIDTH}
            />
            <ElxPicker
                label="Users"
                multiSelect={true}
                items={userOptions.sort((u1, u2) => u1.text.localeCompare(u2.text))}
                disabled={disabled}
                onMultiSelectChange={onSelectedUsers}
                selectedKeys={selectedUsersKeys}
                placeHolder="Select users"
                calloutWidth={PANEL_INPUT_WIDTH}
            />
            <ElxDropdown
                label="Schedule Type"
                options={typeOptions}
                emptyOption={false}
                selectedKey={selectedTypeKey}
                placeholder={typePlaceholder}
                disabled={false}
                onChange={(e, option, index) => onScheduleTypeChange(option)}
            />
            <div className="role-override" >
                <div hidden={hideAdvancedOptions}>
                    <div className="role-override-label">
                        Role override
                    </div>
                    <div className="role-override-description">
                        {roleOverrideDescription}
                    </div>
                    <ElxDropdown
                        label=""
                        multiSelect={false}
                        options={roleOptions.sort((u1, u2) => u1.text.localeCompare(u2.text))}
                        disabled={false}
                        selectedKey={selectedRoleOverride?.id?.toString()}
                        placeHolder={roleOverridePlaceholder}
                        emptyOption={true}
                        onChange={(e, option, index) => onRoleOverrideChange(option)}
                        ariaLabel="Role override"
                    />
                </div>
                <ElxLink onClick={() => {setHideAdvancedOptions(!hideAdvancedOptions)}}>{hideRoleOverrideLinkText}</ElxLink>
            </div>
        </form>
    );
}