import {
    ITableAction,
    DisplayTypes,
    ISearchBoxDropdownFilter,
    FilterTypes,
    MatchTypes,
    IElxColumn,
} from '@elixir/components';
import { IDropdownOption } from '@fluentui/react';
import { CaseDataDetails } from "../Modules/Core/core.data";
import { getTimeDifference } from "./Core.utility";
import moment from 'moment';

export const CASE_REFRESH_WAIT_TIME = 30 * 1000;

const CASE_STATUS_WAITING_FOR_EXPERT = 'Waiting For Expert';
const CASE_STATUS_EXPERT_ASSIGNED = 'Expert Assigned';
const CASE_STATUS_PENDING_OUTCOME = 'Pending Outcome';
const CASE_STATUS_ARCHIVED = 'Archived';
const CASE_STATUS_WAITING_FOR_PG = 'Waiting for PG';
const CASE_STATUS_PG_ASSIGNED = 'PG assigned';

const statusOptions: IDropdownOption[] = [
    {
        key: CASE_STATUS_WAITING_FOR_EXPERT,
        text: CASE_STATUS_WAITING_FOR_EXPERT
    },
    {
        key: CASE_STATUS_EXPERT_ASSIGNED,
        text: CASE_STATUS_EXPERT_ASSIGNED
    },
    {
        key: CASE_STATUS_PENDING_OUTCOME,
        text: CASE_STATUS_PENDING_OUTCOME
    },
    {
        key: CASE_STATUS_ARCHIVED,
        text: CASE_STATUS_ARCHIVED
    },
    {
        key: CASE_STATUS_WAITING_FOR_PG,
        text: CASE_STATUS_WAITING_FOR_PG
    },
    {
        key: CASE_STATUS_PG_ASSIGNED,
        text: CASE_STATUS_PG_ASSIGNED
    },
];

/**
 * Get table actions for cases page.
 */
export function getCasesTableActions(): ITableAction[] {
    return [
        {
            key: 'actionView',
            text: 'View in Teams',
            iconProps: { iconName: 'View' },
            disableBulkAction: true,
            onAction: (item: any) => window.open(item.teamsUrl, '_blank'),
        },
        {
            key: 'actionOpenRAVE',
            text: 'Open in RAVE',
            iconProps: { iconName: 'View' },
            disableBulkAction: true,
            onDisplay: (item: any) => item.raveUrl ? DisplayTypes.Show : DisplayTypes.Hide,
            onAction: (item: any) => window.open(item.raveUrl, '_blank'),
        },
        {
            key: 'actionOpenSD',
            text: 'Open in Service Desk',
            iconProps: { iconName: 'View' },
            disableBulkAction: true,
            onDisplay: (item: any) => item.serviceDeskUrl ? DisplayTypes.Show : DisplayTypes.Hide,
            onAction: (item: any) => window.open(item.serviceDeskUrl, '_blank'),
        },
        {
            key: 'actionOpenASC',
            text: 'Open in ASC',
            iconProps: { iconName: 'View' },
            disableBulkAction: true,
            onDisplay: (item: any) => item.ascUrl ? DisplayTypes.Show : DisplayTypes.Hide,
            onAction: (item: any) => window.open(item.ascUrl, '_blank'),
        },
        {
            key: 'actionOpenDynamics',
            text: 'Open in OneSupport',
            iconProps: { iconName: 'View' },
            disableBulkAction: true,
            onDisplay: (item: any) => item.dynamicsUrl ? DisplayTypes.Show : DisplayTypes.Hide,
            onAction: (item: any) => window.open(item.dynamicsUrl, '_blank'),
        },
    ];
}

/**
 * Get team filter for cases page.
 * @param cases Cases data.
 */
export function getTeamFilter(cases: CaseDataDetails[]): ISearchBoxDropdownFilter {
    return {
        type: FilterTypes.Dropdown,
        key: 'teamChannelFilter',
        label: 'Team',
        matchType: MatchTypes.Contains,
        filterProps: {
            multiSelect: true,
            options: getTeamOptions(cases),
            dropdownWidth: 200,
        },
        displayInline: true,
    };
}

/**
 * Get channel filter for cases page.
 * @param cases Cases data.
 */
export function getChannelFilter(cases: CaseDataDetails[]): ISearchBoxDropdownFilter {
    return {
        type: FilterTypes.Dropdown,
        key: 'teamChannel',
        label: 'Channel',
        matchType: MatchTypes.Contains,
        filterProps: {
            multiSelect: true,
            options: getChannelOptions(cases),
            dropdownWidth: 200,
        },
        displayInline: true,
    };
}

/**
 * Get severity filter for cases page.
 * @param cases Cases data.
 */
export function getSeverityFilter(cases: CaseDataDetails[]): ISearchBoxDropdownFilter {
    return {
        type: FilterTypes.Dropdown,
        key: 'severity',
        label: 'Severity',
        matchType: MatchTypes.Exact,
        filterProps: {
            multiSelect: true,
            options: getSeverityOptions(cases),
            dropdownWidth: 100,
        },
        displayInline: true,
    };
}

/**
 * Get offering filter for cases page.
 * @param cases Cases data.
 */
export function getOfferingFilter(cases: CaseDataDetails[]): ISearchBoxDropdownFilter {
    return {
        type: FilterTypes.Dropdown,
        key: 'offering',
        label: 'Offering',
        matchType: MatchTypes.Exact,
        filterProps: {
            multiSelect: true,
            options: getOfferingOptions(cases),
            dropdownWidth: 150,
        },
        displayInline: true,
    };
}

/**
 * Get status filter for cases page.
 * @param cases Cases data.
 */
export function getStatusFilter(cases: CaseDataDetails[]): ISearchBoxDropdownFilter {
    return {
        type: FilterTypes.Dropdown,
        key: 'status',
        label: 'Status',
        matchType: MatchTypes.Exact,
        filterProps: {
            multiSelect: true,
            options: cases.length > 0 ? statusOptions : [],
            dropdownWidth: 150,
        },
        displayInline: true,
    };
}

/**
 * Get case status from case data.
 * @param caseData Case data.
 */
export function getCaseStatus(caseData: CaseDataDetails): string {
    if (caseData.archivalStatus) {
        switch (caseData.archivalStatus.toLowerCase()) {
            case 'pendingoutcome':
                return CASE_STATUS_PENDING_OUTCOME;
            case 'archived':
                return CASE_STATUS_ARCHIVED;
            default:
                return caseData.archivalStatus;
        }
    }
    else if (caseData.pgStatus === 'waitingForPG') {
        return CASE_STATUS_WAITING_FOR_PG;
    }
    else if (caseData.pgStatus === 'pgAssigned') {
        return CASE_STATUS_PG_ASSIGNED;
    }
    else if (caseData.userChecking) {
        return CASE_STATUS_EXPERT_ASSIGNED;
    }
    return CASE_STATUS_WAITING_FOR_EXPERT;
}

/**
 * Convert offering to user readable format.
 * @param offering 
 */
export function convertOffering(offering: string) {
    switch (offering.toLowerCase()) {
        case 'premier':
            return 'Premier';
        case 'bc':
            return 'Broad Commercial';
        case 'arr':
            return 'ARR';
        default:
            return offering;
    }
}

/**
 * Get team options for filter.
 * @param cases Cases data.
 */
function getTeamOptions(cases: CaseDataDetails[]): IDropdownOption[] {
    return Array.from(new Set(cases.map(c => c.team.name)))
        .map(t => ({ key: t, text: t }));
}

/**
 * Get channel options for filter.
 * @param Cases Cases data.
 */
function getChannelOptions(cases: CaseDataDetails[]): IDropdownOption[] {
    return Array.from(new Set(cases.map(c => c.channelName)))
        .map(ch => ({ key: ch, text: ch }));
}

/**
 * Get severity options for filter.
 * @param cases Cases data.
 */
function getSeverityOptions(cases: CaseDataDetails[]): IDropdownOption[] {
    return Array.from(new Set(cases.map(c => c.severity)))
        .map(s => ({ key: s, text: s }));
}

/**
 * Get offering options for filter.
 * @param cases Cases data.
 */
function getOfferingOptions(cases: CaseDataDetails[]): IDropdownOption[] {
    return Array.from(new Set(cases.map(c => c.offering)))
        .map(o => {
            let offering = convertOffering(o);
            return ({ key: offering, text: offering })
        });
}

/**
 * Get time waited for help for the given case.
 * @param caseData Case data.
 * @param isTimeWaitedRequiredInMinutes  boolean if time difference required in minutes.
 */
export function getTimeWaitedForHelp(caseData: CaseDataDetails, getTimeDifferenceFunc: Function = getTimeDifference): string {
    let timeWaitedForHelp: string = "";
    if (caseData.timeCaseReopened) {
        const timeReopened = new Date(caseData.timeCaseReopened + 'Z');
        if (caseData.timeLastChecked && timeReopened < new Date(caseData.timeLastChecked + 'Z')  ) {
            const timeLastChecked = new Date(caseData.timeLastChecked + 'Z');
            timeWaitedForHelp = getTimeDifferenceFunc(timeReopened, timeLastChecked);
        }
        else {
            if (!caseData.archived && caseData.archivalStatus === undefined) {
                timeWaitedForHelp = getTimeDifferenceFunc(timeReopened, new Date(), );
            } else {
                const timeArchived = new Date(caseData.timeArchived + 'Z');
                timeWaitedForHelp = getTimeDifferenceFunc(timeReopened, timeArchived);
            }
        }
    }
    else {
        const timeCreated = new Date(caseData.timeCreated + 'Z');
        if (caseData.timeFirstChecked) {
            const timeFirstChecked = new Date(caseData.timeFirstChecked + 'Z');
            timeWaitedForHelp = getTimeDifferenceFunc(timeCreated, timeFirstChecked);
        }
        else {
            if (!caseData.archived && caseData.archivalStatus === undefined) {
                timeWaitedForHelp = getTimeDifferenceFunc(timeCreated, new Date());
            } else {
                const timeArchived = new Date(caseData.timeArchived + 'Z');
                timeWaitedForHelp = getTimeDifferenceFunc(timeCreated, timeArchived);
            }
        }
    }
    return timeWaitedForHelp;
}

/**
 * Get time difference in minutes between given dates.
 * @param startDate start data.
 * @param endDate end data.
 */
export function getTimeDifferenceInMinutes(startDate: Date, endDate: Date): string {
    return moment(endDate, "HH:mm:ss").diff(moment(startDate, "HH:mm:ss"), 'minutes').toString();
}

/**
 * Sort column wait time.
 * @param column: IElxColumn.
 * @param a Elixir table data for first row.
 * @param b Elixir table data for second row.
 */
 export function sortWaitTime(column: IElxColumn, a: any, b: any): number {
    let result = (!isNaN(a.timeWaitedInMinutes) && !isNaN(b.timeWaitedInMinutes)
                    && Number(a.timeWaitedInMinutes) > Number(b.timeWaitedInMinutes))
                    ? 1 : -1;                    
    return column.isSortedDescending ? result * -1 : result;
}

/**
 * Sort column case numbers.
 * @param column: IElxColumn.
 * @param a Elixir table data for first row.
 * @param b Elixir table data for second row.
 */
 export function sortCaseNumber(column: IElxColumn, a: any, b: any): number {
    let result = a.caseId > b.caseId ? 1 : -1;
    return column.isSortedDescending ? result * -1 : result;
}

/**
 * Get 'Life and Safety' customer fields to be displayed on portal.
 * @param caseData case data.
 */
export function getLifeAndSafetyString(caseData: CaseDataDetails): string {
    let lifeAndSafetyString = "";
    if (caseData.customerProgramPriority !== undefined && caseData.customerProgramType !== undefined) {
        lifeAndSafetyString = caseData.customerProgramType + " (Pri " + caseData.customerProgramPriority + ")";
    }
    return lifeAndSafetyString;
}