import React from "react";
import { IColumn, SelectionMode } from '@fluentui/react';
import { ElxTable } from '@elixir/components';
import {
  NotificationDelayDetails,
  TeamSettingsCaseNotificationTimes,
} from "../../../Models/teamsettings.data";
import { IGeneralTileProps } from "../General";
import { SettingsTileHeader } from "../SettingsTileHeader/SettingsTileHeader";
import "./NotificationIntervalsTile.scss";

interface INotificationIntervalsTileProps extends IGeneralTileProps {
  notificationIntervals: TeamSettingsCaseNotificationTimes;
  notificationDelayDetails?: NotificationDelayDetails;
}

const columns: IColumn[] = [
  {
    key: "column_SeverityName",
    name: "Severity",
    fieldName: "severityName",
    minWidth: 70,
    maxWidth: 70,
    isResizable: false,
    isRowHeader: true,
  },
  {
    key: "column_ChatInterval",
    name: "Chat",
    fieldName: "chat",
    minWidth: 90,
    maxWidth: 90,
    isResizable: false,
  },
  {
    key: "column_ARRInterval",
    name: "ARR",
    fieldName: "arrInterval",
    minWidth: 90,
    maxWidth: 90,
    isResizable: false,
  },
  {
    key: "column_PremierInterval",
    name: "Premier",
    fieldName: "premierInterval",
    minWidth: 90,
    maxWidth: 90,
    isResizable: false,
  },
  {
    key: "column_BroadCommInterval",
    name: "Broad Commercial",
    fieldName: "broadCommInterval",
    minWidth: 170,
    maxWidth: 170,
    isResizable: false,
  },
];

const supportRequestColumns: IColumn[] = [
  {
    key: "column_Priority",
    name: "Priority",
    fieldName: "priority",
    minWidth: 100,
    maxWidth: 100,
    isResizable: false,
    isRowHeader: true,
  },
  {
    key: "column_Community",
    name: "Community",
    fieldName: "community",
    minWidth: 110,
    maxWidth: 110,
    isResizable: false,
  },
  {
    key: "column_noId",
    name: "No ID",
    fieldName: "noId",
    minWidth: 110,
    maxWidth: 110,
    isResizable: false,
  },
];

export const NotificationIntervalsTile = (
  props: INotificationIntervalsTileProps
): JSX.Element => {
  const title = "Notification intervals";
  const description = props.notificationDelayDetails?.isEnabled
    ? "Notifications will begin " +
      props.notificationDelayDetails.minutes +
      " minutes after posting"
    : "Notifications will be sent immediately";
  const tableItems = getIntervalsTableData(props.notificationIntervals);
  const supportRequestItems = getSupportRequestTableData(
    props.notificationIntervals
  );

  return (
    <div className="setting-tile notification-intervals-tile">
      <SettingsTileHeader
        title={title}
        description={description}
        tab={0}
        onEdit={props.onEdit}
      />
      <hr />
      <h3>Cases with a support request</h3>
      <ElxTable
        columns={columns}
        items={tableItems}
        selectionMode={SelectionMode.none}
        ariaLabelForGrid="Cases with a support request"
      />
      <hr />
      <h3>Cases with no support request</h3>
      <ElxTable
        columns={supportRequestColumns}
        items={supportRequestItems}
        selectionMode={SelectionMode.none}
        ariaLabelForGrid="Cases with no support request"
      />
    </div>
  );
};

/**
 * Creates table data objects from the specified NotificationIntervals object
 * @param teamUsers
 */
function getIntervalsTableData(
  intervals: TeamSettingsCaseNotificationTimes
): any[] {
  const items = [];
  items.push({
    severityName: "Sev1",
    chat: intervals.chat.sev1 + " min",
    arrInterval: intervals.arr.sev1 + " min",
    premierInterval: intervals.premier.sev1 + " min",
    broadCommInterval: intervals.bc.sev1 + " min",
  });
  items.push({
    severityName: "SevA",
    chat: intervals.chat.sevA + " min",
    arrInterval: intervals.arr.sevA + " min",
    premierInterval: intervals.premier.sevA + " min",
    broadCommInterval: intervals.bc.sevA + " min",
  });
  items.push({
    severityName: "SevB",
    chat: intervals.chat.sevB + " min",
    arrInterval: intervals.arr.sevB + " min",
    premierInterval: intervals.premier.sevB + " min",
    broadCommInterval: intervals.bc.sevB + " min",
  });
  items.push({
    severityName: "SevC",
    chat: intervals.chat.sevC + " min",
    arrInterval: intervals.arr.sevC + " min",
    premierInterval: intervals.premier.sevC + " min",
    broadCommInterval: intervals.bc.sevC + " min",
  });
  return items;
}

function getSupportRequestTableData(
  intervals: TeamSettingsCaseNotificationTimes
): any[] {
  const items: any[] = [];

  items.push({
    priority: "High",
    community: intervals.community.high + " min",
    noId: intervals.noID.high + " min",
  });
  items.push({
    priority: "Normal",
    community: intervals.community.normal + " min",
    noId: intervals.noID.normal + " min",
  });

  return items;
}
