import { 
    AvaActionTypes, 
    IGetUserTeamsAction, 
    IGetTeamAction,
    IUpdateIsActiveTeamCanaryEnrolledAction,
    IUpdateCurrentUserSettingsAction,
    IGetTeamCasesAction,
} from './Types';
import { ActionCreator } from 'redux';
import { UserSettings } from '../core.data';
import { IScope } from '@elixir/fx';

export const fetchUserTeamsAction: ActionCreator<IGetUserTeamsAction> = (scope: IScope, queryString?: string) => ({
    type: AvaActionTypes.GET_USER_TEAMS,
    payload: {
        scope,
        queryString
    }
})

/**
 * Gets a specific team from the Ava API
 * @param teamsTeamId The target team
 * @param scope The scope of the container this action was fired from
 * @param queryString Comma delimited string with fields to expand. Available fields are: smeChannels, channels, schedules, roles, and users.
 */
export const fetchTeamAction: ActionCreator<IGetTeamAction> = (teamsTeamId: string, scope: IScope, queryString?: string, switchTeam?: boolean) => ({
    type: AvaActionTypes.GET_TEAM,
    payload: {
        teamsTeamId,
        scope,
        queryString,
        switchTeam
    }
})

export const updateIsActiveTeamCanaryEnrolledAction: ActionCreator<IUpdateIsActiveTeamCanaryEnrolledAction> = (teamsTeamId: string, isEnrolled: boolean) => ({
   type: AvaActionTypes.UPDATE_IS_ACTIVE_TEAM_CANARY_ENROLLED,
   payload: {
       teamsTeamId: teamsTeamId,
       isEnrolled: isEnrolled
   }
})

export const updateCurrentUserSettingsAction: ActionCreator<IUpdateCurrentUserSettingsAction> = (userSettings: UserSettings, scope: IScope) => ({
    type: AvaActionTypes.UPDATE_CURRENT_USER_SETTINGS,
    payload: {
        userSettings,
        scope
    }
})

/**
 * Fetch all cases for the current team.
 * @param teamsTeamId The target team
 * @param scope The scope of the container this action was fired from.
 */
export const fetchTeamCasesAction: ActionCreator<IGetTeamCasesAction> = (teamsTeamId: string, scope: IScope) => ({
    type: AvaActionTypes.GET_TEAM_CASES,
    payload: {
        teamsTeamId,
        scope,
    }
})
