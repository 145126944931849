import { ElxDialog, ElxCopy } from '@elixir/components';

import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentConfig } from '../../../Config';
import { coreSelectors } from '../../Core/Store/Selectors';
import { showContactSupportDialog } from '../../Core/Store/Slice';

import './ContactSupport.scss';

export const ContactSupportDialog = (): JSX.Element => {
    const showDialog = useSelector(coreSelectors.getShowContactSupportDialog);
    const dispatch = useDispatch();
    const avasupp = getCurrentConfig().avaSupportEmail;

    function sendEmail() {
        window.open("mailto:" + avasupp);
    }

    return (
        <ElxDialog
            aria-labelledby='Contact support'
            hidden={!showDialog}
            dismissable={true}
            dialogContentProps={{
                title: "Contact support"
            }}
            primaryButtonProps={{
                text: "Create email",
                onClick: () => sendEmail()
            }}
            cancelButtonProps={{
                text: "Close"
            }}
            onDismiss={() => dispatch(showContactSupportDialog(false))}>
        <div className="contactSupportText" tabIndex={0}>Please use the email address below to contact support.</div>
        <ElxCopy textToCopy={avasupp}>
            <div tabIndex={0}>{avasupp}</div>
        </ElxCopy>
      
      </ElxDialog>
    );
}