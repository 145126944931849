import React from 'react';
import { ElxIconButton } from '@elixir/components';
import './SettingsTileHeader.scss';

interface ISettingsTileHeaderProps {
    title: string,
    tab: number,
    description?: string,
    onEdit(tabIndex: number): void,
}

export const SettingsTileHeader = (props: ISettingsTileHeaderProps): JSX.Element => {
    const title = props.title;
    const description = props.description 
        ? <div className="description">{props.description}</div>
        : undefined;
    const tooltipText = `${title} menu`;

    return(
        <div>
            <div className="header-section">
                <div className="header header-style" role="heading" aria-level={2}>{title}</div>
                <ElxIconButton iconProps={{iconName: 'MoreVertical'}}
                text={tooltipText}
                onRenderMenuIcon={() => null}
                ariaLabel={`${title} Edit icon`}
                menuProps={
                {
                    items:
                    [ 
                        {
                            key: 'SettingsEdit',
                            text: 'Edit',
                            title: 'Edit',
                            onClick: () => {props.onEdit(props.tab)},
                            iconProps: {
                                iconName: 'Edit'
                            }
                        }
                    ]
                }
                }/>   
            </div>
            {description}
        </div>
    );
}