import { IElixirState } from '@elixir/fx';
import { IAvaOverviewState } from './State';

// Ava Overview selector.
const avaOverviewSelector = (state: IElixirState) => state.modules['avaoverview'] as IAvaOverviewState;

/**
 * Team settings selectors.
 */
export const avaOverviewSelectors = {
    /**
   * Gets pending cases for current user
   */
  getPendingCases: (state: IElixirState) => avaOverviewSelector(state).pendingCases,
  /**
   * Gets my cases for current user.
   */
  getMyCases: (state: IElixirState) => avaOverviewSelector(state).myCases,
};
