import React from 'react';
import { useDispatch } from 'react-redux';
import { Toggle } from '@fluentui/react';
import { useCookies, CookiesProvider } from 'react-cookie';
import { setAutoRefreshEnabled } from '../../../Core/Store/Slice';
import '../Toggle.scss';

/**
 * Provides the toggle switch for auto-refresh.
 */
export const AutoRefreshToggle = (): JSX.Element => {
    const dispatch = useDispatch();

    const [cookies, setCookie] = useCookies(['_autoRefreshEnabled']);
    const isEnabled =
        typeof cookies._autoRefreshEnabled === "string"
            ? cookies._autoRefreshEnabled === "true"
            : false;

    React.useEffect(() => {
        dispatch(setAutoRefreshEnabled(isEnabled));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEnabled]);

  const onChange = () => {
    setCookie('_autoRefreshEnabled', String(!isEnabled), {
      path: '/',
      sameSite: 'strict',
    });
  }

  return (
    <div className="toggle">
      <Toggle
        label="Auto-Refresh"
        disabled={false}
        checked={isEnabled}
        inlineLabel
        onText="Enabled"
        offText="Disabled"
        onClick={onChange}
      />
    </div>
  )
}

/**
 * This method is used to render sub-actions for auto-refresh.
 */
export function renderAutoRefreshToggleSubActions(): JSX.Element[] {
    return [
        <CookiesProvider key="AutoRefreshToggleCookie">
            <AutoRefreshToggle />
        </CookiesProvider>,
    ];
}
