import { IElixirState } from '@elixir/fx';
import { ITeamSettingsState } from './State';
import { checkIfValidSchedule } from '../Containers/Schedules/Schedules.utility';

// Team settings selector.
const teamSettingsSelector = (state: IElixirState) => state.modules['teamsettings'] as ITeamSettingsState;

/**
 * Team settings selectors.
 */
export const teamSettingsSelectors = {
    /**
     * Get team roles selector.
     */
    getTeamRoles: (state: IElixirState) => teamSettingsSelector(state).roleDetailsCollection,
    /**
     * Get team users selector.
     */
    getTeamUsers: (state: IElixirState) => teamSettingsSelector(state).userDetailsCollection,
    /**
     * Get the team's schedules.
     */
    getSchedules: (state: IElixirState) => teamSettingsSelector(state).scheduleDetailsCollection,
    /**
     * Get showModifyPanel selector.
     */
    getShowModifyRolePanel: (state: IElixirState) => teamSettingsSelector(state).showModifyRolePanel,
    /**
     * Get showModifyPanel selector.
     */
    getShowModifyUsersPanel: (state: IElixirState) => teamSettingsSelector(state).showModifyUsersPanel,
    /**
     * Get showModifyPanel selector.
     */
    getShowModifyNotificationIntervalsPanel: (state: IElixirState) => teamSettingsSelector(state).showModifyNotificationIntervalsPanel,
    /**
     * Toggle the schedule panel
     */
    getSchedulePanel: (state: IElixirState) => teamSettingsSelector(state).showSchedulePanel,
    /**
     * Toggle the review IcM schedule panel
     */
    getShowReviewIcmSchedulePanel: (state: IElixirState) => teamSettingsSelector(state).showReviewIcmSchedulePanel,
    /**
     * Gets the panel message
     */
    getPanelMessage: (state: IElixirState) => teamSettingsSelector(state).panelMessage,
    /**
     * Gets timezones in Ava
     */
    getTimezones: (state: IElixirState) => teamSettingsSelector(state).timezones,
    /**
     * Gets the active team's general settings
     */
    getGeneralSettings: (state: IElixirState) => teamSettingsSelector(state).generalSettings,
    /**
     * Toggle the general settings panel
     */
    getGeneralSettingsPanel: (state: IElixirState) => teamSettingsSelector(state).showGeneralPanel,
    /**
     * Gets the current team's auto archive settings
     */
    getAutoArchiveDetails: (state: IElixirState) => teamSettingsSelector(state).autoArchiveDetails,
    /**
     * Gets the current team's notification delay settings
     */
    getNotificationDelayDetails: (state: IElixirState) => teamSettingsSelector(state).notificationDelayDetails,
    getNotificationIntervals: (state: IElixirState) => teamSettingsSelector(state).notificationIntervals,
    getTeamSettingsCaseNotificationTimes: (state: IElixirState) => teamSettingsSelector(state).teamSettingsCaseNotificationTimes,

    getScheduleNotificationChannels: (state: IElixirState) => teamSettingsSelector(state).scheduleNotificationChannels,

    canSaveSchedule: (state: IElixirState) => checkIfValidSchedule(teamSettingsSelector(state).schedulesToSave),

    canSaveGeneralSettings: (state: IElixirState) => teamSettingsSelector(state).autoArchiveDetails,
    
    /**
     * Returns a mapping of role name to list of permissions
     */
    getSelectedPermissions: (state: IElixirState) => teamSettingsSelector(state).selectedPermissions,
    /**
     * Gets the schedules that are going to be saved.
     */
    getSchedulesToSave: (state: IElixirState) => teamSettingsSelector(state).schedulesToSave,
    /**
     * Gets the schedules that are slated to be removed after being unselected
     */
    getUnselectedSchedules: (state: IElixirState) => teamSettingsSelector(state).unselectedSchedules,
    /**
     * Get the selected schedule type
     */
    getSelectedScheduleType: (state: IElixirState) => teamSettingsSelector(state).selectedScheduleType,
    getSelectedIcmContacts: (state: IElixirState) => teamSettingsSelector(state).selectedIcmContacts,
    getSelectedIcmTeam: (state: IElixirState) => teamSettingsSelector(state).selectedIcmTeam,
    getSelectedIcmOnCallRole: (state: IElixirState) => teamSettingsSelector(state).selectedIcmOnCallRole,
    getSelectedIcmOnCallSchedule: (state: IElixirState) => teamSettingsSelector(state).selectedIcmOnCallSchedule,
    getSelectedIcmOnCallShifts: (state: IElixirState) => teamSettingsSelector(state).selectedIcmOnCallShifts,
    getUsersToSave: (state: IElixirState) => teamSettingsSelector(state).usersToSave,
};
