import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { ImageIcon } from "@fluentui/react/lib/Icon";
import logo from "./logo.svg";

import { modules } from "./modules";
import * as serviceWorker from "./serviceWorker";
import {
  setConfig,
  ElxShell,
  createScope,
  getFrameworkInstance,
  uxSetPanelOpen,
} from "@elixir/fx";
import { getCurrentConfig } from "./Config";
import { UserSettingsPanel } from "./Modules/TopNavBar/Containers/UserSettings";
import { ContactSupportDialog } from "./Modules/TopNavBar/Containers/ContactSupport";
import { IPanelProps } from "./Modules/TopNavBar/Models/topnav.data";
import { showContactSupportDialog } from "./Modules/Core/Store/Slice";
import { NoTeamsFoundDialog } from "./Modules/Core/Containers/NoTeamsFoundDialog/NoTeamsFoundDialog";

import "./index.scss";

setConfig(getCurrentConfig());

const headerActions = {
  // This needs proper interface, to update once exported
  actions: [
    {
      name: "Contact Support",
      iconProps: { iconName: "Mail" },
      buttonProps: {
        onClick: () => {
          getFrameworkInstance().store.dispatch(showContactSupportDialog(true));
        },
      },
    },
    {
      name: "Power BI",
      iconProps: { iconName: "PowerBILogo" },
      buttonProps: {
        key: "PowerBI",
        href: "https://aka.ms/ava-bi",
        target: "_blank",
      },
    },
    {
      name: "Information",
      iconProps: { iconName: "Unknown" },
      buttonProps: {
        menuProps: {
          items: [
            {
              key: "1",
              text: "Documentation",
              title: "Documentation",
              iconProps: { iconName: "Documentation" },
              target: "_blank",
              href: "https://aka.ms/avadocs",
            },
            {
              key: "2",
              text: "Privacy Policy",
              title: "Privacy Policy",
              iconProps: { iconName: "Shield" },
              target: "_blank",
              href: "https://go.microsoft.com/fwlink/?LinkId=518021",
            },
          ],
        },
      },
    },
    {
      name: "Settings",
      iconProps: { iconName: "Settings" },
      buttonProps: {
        key: "Settings",
        text: "User settings",
        title: "User settings",
        onClick: () => {
          getFrameworkInstance().store.dispatch(
            uxSetPanelOpen(true, settingsPanelScope)
          );
        },
      },
    },
  ],
};

function renderAvaLogo() {
  return (
    <Link to="/" className="elx-logo" title="Ava Management Portal">
      <ImageIcon
        imageProps={{
          src: logo,
        }}
      />
    </Link>
  );
}

const settingsPanelScope = createScope("$ava-header-panel");

const panelProps: IPanelProps = {
  panelScope: settingsPanelScope,
};

ReactDOM.render(
  <ElxShell modules={modules} defaultPath="/home">
    <ElxShell.Branding
      applicationName="Ava Management Portal"
      onRenderLogo={renderAvaLogo}
    />

    <ElxShell.Actions {...headerActions}>
      <UserSettingsPanel {...panelProps} />
      <ContactSupportDialog />
      <NoTeamsFoundDialog />
    </ElxShell.Actions>
  </ElxShell>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
