import { IModule } from '@elixir/fx';
import { Home } from './AvaOverview/Containers/Home/Home';
import { PendingCases } from './AvaOverview/Containers/PendingCases/PendingCases';
import { MyCases } from './AvaOverview/Containers/MyCases/MyCases';
import reducers from './AvaOverview/Store/Slice';
import avaOverviewSaga from './AvaOverview/Store/Saga';
import avaOverview from '../Assets/Images/ava_overview.svg';

const TEAM_PATH = '/';
export const HOME_PATH = `${TEAM_PATH}home`;
export const PENDING_CASES_PATH = `${TEAM_PATH}pendingcases`;
export const MY_CASES_PATH = `${TEAM_PATH}mycases`;

export const avaOverviewModule: IModule = {
  name: 'avaOverview',
  env: 'production',
  route: {
    index: 'AvaOverview',
    name: 'Ava Overview',
    breadcrumbLabel: 'Ava Overview',
    iconProps: {
      iconName:'Ava Overview',
      imageProps: { src: avaOverview }
    },
    inSidebar: true,
    exact: true,
    isRouteable: false,
    path: TEAM_PATH,
    subRoutes: [
      {
        exact: true,
        inSidebar: true,
        index: 'Home',
        name: 'Home',
        path: HOME_PATH,
        component: Home,
        iconProps: {
          iconName: 'Home'
        },
        isRouteable: true
      },
      {
        exact: true,
        inSidebar: true,
        index: 'PendingCases',
        name: 'Pending cases',
        path: PENDING_CASES_PATH,
        component: PendingCases,
        iconProps: {
          iconName: 'TimeEntry'
        },
        isRouteable: true
      },
      {
        exact: true,
        inSidebar: true,
        index: 'MyCases',
        name: 'My cases',
        path: MY_CASES_PATH,
        component: MyCases,
        iconProps: {
          iconName: 'PageListFilter'
        },
        isRouteable: true
      },
    ]
  },
};

/**
 * Ava Overview store module.
 */
export const avaOverviewStore: IModule = {
  name: 'avaoverview',
  env: 'production',
  saga: avaOverviewSaga,
  reducer: reducers
};
