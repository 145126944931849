import React from 'react';
import { ElxTable } from '@elixir/components';
import { IColumn, Icon, SelectionMode } from '@fluentui/react';
import { RoleDetails, PermissionDetails } from '../../../../Core/core.data';
import { IGeneralTileProps } from '../General';
import { SettingsTileHeader } from '../SettingsTileHeader/SettingsTileHeader';

import './TeamPermissionsTile.scss'

interface ITeamPermissionsTileProps extends IGeneralTileProps {
    roles: RoleDetails[],
    permissions: PermissionDetails[]
}

export const TeamPermissionsTile = (props: ITeamPermissionsTileProps): JSX.Element => {
    const [teamRoles, setRoles] = React.useState<RoleDetails[]>(props.roles);
    const [teamPermissions, setPermissions] = React.useState<PermissionDetails[]>(props.permissions);

    const { roles, permissions } = props

    const columns: IColumn[] = getPermissionColumns(teamRoles);
    const items = buildPermissionsTable(teamPermissions);

    const title = 'Permissions';
    const description = 'Roles with permission to perform the following actions';

    React.useEffect(() => {
        setRoles(roles);
        setPermissions(permissions);
    }, [roles, permissions]);

    return(
        <div className="setting-tile permissions-tile">
            <SettingsTileHeader title={title} description={description} onEdit={props.onEdit} tab={2}/>
            <ElxTable 
                columns={columns} 
                items={items} 
                selectionMode={SelectionMode.none}
                ariaLabelForGrid={title}
            />
        </div>
    );
}

function getPermissionColumns(roles: RoleDetails[]): IColumn[] {
    let columns: IColumn[] = [
        {
            key: 'column_name',
            name: 'Permission',
            fieldName: 'name',
            minWidth: 200,
            maxWidth: 250,
            isResizable: false,
            isRowHeader:true,
        },
    ];

    const roleColumns: IColumn[] = roles.map((role) => ({
        key: `column_${role.name}`,
        name: role.name,
        fieldName: `${role.name}`,
        minWidth: 90,
        maxWidth: 90,
        isResizable: false,
    }));

    columns = columns.concat(roleColumns);

    return columns;
}

function buildPermissionsTable(permissionsDetails: PermissionDetails[]): any[] {
    const permissions = new Set(permissionsDetails.map((item) => item.description));

    const dictionary: any = {};

    permissionsDetails.forEach((details: PermissionDetails) => {
        const permName = `${details.description}`;
        if(!(permName in dictionary)) {
            dictionary[permName] = new Array<PermissionDetails>();
        }

        dictionary[permName].push(details);
    });

    const items: any[] = Array.from(permissions).map((permission: string) => {
        const entry: any = {};

        entry['name'] = permission;
        dictionary[permission].forEach((detail: PermissionDetails) => {
            entry[`${detail.roleName}`] = detail.isEnabled 
                ? <Icon iconName="CheckMark" ariaLabel="Checked"/> 
                : <Icon iconName="StatusCircleBlock2" ariaLabel="Unchecked"/>;
        });

        return entry;
    })
    return items;
}