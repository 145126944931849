import { IModule } from '@elixir/fx';
import { General } from './TeamSettings/Containers/General/General';
import { Schedules } from './TeamSettings/Containers/Schedules/Schedules';
import { Users } from './TeamSettings/Containers/Users/Users';
import { Roles } from './TeamSettings/Containers/Roles';
import reducers from './TeamSettings/Store/Slice';
import teamSettingsSaga from './TeamSettings/Store/Saga';
import teamSettings from '../Assets/Images/team_settings.svg';

const SETTINGS_PATH = '/team';
export const GENERAL_QUERY_PATH = `${SETTINGS_PATH}/general`;
export const SCHEDULES_QUERY_PATH = `${SETTINGS_PATH}/schedules`;
export const USERS_QUERY_PATH = `${SETTINGS_PATH}/users`;
export const ROLES_QUERY_PATH = `${SETTINGS_PATH}/roles`;

export const PANEL_INPUT_WIDTH = 370;

/**
 * Team settings module.
 */
export const teamSettingsModule: IModule = {
  name: 'teamSettings',
  env: 'production',
  route: {
    index: 'TeamSettings',
    name: 'Team Settings',
    breadcrumbLabel: 'Team Settings',
    path: SETTINGS_PATH,
    iconProps: {
      iconName:'Team Settings',
      imageProps: { src: teamSettings }
    },
    inSidebar: true,
    exact: true,
    isRouteable: false,
    subRoutes: [
      {
        exact: true,
        inSidebar: true,
        index: 'General',
        name: 'General',
        path: GENERAL_QUERY_PATH,
        component: General,
        iconProps: {
          iconName: 'Info'
        },
        isRouteable: true
      },
      {
        exact: true,
        inSidebar: true,
        index: 'Schedules',
        name: 'Schedules',
        path: SCHEDULES_QUERY_PATH,
        component: Schedules,
        iconProps: {
          iconName: 'DateTime'
        },
        isRouteable: true
      },
      {
        exact: true,
        inSidebar: true,
        index: 'Users',
        name: 'Users',
        path: USERS_QUERY_PATH,
        component: Users,
        iconProps: {
          iconName: 'People'
        },
        isRouteable: true
      },
      {
        exact: true,
        inSidebar: true,
        index: 'Roles',
        name: 'Roles',
        path: ROLES_QUERY_PATH,
        component: Roles,
        iconProps: {
          iconName: 'Badge'
        },
        isRouteable: true
      }
    ]
  }
};

/**
 * Team settings store module.
 */
export const teamSettingsStore: IModule = {
  name: 'teamsettings',
  env: 'production',
  saga: teamSettingsSaga,
  reducer: reducers
};
