import React from 'react';
import { IGeneralTileProps } from '../General';
import { ChannelDetails } from '../../../../Core/core.data';
import { SettingsTileHeader } from '../SettingsTileHeader/SettingsTileHeader';
import '../General.scss';

interface IScheduleNotificationsTileProps extends IGeneralTileProps {
    notifyChannels: ChannelDetails[]
}

export const ScheduleNotificationsTile = (props: IScheduleNotificationsTileProps): JSX.Element => {
    let statusMessage = '';
    const length = props.notifyChannels.length;

    switch (length) {
        case 0:
            statusMessage = 'No channels are configured to notify you when there are no experts scheduled.';
            break;
        case 1:
            statusMessage = `You will receive notifications when there are no experts scheduled for ${props.notifyChannels[0].name} channel.`;
            break;
        default:
            statusMessage = `You will receive notifications when there are no experts scheduled for ${props.notifyChannels[0].name} +${length-1} channels.`;
            break;
    }    

    const title = 'Schedule notifications';

    return (
        <div className="setting-tile simple-tile">
            <div className="simple-tile-header">
                <SettingsTileHeader title={title} tab={3} onEdit={props.onEdit} />
            </div>
            <div className="ms-Grid ms-sm9 ms-lg9 simple-tile-description" dir="ltr">
                <div className="ms-Grid-col simple-tile-text">
                    {statusMessage}
                </div>
            </div>
        </div>
    );
}