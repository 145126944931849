import React from "react";
import { ElxDropdown, ElxTextField } from "@elixir/components";
import { useDispatch, useSelector } from "react-redux";
import { IDropdownOption } from "@fluentui/react";

import {
  IGeneralSettingsPanelProps,
  AutoArchiveDetails,
} from "../../../../Models/teamsettings.data";
import { teamSettingsSelectors } from "../../../../Store/Selectors";
import { actions } from "../../../../Store/Slice";
import "../GeneralSettingsPanel.scss";

export const AutoArchiveTab = (
  props: IGeneralSettingsPanelProps
): JSX.Element => {
  const dispatch = useDispatch();
  const autoArchiveDetails = useSelector(
    teamSettingsSelectors.getAutoArchiveDetails
  );
  const currentAutoArchiveDetails = autoArchiveDetails || {
    isEnabled: props.settings?.autoArchive || true,
    hours: props.settings?.autoArchiveHours || 96,
  };
  const minHours = 60;
  const maxHours = 336;

  React.useEffect(() => {
    if (autoArchiveDetails) {
      dispatch(actions.setAutoArchiveDetails(autoArchiveDetails));
    }
  }, [dispatch, autoArchiveDetails]);

  const autoArchiveOptions: IDropdownOption[] = [
    {
      key: "auto_archive_yes",
      data: "true",
      title: "Yes",
      text: "Yes",
      selected: currentAutoArchiveDetails.isEnabled,
    },
    {
      key: "auto_archive_no",
      data: "false",
      title: "No",
      text: "No",
      selected: currentAutoArchiveDetails.isEnabled === false,
    },
  ];

  const onSelectAutoArchive = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ) => {
    const enabled = option?.data === "true";
    let archiveDetails = {
      isEnabled: enabled,
      hours: currentAutoArchiveDetails.hours,
    } as AutoArchiveDetails;
    dispatch(actions.setAutoArchiveDetails(archiveDetails));
  };

  const onHoursChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (newValue !== "") {
      const numericValue = Number(newValue);
      let archiveDetails = {
        ...currentAutoArchiveDetails,
        hours: numericValue >= 60 && numericValue < 336 ? numericValue : 60,
      } as AutoArchiveDetails;
      dispatch(actions.setAutoArchiveDetails(archiveDetails));
    }
  };

  return (
    <form className="general-settings-tile">
      <ElxDropdown
        label="Do you want to auto archive requests?"
        options={autoArchiveOptions}
        emptyOption={false}
        disabled={false}
        onChange={onSelectAutoArchive}
      />
      <ElxTextField
        label="Auto archive (in hours)"
        disabled={!currentAutoArchiveDetails.isEnabled}
        required={currentAutoArchiveDetails.isEnabled}
        validateOnLoad={false}
        defaultValue={String(currentAutoArchiveDetails.hours)}
        type="number"
        min={minHours}
        max={maxHours}
        onChange={onHoursChange}
      />
    </form>
  );
};
