import { ITeamSettingsState } from "./State";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  RoleDetailsCollection,
  UserDetailsCollection,
  UserScheduleDetails,
  GeneralSettings,
  AutoArchiveDetails,
  NotificationIntervals,
  ChannelNotificationDetails,
  Timezone,
  NotificationDelayDetails,
  ScheduleDetailsCollection,
  IcmContact,
  IcmTeam,
  IcmOnCallPosition,
  IcmSchedule,
  IcmOnCallShift,
  TeamSettingsCaseNotificationTimes,
} from "../Models/teamsettings.data";
import { IMessage } from "@elixir/components";
import { Draft } from "immer";

/**
 * Initial state for the teamsettings core state.
 */
const initialState: ITeamSettingsState = {
  roleDetailsCollection: { roleDetails: [] },
  userDetailsCollection: { userDetails: [] },
  scheduleDetailsCollection: { schedules: [] },
  showModifyRolePanel: false,
  showModifyUsersPanel: false,
  showModifyNotificationIntervalsPanel: false,
  showSchedulePanel: false,
  showReviewIcmSchedulePanel: false,
  showGeneralPanel: false,
  unselectedSchedules: [],
};

const teamSettingsSlice = createSlice({
  name: "$team_settings",
  initialState,
  reducers: {
    setTeamRoles(state, action: PayloadAction<RoleDetailsCollection>): void {
      state.roleDetailsCollection = action.payload;
    },
    setTeamUsers(state, action: PayloadAction<UserDetailsCollection>): void {
      state.userDetailsCollection = action.payload;
    },
    showModifyRolePanel(state, action: PayloadAction<boolean>): void {
      state.showModifyRolePanel = action.payload;
    },
    setUserSchedules(state, action: PayloadAction<UserScheduleDetails>): void {
      state.scheduleDetailsCollection = action.payload;
    },
    showModifyUsersPanel(state, action: PayloadAction<boolean>): void {
      state.showModifyUsersPanel = action.payload;
    },
    showSchedulesPanel(state, action: PayloadAction<boolean>): void {
      state.showSchedulePanel = action.payload;
    },
    showReviewIcmSchedulePanel(state, action: PayloadAction<boolean>): void {
      state.showReviewIcmSchedulePanel = action.payload;
    },
    setPanelMessage(state, action: PayloadAction<IMessage | undefined>): void {
      state.panelMessage = action.payload as Draft<IMessage> | undefined;
    },
    clearPanelMessage(state): void {
      state.panelMessage = undefined;
    },
    clearSchedulePanel(state): void {
      state.showSchedulePanel = false;
      state.schedulesToSave = [];
      state.unselectedSchedules = [];
      state.selectedIcmOnCallShifts = undefined;
    },
    setTeamSettings(state, action: PayloadAction<GeneralSettings>): void {
      state.generalSettings = action.payload;

      let autoArchiveDetails: AutoArchiveDetails = {
        isEnabled: action.payload.autoArchive,
        hours: action.payload.autoArchiveHours,
      };
      state.autoArchiveDetails = autoArchiveDetails;

      let notificationDelayDetails: NotificationDelayDetails = {
        isEnabled: action.payload.notificationDelay,
        minutes: action.payload.notificationDelayMinutes,
      };
      state.notificationDelayDetails = notificationDelayDetails;

      state.selectedPermissions = action.payload.permissions;
    },
    clearGeneralPanel(state): void {
      state.notificationIntervals = undefined;
      state.autoArchiveDetails = undefined;
      state.notificationDelayDetails = undefined;
      state.selectedPermissions = undefined;
      state.panelMessage = undefined;
      state.scheduleNotificationChannels = undefined;
      state.teamSettingsCaseNotificationTimes = undefined;
    },
    showGeneralPanel(state, action: PayloadAction<boolean>): void {
      state.showGeneralPanel = action.payload;
    },
    setAutoArchiveDetails(
      state,
      action: PayloadAction<AutoArchiveDetails>
    ): void {
      state.autoArchiveDetails = action.payload;
    },
    setNotificationDelayDetails(
      state,
      action: PayloadAction<NotificationDelayDetails>
    ): void {
      state.notificationDelayDetails = action.payload;
    },
    setNotificationIntervals(
      state,
      action: PayloadAction<NotificationIntervals>
    ): void {
      state.notificationIntervals = action.payload;
    },
    setTeamSettingsCaseNotificationTimes(
      state,
      action: PayloadAction<TeamSettingsCaseNotificationTimes>
    ): void {
      state.teamSettingsCaseNotificationTimes = action.payload;
    },
    setScheduleNotificationChannels(
      state,
      action: PayloadAction<ChannelNotificationDetails[]>
    ): void {
      state.scheduleNotificationChannels = action.payload;
    },
    setSelectedPermissions(state, action: PayloadAction<any>): void {
      state.selectedPermissions = action.payload;
    },
    setTimeZones(state, action: PayloadAction<Timezone[]>): void {
      state.timezones = action.payload;
    },
    setSchedulesToSave(
      state,
      action: PayloadAction<ScheduleDetailsCollection[]>
    ): void {
      state.schedulesToSave = action.payload;
    },
    setUnselectedSchedules(
      state,
      action: PayloadAction<ScheduleDetailsCollection[]>
    ): void {
      state.unselectedSchedules = action.payload;
    },
    setSelectedScheduleType(state, action: PayloadAction<string>): void {
      state.selectedScheduleType = action.payload;
    },
    setSelectedIcmContacts(state, action: PayloadAction<IcmContact[]>): void {
      state.selectedIcmContacts = action.payload;
    },
    setSelectedIcmTeam(state, action: PayloadAction<IcmTeam>): void {
      state.selectedIcmTeam = action.payload;
    },
    setSelectedIcmOnCallRole(
      state,
      action: PayloadAction<IcmOnCallPosition>
    ): void {
      state.selectedIcmOnCallRole = action.payload;
    },
    setSelectedIcmOnCallSchedule(
      state,
      action: PayloadAction<IcmSchedule>
    ): void {
      state.selectedIcmOnCallSchedule = action.payload;
    },
    setSelectedIcmOnCallShifts(
      state,
      action: PayloadAction<IcmOnCallShift[]>
    ): void {
      state.selectedIcmOnCallShifts = action.payload;
    },
    setUsersToSave(state, action: PayloadAction<UserDetailsCollection>): void {
      state.usersToSave = action.payload;
    },
    clearUsersPanel(state): void {
      state.panelMessage = undefined;
      state.usersToSave = undefined;
      state.showModifyUsersPanel = false;
    },
  },
});

export const { actions } = teamSettingsSlice;

export default teamSettingsSlice.reducer;
