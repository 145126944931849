import React from 'react';
import { CommandButton } from '@fluentui/react';
import { HomeTileHeader } from '../HomeTileHeader/HomeTileHeader';
import './NewFeaturesTile.scss'
import { ElxTooltip } from '@elixir/components';

const MORE_INFO_DESTINATION = 'https://azsupportdocs.azurewebsites.net/ava/articles/GettingStarted.html';

export const NewFeaturesTile = (): JSX.Element => {
    const title = 'New features';
    const textContent = 'Welcome to the all new Ava experience!';
    const tooltipText = 'Learn more';

    return (
        <div>
            <div className="home-tile new-features-tile">
                <HomeTileHeader title={title}></HomeTileHeader>
                <div className="new-features-text">{textContent}</div>
                <div className="tile-action">
                    <ElxTooltip content={tooltipText}>
                        <CommandButton iconProps={{ iconName: 'Add' }} text={'Learn more about Ava features'}  href={MORE_INFO_DESTINATION}/>
                    </ElxTooltip>
                </div>
            </div>
        </div>
    );
}