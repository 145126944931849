import { ElxDialog } from '@elixir/components';
import { useHistory } from "react-router-dom";

import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { coreSelectors } from '../../../Core/Store/Selectors';
import { showNoTeamDialog } from '../../../Core/Store/Slice';

export const NoTeamsFoundDialog = (): JSX.Element => {
    const history = useHistory();
    const showDialog = useSelector(coreSelectors.getShowNoTeamAvailableDialog);
    const dispatch = useDispatch();

    const getStarted = () => {
        window.location.href = 'https://ace-docs.azurewebsites.net/docs/articles/ava/Onboarding.html';
    };

    const goHome = () => {
        history.push('/home');
        dispatch(showNoTeamDialog(false));
    };

    return (
        <ElxDialog
            hidden={!showDialog}
            dismissable={true}
            dialogContentProps={{
                title: "No Ava Teams Found"
            }}
            primaryButtonProps={{
                text: "Get started",
                onClick: getStarted
            }}
            cancelButtonProps={{
                text: "Go home"
            }}
            onDismiss={goHome}>
        <div>To use this page, please install Ava on one of your teams or join a team where Ava is already installed.</div>
      
      </ElxDialog>
    );
}