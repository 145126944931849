import { IModule } from '@elixir/fx';
import { Dashboard } from './TeamOverview/Containers/Dashboard/Dashboard';
import { TeamCases } from './TeamOverview/Containers/TeamCases/TeamCases';
import reducers from './TeamOverview/Store/Slice';
import teamOverview from '../Assets/Images/team_overview.svg';

const TEAM_PATH = '/overview';
export const DASHBOARD_PATH = `${TEAM_PATH}/dashboard`;
export const TEAM_CASES_PATH = `${TEAM_PATH}/teamcases`;

export const teamOverviewModule: IModule = {
  name: 'teamOverview',
  env: 'production',
  route: {
    index: 'TeamOverview',
    name: 'Team Overview',
    breadcrumbLabel: 'Team Overview',
    iconProps: {
      iconName:'Team Overview',
      imageProps: { src: teamOverview }
    },
    inSidebar: true,
    exact: true,
    isRouteable: false,
    path: TEAM_PATH,
    subRoutes: [
      {
        exact: true,
        inSidebar: true,
        index: 'Dashboard',
        name: 'Dashboard',
        path: DASHBOARD_PATH,
        component: Dashboard,
        iconProps: {
          iconName: 'ViewDashboard'
        },
        isRouteable: true
      },
      {
        exact: true,
        inSidebar: true,
        index: 'TeamCases',
        name: 'Team Cases',
        path: TEAM_CASES_PATH,
        component: TeamCases,
        iconProps: {
          iconName: 'PageListFilter'
        },
        isRouteable: true
      },
    ]
  },
};

export const teamOverviewStore: IModule = {
  name: 'dashboard',
  env: 'production',
  reducer: reducers
};