import { 
  IAddTeamRolesAction,
  IDeleteTeamRolesAction,
  TeamSettingsActionTypes, 
  IGetTeamRolesAction,
  IGetTeamUsersAction,
  IEditTeamUsersAction,
  IEditTeamRolesAction,
  IGetUserSchedules,
  ISaveSchedule,
  IDeleteSchedules,
  IGetTimeZones,
  IUpdateGeneralSettingsAction,
  IGetGeneralSettingsAction,
  IGetIcmContacts,
  IGetIcmOnCallSchedule,
} from "./Types";
import { SagaIterator } from '@redux-saga/core';
import { call, put, all, takeLatest, select } from '@redux-saga/core/effects';
import {
  Http,
  uxAddContainerMessageAction,
  uxSendNotificationAction,
  ElxScopedPanel,
} from "@elixir/fx";
import { IMessage, INotification } from '@elixir/components';
import { getLogger } from '@elixir/telemetry';
import { AxiosResponse } from "axios";
import { AvaResponse, UserDetails } from "../../Core/core.data";
import Config from '../../../Config';
import { 
    RoleDetailsCollection, 
    UserDetailsCollection, 
    Timezone,
    GeneralSettings,
    IcmContact,
    IcmContactOnCallSlot,
    UserScheduleDetails,
} from "../Models/teamsettings.data";
import { setPageLoading, initializeContainer } from '../../Core/Store/Slice';
import { 
    actions
} from '../Store/Slice'
import { MessageBarType } from '@fluentui/react';
import moment from "moment";
import { coreSelectors } from "../../Core/Store/Selectors";
import { scopedAuthentication, scopedAuthenticationValue } from '../../../Common/Saga.utility';

const AVA_TEAM_API: string = Config.apiUrl + '/api/v1/teams';
const AVA_ICM_API: string = Config.apiUrl + '/api/v1/icm';
const AVA_TIMEZONE_API: string = Config.apiUrl + '/api/v1/timezones';

/**
 * Get all the team roles for the given team.
 * @param action Get team roles action payload
 */
function* getTeamRoles(action: IGetTeamRolesAction): SagaIterator {
    // Set loading state of page to true
    yield put(initializeContainer({isLoading: true, displayError: false}));

    const currentUser: UserDetails | undefined = yield select(coreSelectors.getCurrentUser);

    yield scopedAuthentication(currentUser, action.payload.scope);

    try {
      if (scopedAuthenticationValue(currentUser, action.payload.scope)){
        const teamRolesResult: AxiosResponse<AvaResponse<RoleDetailsCollection>> = 
            yield call(Http.get, action, `${AVA_TEAM_API}/${action.payload.teamsTeamId}/roles/`);
        yield put(actions.setTeamRoles(teamRolesResult.data.data));
      }
    }
    catch (err: any) {
      yield put(uxAddContainerMessageAction({
          type: MessageBarType.error,
          message: 'An error occurred while fetching roles, please try again later.',
      }, action.payload.scope));
    
      getLogger().error(err);
    }
    finally{
        // Set the current loading state to false
        yield put(setPageLoading(false));
    }
}

/**
 * Get team users for the given team.
 * @param action Get team users action payload.
 */
function* getTeamUsers(action: IGetTeamUsersAction): SagaIterator {
    // Set loading state of page to true
    yield put(initializeContainer({isLoading: true, displayError: false}));

    const currentUser: UserDetails | undefined = yield select(coreSelectors.getCurrentUser);

    yield scopedAuthentication(currentUser, action.payload.scope);

    try {
      if (scopedAuthenticationValue(currentUser, action.payload.scope)){
        const teamUsersResult: AxiosResponse<AvaResponse<UserDetailsCollection>> = 
            yield call(Http.get, action, `${AVA_TEAM_API}/${action.payload.teamsTeamId}/users/`);
        yield put(actions.setTeamUsers(teamUsersResult.data.data));
      }
    }
    catch (err: any) {
      yield put(uxAddContainerMessageAction({
            type: MessageBarType.error,
            message: 'An error occurred while fetching users, please try again later.',
        }, action.payload.scope));
      
        getLogger().error(err);
    }
    finally{
        // Set the current loading state to false
        yield put(setPageLoading(false));
    }
}

/**
 * Edit team user.
 * @param action Edit team user action payload.
 */
function* editTeamUsers(action: IEditTeamUsersAction): SagaIterator {
    // Set loading state of page to true
    yield put(setPageLoading(true));

    try {
        yield call(Http.put, action, `${AVA_TEAM_API}/${action.payload.teamsTeamId}/users/`, 
            action.payload.userDetails);
        const teamUserResult: AxiosResponse<AvaResponse<UserDetailsCollection>> = 
            yield call(Http.get, action, `${AVA_TEAM_API}/${action.payload.teamsTeamId}/users/`);
        yield put(actions.setTeamUsers(teamUserResult.data.data));

        yield put(actions.clearUsersPanel());

        const toastNotification: INotification = {
            message: 'Users successfully edited.',
          };
      
        yield put(uxSendNotificationAction(toastNotification));
    }
    catch (err: any) {
        const panelMessage: IMessage = {
          message: err.response?.data?.Message
          || 'An error occurred while editing the user, please try again later.',
          type: MessageBarType.error
        };
        yield put(actions.setPanelMessage(panelMessage))
    
      getLogger().error(err);
    }
    finally{
        // Set the current loading state to false
        yield put(setPageLoading(false));
    }
}

/**
 * Add new team role(s).
 * @param action Add team role action payload.
 */
function* addTeamRoles(action: IAddTeamRolesAction): SagaIterator {
    // Set loading state of page to true
    yield put(setPageLoading(true));

    try {
        yield call(Http.post, action, `${AVA_TEAM_API}/${action.payload.teamsTeamId}/roles/`, 
            action.payload.roleDetailsCollection);
        const teamRolesResult: AxiosResponse<AvaResponse<RoleDetailsCollection>> = 
            yield call(Http.get, action, `${AVA_TEAM_API}/${action.payload.teamsTeamId}/roles/`);
        yield put(actions.setTeamRoles(teamRolesResult.data.data));

        const toastNotification: INotification = {
            message: 'Roles successfully saved.',
          };
          yield put(actions.showModifyRolePanel(false));
          yield put(uxSendNotificationAction(toastNotification));
    }
    catch (err: any) {
      const panelMessage: IMessage = {
          message: err.response?.data?.Message
          || 'An error occurred while adding team roles, please try again later.',
          type: MessageBarType.error
        };
        yield put(actions.setPanelMessage(panelMessage))
    
      getLogger().error(err);
    }
    finally{
        // Set the current loading state to false
        yield put(setPageLoading(false));
    }
}

/**
 * Edit team role(s).
 * @param action Edit team role action payload.
 */
function* editTeamRoles(action: IEditTeamRolesAction): SagaIterator {
    // Set loading state of page to true
    yield put(setPageLoading(true));

    try {
        yield call(Http.put, action, `${AVA_TEAM_API}/${action.payload.teamsTeamId}/roles/`, 
            action.payload.roleDetailsCollection);
        const teamRolesResult: AxiosResponse<AvaResponse<RoleDetailsCollection>> = 
            yield call(Http.get, action, `${AVA_TEAM_API}/${action.payload.teamsTeamId}/roles/`);
        yield put(actions.setTeamRoles(teamRolesResult.data.data));

        const toastNotification: INotification = {
          message: 'Team roles successfully saved.',
        };
    
      yield put(uxSendNotificationAction(toastNotification));
      yield put(actions.showModifyRolePanel(false));
    }
    catch (err: any) {
        yield put(actions.setPanelMessage({
            type: MessageBarType.error,
            message: err.response?.data?.Message
            || 'An error occurred while editing team roles, please try again later.',
        }));
      
        getLogger().error(err);
      }
    finally{
        // Set the current loading state to false
        yield put(setPageLoading(false));
    }
}

/**
 * Delete team role(s).
 * @param action Add team role action payload.
 */
function* deleteTeamRoles(action: IDeleteTeamRolesAction): SagaIterator {
    // Set loading state of page to true
    yield put(setPageLoading(true));

    try {
        yield call(Http.delete, action, `${AVA_TEAM_API}/${action.payload.teamsTeamId}/roles/`, 
            action.payload.roleDetailsCollection);
        const teamRolesResult: AxiosResponse<AvaResponse<RoleDetailsCollection>> = 
            yield call(Http.get, action, `${AVA_TEAM_API}/${action.payload.teamsTeamId}/roles/`);
        yield put(actions.setTeamRoles(teamRolesResult.data.data));
    }
    catch (err: any) {
        yield put(uxAddContainerMessageAction({
            type: MessageBarType.error,
            message: err.response?.data?.Message
            || 'An error occurred while deleting team roles, please try again later.',
        }, action.payload.scope));
      
        getLogger().error(err);
      }
    finally{
        // Set the current loading state to false
        yield put(setPageLoading(false));
    }
}

/**
 * Gets the user schedules from the Ava API
 * @param action The action defined to get user schedules.
 */
function* getUserSchedules(action: IGetUserSchedules): SagaIterator {
    yield put(initializeContainer({isLoading: true, displayError: false}));

    try {
        const avaResponse: AxiosResponse<AvaResponse<UserScheduleDetails>> = 
        yield call(Http.get, action, `${AVA_TEAM_API}/${action.payload.teamsTeamId}/schedules/`);
        
        yield put(actions.setUserSchedules(avaResponse.data.data));
    }
    catch (err: any) {
        yield put(uxAddContainerMessageAction({
            type: MessageBarType.error,
            message: "An error occurred while fetching the user's schedule, please try again later.",
        }, action.payload.scope));

        getLogger().error(err);
    }
    finally {
        yield put(setPageLoading(false));
    }
}

/**
 * Saves edited or new user schedules
 * @param action The action defined to get user schedules.
 */
function* saveUserSchedules(action: ISaveSchedule): SagaIterator {
  yield put(setPageLoading(true));

  try {
    yield call(Http.put, action, `${AVA_TEAM_API}/${action.payload.teamsTeamId}/schedules/`,
      action.payload.schedules);

    if (action.payload.unselectedSchedules) {
      yield call(Http.delete, action, `${AVA_TEAM_API}/${action.payload.teamsTeamId}/schedules/`,
      action.payload.unselectedSchedules);
    }

    const avaResponse: AxiosResponse<AvaResponse<UserScheduleDetails>> =
      yield call(Http.get, action, `${AVA_TEAM_API}/${action.payload.teamsTeamId}/schedules/`);

    yield put(actions.setUserSchedules(avaResponse.data.data));

    const toastNotification: INotification = {
      message: 'Schedule successfully saved.',
    };

    yield put(actions.clearSchedulePanel());
    yield put(uxSendNotificationAction(toastNotification));
  }
  catch (err: any) {
    const panelMessage: IMessage = {
      message: err.response?.data?.Message
      || 'An error occurred while saving team schedules, please try again later.',
      type: MessageBarType.error
    };
    yield put(actions.setPanelMessage(panelMessage))
  }
  finally {
    yield put(setPageLoading(false));
  }
}

/**
 * Deletes the specified user schedules
 * 
 * @param action 
 */
function* deleteUserSchedules(action: IDeleteSchedules): SagaIterator {
  yield put(setPageLoading(true));

  try {
    yield call(Http.delete, action, `${AVA_TEAM_API}/${action.payload.teamsTeamId}/schedules/`,
      action.payload.schedules);

    const avaResponse: AxiosResponse<AvaResponse<UserScheduleDetails>> =
      yield call(Http.get, action, `${AVA_TEAM_API}/${action.payload.teamsTeamId}/schedules/`);

    yield put(actions.setUserSchedules(avaResponse.data.data));
  }
  catch (err: any) {
      const notification: INotification = {
        message: err.response?.data?.Message
        || 'An error occurred while deleting team schedules, please try again later.'
      };
      yield put(uxSendNotificationAction(notification))
  }
  finally {
    yield put(setPageLoading(false));
  }
}

function* getIcmContacts(action: IGetIcmContacts): SagaIterator {
  yield put(setPageLoading(true));

  try {
      const avaResponse: AxiosResponse<AvaResponse<IcmContact[]>> = 
      yield call(Http.get, action, `${AVA_ICM_API}/contacts/?aliases=${action.payload.aliases.join('&aliases=')}`);
      yield put(actions.setSelectedIcmContacts(avaResponse.data.data));
  }
  catch (err: any) {
      yield put(uxAddContainerMessageAction({
          type: MessageBarType.error,
          message: 'An error occurred while fetching IcM contacts, please try again later.',
      }, action.payload.scope));

      getLogger().error(err);
  }
  finally {
      yield put(setPageLoading(false));
  }
}

function* getIcmOnCallSchedule(action: IGetIcmOnCallSchedule): SagaIterator {
  yield put(setPageLoading(true));

  try {
    const query = `TeamIds=${action.payload.teamId}&MaxSlotsPerTeam=5&StartTime=${moment().utc().format()}&OnCallPosition=${action.payload.onCallPosition}&ScheduleType=All`;
    const avaResponse: AxiosResponse<AvaResponse<IcmContactOnCallSlot[]>> = 
    yield call(Http.get, action, `${AVA_ICM_API}/schedule/${action.payload.contactId}/?${query}`);

    const shifts = avaResponse.data.data.map(slot => (
      {
        startTime: slot.startTime,
        endTime: slot.endTime
      }
    ));
    yield put(actions.setSelectedIcmOnCallShifts(shifts));
  }
  catch (err: any) {
    yield put(uxAddContainerMessageAction({
        type: MessageBarType.error,
        message: 'An error occurred while fetching IcM teams, please try again later.',
    }, action.payload.scope));

    getLogger().error(err);
  }
  finally {
      yield put(setPageLoading(false));
  }
}

/**
 * Gets timezones available in Ava
 * 
 * @param action 
 */
function* getTimezones(action: IGetTimeZones): SagaIterator {
  try {
    const avaResponse: AxiosResponse<AvaResponse<Timezone[]>> = yield call(Http.get, action, AVA_TIMEZONE_API);

    yield put(actions.setTimeZones(avaResponse.data.data));
  }
  catch (err: any) {
    const panelMessage: IMessage = {
        message: err.response?.data?.Message
        || 'An error occurred while saving team schedules, please try again later.',
        type: MessageBarType.error
      };
    yield put(actions.setPanelMessage(panelMessage))
  }
}

/**
 * Saves edited general team settings
 * @param action The action defined to get general settings.
 */
function* updateTeamSettings(action: IUpdateGeneralSettingsAction): SagaIterator {
  yield put(setPageLoading(true));

  try {
    yield call(
      Http.post,
      action,
      `${AVA_TEAM_API}/${action.payload.teamsTeamId}/settings/`,
      action.payload.generalSettings
    );

    const avaResponse: AxiosResponse<AvaResponse<GeneralSettings>> = yield call(
      Http.get,
      action,
      `${AVA_TEAM_API}/${action.payload.teamsTeamId}/settings/`
    );

    yield put(actions.setTeamSettings(avaResponse.data.data));

    const toastNotification: INotification = {
      message: "Settings successfully saved.",
    };
    yield put(actions.clearGeneralPanel());
    yield put(actions.showGeneralPanel(false));
    yield put(uxSendNotificationAction(toastNotification));
  } 
  catch (err: any) {
    const panelMessage: IMessage = {
      message: err.response?.data?.Message
      || "An error occurred while saving team settings, please try again later.",
      type: MessageBarType.error,
    };
    
    yield put(actions.setPanelMessage(panelMessage));
  } finally {
    yield put(setPageLoading(false));
  }
}

/**
 * Gets general team settings
 * @param action The action defined to get general settings.
 */
function* getTeamSettings(action: IGetGeneralSettingsAction): SagaIterator {
  yield put(initializeContainer({isLoading: true, displayError: false}));

  const currentUser: UserDetails | undefined = yield select(coreSelectors.getCurrentUser);

  yield scopedAuthentication(currentUser, action.payload.scope);

  try {
    if(scopedAuthenticationValue(currentUser, action.payload.scope)){
      const avaResponse: AxiosResponse<AvaResponse<GeneralSettings>> =
        yield call(Http.get, action, `${AVA_TEAM_API}/${action.payload.teamsTeamId}/settings/`);

      yield put(actions.setTeamSettings(avaResponse.data.data));
    }
  }
  catch (err: any) {
    const panelMessage: IMessage = {
      message: err.response?.data?.Message
      || 'An error occurred while fetching team settings, please try again later.',
      type: MessageBarType.error
    };
    yield put(actions.setPanelMessage(panelMessage))
  }
  finally {
    yield put(setPageLoading(false));
  }
}

function* watchTeamSettingsActions(): IterableIterator<any> {
    yield all([
        yield takeLatest(TeamSettingsActionTypes.GET_TEAM_USERS, getTeamUsers),
        yield takeLatest(TeamSettingsActionTypes.EDIT_TEAM_USERS, editTeamUsers),
        yield takeLatest(TeamSettingsActionTypes.GET_TEAM_ROLES, getTeamRoles),
        yield takeLatest(TeamSettingsActionTypes.ADD_TEAM_ROLES, addTeamRoles),
        yield takeLatest(TeamSettingsActionTypes.EDIT_TEAM_ROLES, editTeamRoles),
        yield takeLatest(TeamSettingsActionTypes.DELETE_TEAM_ROLES, deleteTeamRoles),
        yield takeLatest(TeamSettingsActionTypes.GET_USER_SCHEDULES, getUserSchedules),
        yield takeLatest(TeamSettingsActionTypes.SAVE_SCHEDULE, saveUserSchedules),
        yield takeLatest(TeamSettingsActionTypes.DELETE_SCHEDULE, deleteUserSchedules),
        yield takeLatest(TeamSettingsActionTypes.GET_ICM_CONTACTS, getIcmContacts),
        yield takeLatest(TeamSettingsActionTypes.GET_ICM_ONCALL_SCHEDULE, getIcmOnCallSchedule),
        yield takeLatest(TeamSettingsActionTypes.GET_TIME_ZONES, getTimezones),
        yield takeLatest(TeamSettingsActionTypes.UPDATE_GENERAL_SETTINGS, updateTeamSettings),
        yield takeLatest(TeamSettingsActionTypes.GET_GENERAL_SETTINGS, getTeamSettings),
    ]);
}

// tslint:disable-next-line: no-any
function* teamSettingsSaga(): IterableIterator<any> {
    yield watchTeamSettingsActions();
}

export default teamSettingsSaga;
