import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ElxPanel,
  ElxDropdown,
  PanelSize,
  IContainerAction,
} from "@elixir/components";
import { IDropdownOption } from "@fluentui/react";
import { teamSettingsSelectors } from "../../../Store/Selectors";
import { editTeamUsersAction } from "../../../Store/Actions";
import { actions } from "../../../Store/Slice";
import { IUserPanelProps, OrgType } from "../../../Models/teamsettings.data";
import "./ModifyUserPanel.scss";
import { coreSelectors } from "../../../../Core/Store/Selectors";
import _ from "lodash";
import { UsersTableView } from "../../../Models/teamsettings.view";
import { RoleDetails } from "../../../../Core/core.data";

/**
 * This panel is used for editing team users from the Users page.
 * @param props Initial properties to be set in the panel.
 */
export const ModifyUserPanel = (props: IUserPanelProps): JSX.Element => {
  const dispatch = useDispatch();

  const showPanel = useSelector(teamSettingsSelectors.getShowModifyUsersPanel);
  const panelMessage = useSelector(teamSettingsSelectors.getPanelMessage);
  const isLoading = useSelector(coreSelectors.getPageLoading);
  const activeTeam = useSelector(coreSelectors.getActiveTeam);

  const titles = getPanelTitles(props.users || []);

  const teamRoles = useSelector(teamSettingsSelectors.getTeamRoles).roleDetails;

  // Component state to track the selected fields
  const [selectedOof, setSelectedOof] = React.useState<boolean | undefined>(
    props.users && props.users?.length === 1
      ? props.users[0].data.isOutOfOffice
      : undefined
  );
  const [selectedRole, setSelectedRole] = React.useState<
    RoleDetails | undefined
  >(
    props.users && props.users?.length === 1
      ? props.users[0].data.role
      : undefined
  );
  const [selectedAdmin, setSelectedAdmin] = React.useState<boolean | undefined>(
    props.users && props.users?.length === 1
      ? props.users[0].data.isAdmin
      : undefined
  );
  const [selectedOrg, setSelectedOrg] = React.useState<OrgType | undefined>(
    props.users && props.users?.length === 1
      ? props.users[0].data.userGroupId
      : undefined
  );

  const usersToSave = useSelector(teamSettingsSelectors.getUsersToSave);

  // Effect that updates users to save
  React.useEffect(() => {
    if (props.users && props.users.length > 0) {
      const updatedUsersToSave = _.cloneDeep(usersToSave) || {
        userDetails: [],
      };

      if (usersToSave) {
        updatedUsersToSave.userDetails.forEach((user) => {
          user.isAdmin =
            selectedAdmin === undefined ? user.isAdmin : selectedAdmin;
          user.isOutOfOffice =
            selectedOof === undefined ? user.isOutOfOffice : selectedOof;
          user.role = selectedRole || user.role;
          user.userGroupId =
            selectedOrg === undefined ? user.userGroupId : selectedOrg;
        });
      } else {
        props.users.forEach((userView) => {
          updatedUsersToSave.userDetails.push({
            alias: userView.data.alias,
            isAdmin: userView.data.isAdmin,
            isOutOfOffice: userView.data.isOutOfOffice,
            name: userView.data.name,
            role: userView.data.role,
            teamsUserId: userView.data.teamsUserId,
            userGroupId: userView.data.userGroupId,
            userGroupName: userView.data.userGroupName,
            userId: userView.data.userId,
          });
        });
      }

      dispatch(actions.setUsersToSave(updatedUsersToSave));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.users,
    dispatch,
    selectedAdmin,
    selectedOof,
    selectedOrg,
    selectedRole,
  ]);

  // Construct Placeholders
  const rolePlaceholder = props.users
    ? updatePlaceholder(props.users.map((u) => u.roleName))
    : "";
  const adminPlaceholder = props.users
    ? updatePlaceholder(props.users.map((u) => u.adminStatus))
    : "";
  const orgPlaceholder = props.users
    ? updatePlaceholder(props.users.map((u) => u.orgName))
    : "";
  const oofPlaceholder = props.users
    ? updatePlaceholder(props.users.map((u) => u.isOutOfOffice))
    : "";

  // Constructing options
  // Set oof options
  const oofOptions: IDropdownOption[] = [
    {
      key: "true",
      id: "oofYes",
      title: "Yes",
      text: "Yes",
      data: true,
      selected: selectedOof === true,
    },
    {
      key: "false",
      id: "oofNo",
      title: "No",
      text: "No",
      data: false,
      selected: selectedOof === false,
    },
  ];

  // Set selected role options
  const roleOptions =
    teamRoles.map((role) => {
      let option: IDropdownOption = {
        key: String(role.id),
        title: role.description,
        text: role.name,
        data: role,
        selected: selectedRole?.name === role.name,
      };

      return option;
    }) || [];

  // Set admin options
  const adminOptions: IDropdownOption[] = [
    {
      key: "true",
      id: "adminYes",
      title: "Yes",
      text: "Yes",
      data: true,
      selected: selectedAdmin === true,
    },
    {
      key: "false",
      id: "adminNo",
      title: "No",
      text: "No",
      data: false,
      selected: selectedAdmin === false,
    },
  ];

  // Create org options
  const orgTypeOptions: IDropdownOption[] = [
    {
      key: "orgTypePG",
      id: "orgTypePG",
      title: "Product Group",
      text: "PG",
      data: OrgType.PG,
      selected: selectedOrg === OrgType.PG,
    },
    {
      key: "orgTypeCSS",
      id: "orgTypeCSS",
      title: "Customer Support Services",
      text: "CSS",
      data: OrgType.CSS,
      selected: selectedOrg === OrgType.CSS,
    },
    {
      key: "orgTypeUnknown",
      id: "orgTypeUnknown",
      title: "Unknown",
      text: "Unknown",
      data: OrgType.Unknown,
      selected: selectedOrg === OrgType.Unknown,
    },
  ];

  const clearComponentState = () => {
    setSelectedAdmin(undefined);
    setSelectedOof(undefined);
    setSelectedOrg(undefined);
    setSelectedRole(undefined);
  };

  const onRoleChange = (option?: IDropdownOption) => {
    setSelectedRole(option?.data);
  };

  const onAdminChange = (option?: IDropdownOption) => {
    setSelectedAdmin(option?.data);
  };

  const onOrgTypeChange = (option?: IDropdownOption) => {
    setSelectedOrg(option?.data);
  };

  const onIsOofChange = (option?: IDropdownOption) => {
    setSelectedOof(option?.data);
  };

  const onSave = () => {
    clearComponentState();

    dispatch(
      editTeamUsersAction(
        activeTeam?.teamsTeamId,
        usersToSave,
        props.panelScope
      )
    );
  };

  const onDismiss = () => {
    clearComponentState();

    dispatch(actions.clearUsersPanel());
  };

  const roleLabel = "Role";
  const adminLabel = "Admin";
  const orgLabel = "Org";
  const oofLabel = "OOF";

  const renderPanelActions = (): IContainerAction[] => {
    return [
      {
        key: "buttonSave",
        text: "Save",
        primary: true,
        disabled: isLoading,
        onClick: onSave,
      },
      {
        key: "buttonCancel",
        text: "Cancel",
        onClick: onDismiss,
      },
    ];
  };

  return (
    <ElxPanel
      headerText={titles.headerText}
      onRenderSubHeader={() => titles.subHeaderElement}
      isOpen={showPanel}
      size={PanelSize.medium}
      actions={renderPanelActions()}
      onDismiss={onDismiss}
      message={panelMessage}
    >
      <div className="user-panel-dropdown-container padding-1">
        <ElxDropdown
          label={roleLabel}
          required={true}
          options={roleOptions.sort((r1, r2) => r1.text.localeCompare(r2.text))}
          placeholder={rolePlaceholder}
          onChange={(ev, newOption) => onRoleChange(newOption)}
        />
        <ElxDropdown
          label={adminLabel}
          required={true}
          options={adminOptions}
          placeholder={adminPlaceholder}
          onChange={(ev, newOption) => onAdminChange(newOption)}
        />
        <ElxDropdown
          label={orgLabel}
          required={true}
          options={orgTypeOptions}
          placeholder={orgPlaceholder}
          onChange={(ev, newOption) => onOrgTypeChange(newOption)}
        />
        <ElxDropdown
          label={oofLabel}
          required={true}
          options={oofOptions}
          placeholder={oofPlaceholder}
          onChange={(ev, newOption) => onIsOofChange(newOption)}
        />
      </div>
    </ElxPanel>
  );
};

/**
 * Method to get panel title based on number of users.
 * @param users User details.
 */
export function getPanelTitles(users: UsersTableView[]) {
  let result = { headerText: "", subHeaderElement: <></> };

  if (users?.length === 1) {
    result.headerText = "Edit user";
    result.subHeaderElement = (
      <p>{`Editing ${users[0].name} (${users[0].alias})`}</p>
    );
  } else if (users?.length > 1) {
    result.headerText = "Edit users";
    result.subHeaderElement = <p>{`Editing ${users?.length} users`}</p>;
  }
  return result;
}

/**
 * Update distinct options for provided values.
 * @param selectedValues
 * @param setPlaceholder
 */
function updatePlaceholder(selectedValues: string[]) {
  const distinctValues = new Set(selectedValues);

  let PlaceholderText = undefined as string | undefined;

  if (distinctValues.size === 1) {
    PlaceholderText = selectedValues[0];
  } else if (distinctValues.size > 1) {
    PlaceholderText = "Multiple values";
  }
  return PlaceholderText;
}
