import React from 'react';
import { createScope, ElxScopedContainer } from '@elixir/fx';
import { IContainerAction } from '@elixir/components';
import { useDispatch, useSelector } from 'react-redux';
import { coreSelectors } from '../../../Core/Store/Selectors';
import { Teams, Team, avaContainers } from '../../../Core/core.data';
import { WelcomeTile } from './WelcomeTile/WelcomeTile';
import { NewFeaturesTile } from './NewFeaturesTile/NewFeaturesTile';
import { fetchUserTeamsAction } from '../../../Core/Store/Actions';
import { TeamTile } from './TeamTile/TeamTile';
import './Home.scss';
import { AvaErrorBoundary } from '../../../Core/Containers/AvaErrorBoundary/AvaErrorBoundary';
import { mainTabFix } from '../../../../Common/TabIndexFix';
import { NotificationMessage } from '../../../Core/Containers/NotificationBanner/NotificationBanner'

const homeScope = createScope(avaContainers.AVA_HOME);

export const Home = (): JSX.Element => {
    const dispatch = useDispatch();

    const isPageLoading = useSelector(coreSelectors.getPageLoading);
    const teams = useSelector(coreSelectors.getUserTeams);

    // To be executed whenever the team length is 0.
    React.useEffect(() => {
        if (!teams.teams.length) {
            dispatch(fetchUserTeamsAction(homeScope, 'teams,overview,users'));
        }
    }, [dispatch, teams.teams.length]);

    // To be executed once.
    React.useEffect(() => {
        dispatch(fetchUserTeamsAction(homeScope, 'teams,overview,users'));
        mainTabFix();
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const teamTiles = teams.teams.length > 0 ? renderTeamSection(teams) : undefined;
    
    return(
        <AvaErrorBoundary>
        <ElxScopedContainer 
            headerText="Welcome to Ava" 
            actions={renderActions()}
            isLoading={isPageLoading}
            scope={homeScope}
        >
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-md8 ms-lg5 ms-xl3">
                        <WelcomeTile />
                    </div>
                    <div className="ms-Grid-col ms-md8 ms-lg5 ms-xl3">
                        <NewFeaturesTile />
                    </div>
                </div>
                {teamTiles}
            </div> 
        </ElxScopedContainer>
        </AvaErrorBoundary>
    );
}
function renderActions(): IContainerAction[] {
    return [{
      key: '1',
      text: 'View documentation',
      primary: false,
      href: `https://azsupportdocs.azurewebsites.net/ava/articles/Overview.html`,
      target: '_blank',
      rel: 'noopener noreferrer'
    },];
}

function renderTeamSection(teams: Teams): JSX.Element {
    const teamTiles: JSX.Element[] = teams.teams.map((team: Team) => {
        return(
            <div className="ms-Grid-col ms-md8 ms-lg5 ms-xl3">
                <TeamTile scope={homeScope} team={team}/>
            </div>);
    });

    return(
        <div>
            <h2>Your teams</h2>
            <div className="ms-Grid-row">
                {teamTiles}
            </div>
        </div>
    ); 
}
