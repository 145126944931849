import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { CommandButton, IContextualMenuProps } from '@fluentui/react';
import { HomeTileHeader } from '../HomeTileHeader/HomeTileHeader';
import { Team } from '../../../../Core/core.data';
import { coreSelectors } from '../../../../Core/Store/Selectors';
import { fetchTeamAction } from '../../../../Core/Store/Actions';

import './TeamTile.scss'
import { IScope } from '@elixir/fx';
import { ElxTooltip } from '@elixir/components';

interface ITeamTileProps {
    scope: IScope,
    team: Team,
}

export const TeamTile = (props: ITeamTileProps): JSX.Element => {
    const [destination, setDestination] = React.useState<string>();

    const dispatch = useDispatch();
    const history = useHistory();
    const isPageLoading = useSelector(coreSelectors.getPageLoading);
    const activeTeam = useSelector(coreSelectors.getActiveTeam);

    const title = props.team?.name;
    const usersScheduledText = `${props.team.usersScheduled} users currently scheduled`;
    const openCasesText = `${props.team.openCases} open cases`;
    const tooltipText = 'View team overview';

    React.useEffect(() => {
        if(!isPageLoading && destination && activeTeam?.teamsTeamId) {
            history.push(destination);
        }
    }, [history, destination, activeTeam, isPageLoading]);

    const onClickOverview = () => {
        setDestination('/overview/dashboard');
        dispatch(fetchTeamAction(props.team.teamsTeamId, props.scope, 'overview,canary'));
    };

    const onClickSettings = () => {
        setDestination('/team/general');
        dispatch(fetchTeamAction(props.team.teamsTeamId, props.scope, 'settings,roles'));
    };

    const menuOptions: IContextualMenuProps = {
        items:
        [ 
            {
                key: 'ViewSettings',
                text: 'View settings',
                title: 'View settings',
                onClick: onClickSettings,
                iconProps: {
                    iconName: 'View'
                }
            },
            {
                key: 'OpenTeams',
                text: 'View in Teams',
                title: 'View in Teams',
                href: `https://teams.microsoft.com/l/team/${props.team.teamsTeamId}/`,
                iconProps: {
                    iconName: 'View'
                }
            }
        ]
    }; 

    return (
        <div>
            <div className="home-tile team-tile">
                <HomeTileHeader title={title} menuProps={menuOptions}></HomeTileHeader>
                <div className="team-tile-text">
                    <div>
                        {usersScheduledText}
                    </div>
                    <div>
                        {openCasesText}
                    </div>
                </div>
                <div className="tile-action">
                    <ElxTooltip content={tooltipText}>
                        <CommandButton iconProps={{ iconName: 'View' }} text={'View team overview'}  onClick={onClickOverview}/>
                    </ElxTooltip>
                </div>
            </div>
        </div>
    );
}