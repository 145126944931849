import * as Highcharts from 'highcharts';

// Sample options to supply for line chart
export var lineOptions: Highcharts.Options = {
  colors: ['#0078D4', '#EF6950', '#00188F', '#00A2AD', '#4B003F', '#E3008C', '#022F22', '#917EDB', '#001D3F', '#502006'],
  time: {
    useUTC: true,
  },

  title: {
    text: '',
  },

  yAxis: {
    title: {
      text: '',
    },
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    type: 'datetime',
    tickInterval: 3600 * 1000
  },

  legend: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'middle',
    enabled: false,
  },

  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      pointStart: Date.UTC(
        new Date().getUTCFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        0,
        0,
        0
      ),
      pointInterval: 3600000,
    },
  },

  series: [],

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
          },
          yAxis: {
            title: {
              text: null,
            },
          },
        },
      },
    ],
  },
}
