import React from 'react';
import moment from 'moment';

const humanizeDuration = require("humanize-duration");

/**
 * Method to get time difference in 'xd yh zmins' format.
 * @param dt1 First date.
 * @param dt2 Last date.
 */
export function getTimeDifference(dt1: Date, dt2: Date): string {
    let start = moment(dt1);
    let end = moment(dt2);
    let duration = moment.duration(end.diff(start));
    
    // This will give in 'a days, b hours, c minutes, d seconds' format.
    let waitTime: string = humanizeDuration(duration.asMilliseconds());

    // RegExp for replacing to required format.
    const regex_separator = /, /g;
    const regex_weeks = / week(s\b|\b)/gi;
    const regex_days = / day(s\b|\b)/gi;
    const regex_hours = / hour(s\b|\b)/gi;
    const regex_mins = / minute(s\b|\b)/gi;

    // Remove 'seconds' portion of waitTime.
    let secondsToken = waitTime.split(regex_separator)
        .filter(x => x.includes('second'));
    if (secondsToken.length === 1) {
        waitTime = waitTime.replace(secondsToken[0], '');
    }

    waitTime = waitTime.replace(regex_separator, ' ');
    waitTime = waitTime.replace(regex_weeks, 'w');
    waitTime = waitTime.replace(regex_days, 'd');
    waitTime = waitTime.replace(regex_hours, 'h');
    waitTime = waitTime.replace(regex_mins, 'mins');
    waitTime = waitTime.trim();

    return waitTime;
}

/**
 * Get truncated time (without seconds/milliseconds)
 * @param utcTime Given time in utc format.
 */
export function getTruncatedTime(utcTime: string) {
    utcTime = utcTime.replace('T', ' ');
    let temp = utcTime.split(':');
    if (temp.length < 2) {
        return utcTime;
    }
    let result = temp[0] + ":" + temp[1];
    return result;
}

/**
 * This method is used to render empty table.
 */
export function renderEmptyView(displayText: string): JSX.Element {
    return (
        <div className="flex-center">
            <div role="status" aria-live="polite" className="secondary-text subtitle empty-view">
                {displayText}
            </div>
        </div>
    );
}