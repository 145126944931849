import React from 'react';
import { UserDetails } from '../../../Core/core.data';
import './RoleLabel.scss';

interface IRoleLabelProps {
    user?: UserDetails
}

/**
 * Label found on subactions of all primary pages. Show's user's role name and (Administrator) if user
 * is an administrator
 * @param props Current user object
 * @props user
 */
export const RoleLabel = (props: IRoleLabelProps): JSX.Element => {
    const role = props.user && props.user.role ? props.user.role.name : '';
    const administratorLabel = props.user && props.user.isAdmin ? <span> (Administrator)</span> : undefined;

    return(
        <div className="role-label">
            <span className="ms-fontWeight-semibold">Role: </span>
            <span>{role}</span>
            {administratorLabel}
        </div>
    );
}