import React from 'react';
import { ElxDropdown, SelectedKeyTypes } from '@elixir/components';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { IDropdownOption } from '@fluentui/react';
import { IGeneralSettingsPanelProps, ChannelNotificationDetails } from '../../../../Models/teamsettings.data';
import { teamSettingsSelectors } from '../../../../Store/Selectors';
import { actions } from '../../../../Store/Slice';
import { coreSelectors } from '../../../../../Core/Store/Selectors';
import '../GeneralSettingsPanel.scss';

export const ScheduleNotificationTab = (props: IGeneralSettingsPanelProps): JSX.Element => {
    const dispatch = useDispatch();
    const channels = useSelector(coreSelectors.getChannels) || [];
    const storeChannelNotificationDetails = useSelector(teamSettingsSelectors.getScheduleNotificationChannels) || [];

    let initialSelection: string[] = [];
    let allChannels: ChannelNotificationDetails[];

    if (props.settings?.scheduleNotificationDetails) {
        initialSelection = getSelectedChannelIds(props.settings.scheduleNotificationDetails);
        allChannels = props.settings.scheduleNotificationDetails;
    } else if (storeChannelNotificationDetails) {
        initialSelection = getSelectedChannelIds(storeChannelNotificationDetails);
        allChannels = storeChannelNotificationDetails;
    } else {
        allChannels = [];
    }

    const [selectedChannelIds, setSelectedChannelIds] = React.useState<SelectedKeyTypes>(initialSelection);

    React.useEffect(() => {
        dispatch(actions.setScheduleNotificationChannels(allChannels));
    }, [dispatch, allChannels]);

    const channelOptions: IDropdownOption[] = channels.map(channel => (
        {
            key: channel.teamsChannelId,
            text: channel.name,
            title: channel.name,
            data: channel
        }
    ));

    const onSelectedChannels = (selectedKeys?: SelectedKeyTypes) => {
        let channels = _.cloneDeep(allChannels);
        channels.forEach(c => {
            c.areNotificationsEnabled = (selectedKeys?.some((x: string | number) => x === c.teamsChannelId)) ?? false;
        });

        dispatch(actions.setScheduleNotificationChannels(channels));
        setSelectedChannelIds(selectedKeys);
    };

    return (
        <form className='general-settings-tile'>
            <ElxDropdown
                label="Which channels would you like to receive notifications for when there are no experts scheduled?"
                options={channelOptions}
                emptyOption={true}
                emptyOptionText='Select channels'
                multiSelect={true}
                enableSelectAll={true}
                disabled={false}
                onMultiSelectChange={onSelectedChannels}
                selectedKeys={selectedChannelIds}
            />
        </form>
    );
}

function getSelectedChannelIds(channels: ChannelNotificationDetails[]): string[] {
    const result: string[] = [];

    channels
        .filter(c => c.areNotificationsEnabled)
        .forEach(c => {
            result.push(c.teamsChannelId);
        }
    );

    return result;
}