import { IModule } from '@elixir/fx';

import { teamOverviewModule, teamOverviewStore } from './Modules/TeamOverview';
import { teamSettingsModule, teamSettingsStore } from './Modules/TeamSettings';
import { avaOverviewModule, avaOverviewStore } from './Modules/AvaOverview';
import { coreStateModule } from './Modules/Core/';

/**
 * All the modules.
 */
export const modules: IModule[] = [
  coreStateModule,
  avaOverviewModule,
  teamOverviewModule,
  teamSettingsModule,
  avaOverviewStore,
  teamOverviewStore,
  teamSettingsStore,
];
