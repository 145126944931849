/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { createScope, ElxScopedContainer } from '@elixir/fx';
import { IElxContainerProps, ElxDropdown, IContainerAction } from '@elixir/components';
import { IDropdownOption } from '@fluentui/react';
import { PageHeader } from '../../../PageHeader/Containers/PageHeader';
import { useDispatch, useSelector } from 'react-redux';
import { coreSelectors } from '../../../Core/Store/Selectors';
import { fetchTeamAction } from '../../../Core/Store/Actions';
import { dashboardSelectors } from '../../Store/Selectors';
import { QuestionsPendingSection } from '../../Components/QuestionsPendingSection/QuestionsPendingSection';
import { RoleLabel } from '../../../PageHeader/Components/RoleLabel/RoleLabel';
import { PreviewToggle } from '../../../PageHeader/Components/PreviewToggle/PreviewToggle';
import { UserDetails, avaContainers } from '../../../Core/core.data';
import { UserRolesSection } from '../../Components/UserRolesSection/UserRolesSection';
import { Overview } from '../../Models/dashboard.data';
import Highcharts, { Chart, SeriesLineOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { lineOptions } from './LineChart.data';

import './Dashboard.scss';
import { AvaErrorBoundary } from '../../../Core/Containers/AvaErrorBoundary/AvaErrorBoundary';
import { mainTabFix } from '../../../../Common/TabIndexFix';
import { NotificationMessage } from '../../../Core/Containers/NotificationBanner/NotificationBanner'

require("highcharts/modules/accessibility")(Highcharts);

// This scope is used for controlling error handling on this page
const dashboardScope = createScope(avaContainers.AVA_DASHBOARD);

/**
 * This container is the dashboard l2 on the portal
 */
export const Dashboard = (): JSX.Element => {
    const isPageLoading = useSelector(coreSelectors.getPageLoading);
    const user = useSelector(coreSelectors.getCurrentUser);
    
    const overview = useSelector(dashboardSelectors.getOverview)
    const activeTeam = useSelector(coreSelectors.getActiveTeam);

    const dispatch = useDispatch();

    const activeTeamId = activeTeam?.teamsTeamId;

    const displayError = useSelector(coreSelectors.getDisplayError);

    const [chart, setChart] = React.useState<Chart | undefined>(undefined);
    
	React.useEffect(() => {
		mainTabFix();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
	
	React.useEffect(() => {
        if (!isPageLoading && activeTeamId) {
            dispatch(fetchTeamAction(activeTeamId, dashboardScope, 'overview,canary'));
        }
    }, [activeTeamId, dispatch, dashboardScope]);

    // Chart update
    React.useEffect(() => {
        if (!displayError && chart !== undefined && overview?.coverage !== undefined) {
            var graphData = [] as SeriesLineOptions[];
            overview.coverage.forEach(cov => {
                graphData.push({
                    id: `${cov.channel.teamsChannelId};${cov.role.id}`,
                    name: `${cov.channel.name} | ${cov.role.name}`,
                    data: cov.expertCount,
                    type: 'line',
                });
            });
            updateChart(chart, graphData);
        }
    }, [chart, overview]);

    const [channelOptions, setChannelOptions] = React.useState<IDropdownOption[]>([]);
    const [selectedChannels, setSelectedChannels] = React.useState<string[]>([]);

    const [roleOptions, setRoleOptions] = React.useState<IDropdownOption[]>([]);
    const [selectedRoles, setSelectedRoles] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (overview?.coverage !== undefined) {
            setChannelOptions(overview.channels.map(channel => ({ key: channel.teamsChannelId, text: channel.name, ariaLabel: channel.name, role: 'option' })));
            setSelectedChannels(overview.channels.map(channel => channel.teamsChannelId));

            var allAvailableRoles = overview.coverage.map(coverage => ({ key: (coverage.role.id || 0).toString(), text: coverage.role.name, ariaLabel: coverage.role.name, role: 'option' }));
            var uniqueRoles = new Map<string, any>();

            allAvailableRoles.forEach(r => {
                uniqueRoles.set(r.key, r);
            });

            setRoleOptions(Array.from(uniqueRoles.values()));
            setSelectedRoles(Array.from(new Set(overview.coverage.map(coverage => (coverage.role.id || 0).toString()))));
        }
    }, [overview]);

    const actionBtnProps: IContainerAction[] = [{
        key: '1',
        text: 'View documentation',
        href: `https://azsupportdocs.azurewebsites.net/ava/articles/Overview.html`,
        target: '_blank',
        rel: 'noopener noreferrer'
    },
    {
        key: '2',
        text: 'Open in Teams',
        href: `https://teams.microsoft.com/l/team/${activeTeam?.teamsTeamId}/`,
        target: '_blank',
        rel: 'noopener noreferrer'
    }];

    return (
        <ElxScopedContainer headerText="" onRenderHeader={(props: IElxContainerProps) => (
            <PageHeader containerScope={dashboardScope} include='overview,canary' />
        )}
            subActions={renderSubActions(user)}
            actions={actionBtnProps}
            scope={dashboardScope}
        >
        <AvaErrorBoundary>
        <ElxScopedContainer
            className="hideHeader"
            isLoading={isPageLoading}
        >
            <form className="dashboard">
                <div className="ms-Grid ms-lg12 ms-sm12" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm10">
                            <QuestionsPendingSection questionsPending={overview?.questionsPending || {}} questionsPendingWithExpiredTimers={overview?.questionsPendingWithExpiredTimers || {}} />
                            <div className="ms-Grid-col ms-sm12 schedule-coverage" dir="ltr">
                                <div className="ms-Grid-row ms-sm12 dashboard-tile">
                                    <div className="ms-Grid-row ms-fontSize-13">
                                        <h2 className="header">Today's schedule coverage</h2>
                                    </div>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6" dir="ltr" aria-label="Selection 1">
                                            <ElxDropdown
                                                label="Select channels to view"
                                                placeholder="Select channels"
                                                enableSelectAll={true}
                                                disabled={false}
                                                emptyOption={true}
                                                multiSelect={true}
                                                options={channelOptions}
                                                selectedKeys={selectedChannels}
                                                onMultiSelectChange={selectedKeys => onChannelsSelect(selectedKeys as string[],
                                                    chart, overview, setSelectedChannels, selectedRoles)}
                                                ariaLabel="Select channels to view coverage details."
                                            />
                                        </div>
                                        <div className="ms-Grid-col ms-sm6" dir="ltr" aria-label="Selection 2">
                                            <ElxDropdown
                                                    label="Select roles to view"
                                                    placeholder="Select roles"
                                                    enableSelectAll={true}
                                                    disabled={false}
                                                    emptyOption={true}
                                                    multiSelect={true}
                                                    options={roleOptions}
                                                    selectedKeys={selectedRoles}
                                                    onMultiSelectChange={selectedKeys => onRolesSelect(selectedKeys as string[],
                                                        chart, overview, setSelectedRoles, selectedChannels)}
                                                    ariaLabel="Select roles for coverage details."
                                            />
                                        </div>
                                        <div className="ms-Grid-col ms-sm12" dir="ltr">
                                        </div>
                                    </div>
                                    <div className="ms-Grid-row ms-sm12 ms-xl6">
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={lineOptions}
                                            callback={setChart}
                                            tabIndex="-1"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-col ms-sm2">
                            <UserRolesSection roleMetrics={overview?.userRoles} />
                        </div>
                    </div>
                </div>
            </form>
        </ElxScopedContainer>
        </AvaErrorBoundary>
        </ElxScopedContainer>
    );
}

/**
 * This method is used to update chart options and redraw after update.
 * @param chart chart instance to update.
 * @param graphData chart series data to update.
 */
function updateChart(chart: Chart, graphData: SeriesLineOptions[]) {
    var ch = new Highcharts.Chart(chart.container, chart.options);
    ch.options.series = graphData;
    ch.update(ch.options, true, true);
}

/**
 * This method is called on selection of channels.
 * @param selectedKeys Selected channels.
 * @param chart Chart instance to update.
 * @param overview Team overview which includes schedule coverage for the team.
 * @param setSelectedChannels Set state function for updating selected channels.
 */
function onChannelsSelect(selectedKeys: string[], chart: Chart | undefined, overview: Overview | undefined, 
    setSelectedChannels: React.Dispatch<React.SetStateAction<string[]>>, selectedRoles: string[]) {
    if (chart !== undefined) {
        var graphData =  [] as SeriesLineOptions[];
        if (selectedKeys.length > 0) {
            overview?.coverage.forEach(cov => {
                if (selectedKeys.includes(cov.channel.teamsChannelId) && selectedRoles.includes(cov.role.id?.toString() ?? '0')) {
                    graphData.push({
                        id: `${cov.channel.teamsChannelId};${cov.role.id}`,
                        name: `${cov.channel.name} | ${cov.role.name}`,
                        data: cov.expertCount,
                        type: 'line',
                    });
                }
            });
        }
        updateChart(chart, graphData);
        
        if (selectedKeys.length === 0) {
            setSelectedChannels([]);
        } else {
            setSelectedChannels(selectedKeys);
        }
    }
}

function onRolesSelect(selectedKeys: string[], chart: Chart | undefined, overview: Overview | undefined,
    setSelectedRoles: React.Dispatch<React.SetStateAction<string[]>>, selectedChannels: string[]) {
    if (chart !== undefined) {
        var graphData = [] as SeriesLineOptions[];
        if (selectedKeys.length > 0) {
            overview?.coverage.forEach(cov => {
                const stringifiedRoleId = (cov.role.id || 0).toString();
                if (selectedKeys.includes(stringifiedRoleId) && selectedChannels.includes(cov.channel.teamsChannelId)) {
                    graphData.push({
                        id: `${cov.channel.name};${cov.role.name}`,
                        name: `${cov.channel.name} | ${cov.role.name}`,
                        data: cov.expertCount,
                        type: 'line'
                    });
                }
            });
        }
        updateChart(chart, graphData);
        
        if (selectedKeys.length === 0) {
            setSelectedRoles([]);
        } else {
            setSelectedRoles(selectedKeys);
        }
    }
}

/**
 * Render sub action buttons.
 * @param user Current user.
 */
function renderSubActions(user?: UserDetails): JSX.Element[] {
    const subActions: JSX.Element[] = [];    

    if (user?.isAdmin) {
        subActions.push(<PreviewToggle user={user} />);
    }

    subActions.push(<RoleLabel user={user} />);

    return subActions;
}
