import { ICoreState } from './State';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Teams, Team, UserDetails, UserSettings, ChannelDetails, CaseOfferingDetails, CaseDataDetails } from '../core.data';
import { IDisplayErrorBoundary, IInitialContainerProps } from './Types';

/**
 * The intial state of the management portal
 */
const initialState: ICoreState = {
  teams: { teams: [] },
  showContactSupport: false,
  pageLoading: true,
  showNoTeamDialog: false,
  displayError: false,
};

const coreSlice = createSlice({
  name: '$core',
  initialState,
  reducers: {
    showContactSupportDialog(state: ICoreState, action: PayloadAction<boolean>): void {
      state.showContactSupport = action.payload;
    },
    setPageLoading(state: ICoreState, action: PayloadAction<boolean>): void {
      state.pageLoading = action.payload;
    },
    setUserTeams(state: ICoreState, action: PayloadAction<Teams>): void {
      state.teams = action.payload;
    },
    setActiveTeam(state: ICoreState, action: PayloadAction<Team>): void {
      state.activeTeam = action.payload;
    },
    setTeamUser(state: ICoreState, action: PayloadAction<UserDetails>): void {
      state.currentTeamUser = action.payload;
    },
    setIsActiveTeamCanaryEnrolled(state: ICoreState, action: PayloadAction<boolean | undefined>): void {
      state.isActiveTeamCanaryEnrolled = action.payload;
    },
    setCurrentUserSettings(state: ICoreState, action: PayloadAction<UserSettings>): void {
      state.currentTeamUserSettings = action.payload;
    },
    setTeamChannels(state: ICoreState, action: PayloadAction<ChannelDetails[]>): void {
      state.channels = action.payload;
    },
    setCaseOfferings(state: ICoreState, action: PayloadAction<CaseOfferingDetails[]>): void {
      state.offerings = action.payload;
    },
    showNoTeamDialog(state: ICoreState, action: PayloadAction<boolean>): void {
      state.showNoTeamDialog = action.payload;
    },
    setTeamCases(state: ICoreState, action: PayloadAction<CaseDataDetails[]>): void {
      state.teamCases = action.payload;
    },
    setAutoRefreshEnabled(state: ICoreState, action: PayloadAction<boolean | undefined>): void {
      state.autoRefreshEnabled = action.payload;
    },
    setDisplayError(state: ICoreState, action: PayloadAction<IDisplayErrorBoundary>): void {
      state.displayError = action.payload.display;
      state.errorMessage = action.payload.message;
    },
    initializeContainer(state: ICoreState, action: PayloadAction<IInitialContainerProps>) : void {
      state.pageLoading = action.payload.isLoading;
      state.displayError = action.payload.displayError || false;
      state.errorMessage = '';
    }
  },
});

export const {
  showContactSupportDialog,
  setPageLoading,
  setUserTeams,
  setActiveTeam,
  setTeamUser,
  setIsActiveTeamCanaryEnrolled,
  setCurrentUserSettings,
  setTeamChannels,
  setCaseOfferings,
  showNoTeamDialog,
  setTeamCases,
  setAutoRefreshEnabled,
  setDisplayError,
  initializeContainer,
} = coreSlice.actions;

export default coreSlice.reducer;