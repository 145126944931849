import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { IPickerItem, ElxPicker, SelectedKeyTypes, InputMessageTypes } from '@elixir/components';
import { actions } from '../../Store/Slice';
import { teamSettingsSelectors } from '../../Store/Selectors';
import { ISchedulePanelProps } from '../../Models/teamsettings.data';
import { updateSchedulesToSaveValues } from '../../Containers/Schedules/SchedulesPanel';
import { PANEL_INPUT_WIDTH } from '../../../TeamSettings';
import '../../TeamSettings.scss';

const hours = createHours();
const minutes = createMinutes();

const daysOfWeekOptions = createDaysOfWeek();

const hourOptions: IPickerItem[] = hours.map(hour => ({
    key: `hour_options_${hour}`,
    text: hour,
    data: hour
}));

const minuteOptions: IPickerItem[] = minutes.map(minute => ({
    key: `minute_options_${minute}`,
    text: minute,
    data: minute
}));

/**
 * Implements the Weekly schedule component.
 */
export const WeeklyScheduleDetails = (props: ISchedulePanelProps): JSX.Element => {
    const title = 'Custom schedule';
    const dispatch = useDispatch();

    const schedulesToSave = useSelector(teamSettingsSelectors.getSchedulesToSave) || [];
    const timezones = useSelector(teamSettingsSelectors.getTimezones) || [];

    let startHour = '09:00';
    let daysOfWeek = 'Mon,Tue,Wed,Thu,Fri';
    let timeZone = moment.tz.guess();
    let endHour = '18:00';

    let sameDays = true;
    let sameTimezone = true;
    let sameStartTime = true;
    let sameEndTime = true;

    if (schedulesToSave && schedulesToSave.every(s => s.scheduleDetails.every(d => d.scheduleType === 'Weekly'))) {
        daysOfWeek = schedulesToSave[0]?.scheduleDetails[0]?.daysOfWeek?.replace(/\s/g, '') || daysOfWeek;
        timeZone = schedulesToSave[0]?.scheduleDetails[0]?.timeZone || timeZone;
        startHour = schedulesToSave[0]?.scheduleDetails[0]?.start || startHour;
        daysOfWeek = schedulesToSave[0]?.scheduleDetails[0]?.daysOfWeek || daysOfWeek;
        endHour = schedulesToSave[0]?.scheduleDetails[0]?.end || endHour;

        // Do all the selected rows have the same channels
        if (schedulesToSave.length > 1) {
            // All entries should match first instance of timezone
            const firstTimezone = schedulesToSave[0]?.scheduleDetails[0].timeZone;

            // Go through the rest of the times in the details
            sameTimezone = schedulesToSave.every(s => {
                return s.scheduleDetails[0].timeZone === firstTimezone;
            });

            // All entries should match first instance of days of the week
            const firstDays = schedulesToSave[0]?.scheduleDetails[0].daysOfWeek;

            // Go through the rest of the days of the week
            sameDays = schedulesToSave.every(s => {
                return s.scheduleDetails[0].daysOfWeek === firstDays;
            });

            // All entries should match first instance of start time
            const firstStartTime = schedulesToSave[0]?.scheduleDetails[0].start;

            // Go through the rest of the start times
            sameStartTime = schedulesToSave.every(s => {
                return s.scheduleDetails[0].start === firstStartTime;
            });
            
            // All entries should match first instance of end time
            const firstEndTime = schedulesToSave[0]?.scheduleDetails[0].end;

            // Go through the rest of the end times
            sameEndTime = schedulesToSave.every(s => {
                return s.scheduleDetails[0].end === firstEndTime;
            });
        }
    }

    const daysPlaceholder = sameDays ? 'Select days of the week' : 'Multiple values' ;
    const timezonePlaceholder = sameTimezone ? 'Select time zone' : 'Multiple values';
    const startTimePlaceholder = sameStartTime ? '' : 'Multiple values';
    const endTimePlaceholder = sameEndTime ? '' : 'Multiple values';

    // Parse selected time into hours and minutes
    const startHourAndMinutes = startHour.split(":");
    const startHours = startHourAndMinutes[0];
    const startMinutes = startHourAndMinutes[1];

    const endHourAndMinutes = endHour.split(":");
    const endHours = endHourAndMinutes[0];
    const endMinutes = endHourAndMinutes[1];

    const [selectedDaysKeys, setSelectedDaysKeys] = React.useState<any>(sameDays ? daysOfWeek.replace(/\s/g, '').split(',') : '');
    const [selectedTimezoneKeys, setSelectedTimezoneKeys] = React.useState<string>(sameTimezone ? timeZone : '');
    const [selectedStartHourKeys, setSelectedStartHourKeys] = React.useState<string>(sameStartTime ? `hour_options_${startHours}` : '');
    const [selectedStartMinuteKeys, setSelectedStartMinuteKeys] = React.useState<string>(sameStartTime ? `minute_options_${startMinutes}` : '');
    const [selectedEndHourKeys, setSelectedEndHourKeys] = React.useState<string>(sameEndTime ? `hour_options_${endHours}` : '');
    const [selectedEndMinutesKeys, setSelectedEndMinutesKeys] = React.useState<string>(sameEndTime ? `minute_options_${endMinutes}` : '');

    const startTimeBuilder = moment(startHour, 'HH:mm');
    const endTimeBuilder = moment(endHour, 'HH:mm');

    React.useEffect(() => {
        if (sameStartTime) {
            setSelectedStartHourKeys(`hour_options_${startHours}`);
            setSelectedStartMinuteKeys(`minute_options_${startMinutes}`);
        }

        if (sameEndTime) {
            setSelectedEndHourKeys(`hour_options_${endHours}`);
            setSelectedEndMinutesKeys(`minute_options_${endMinutes}`);
        }
    }, [sameStartTime, sameEndTime, startMinutes, endMinutes, endHours, startHours]);

    React.useEffect(() => {
        if (schedulesToSave && schedulesToSave.length > 0) {
            const updatedSchedules = _.cloneDeep(schedulesToSave);
    
            updatedSchedules.forEach(s => {
                s.scheduleDetails.forEach(details => {
                    details.timeZone = details.timeZone || timeZone;
                    details.daysOfWeek = details.daysOfWeek?.replace(/\s/g, '') || daysOfWeek;
                    details.start = details.start || startHour;
                    details.end = details.end || endHour;
                    details.icmSchedule = undefined;
                });
            });
    
            dispatch(actions.setSchedulesToSave(updatedSchedules));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Add the selected fields to any empty 
    const onSelectDaysOfWeek = (selectedKeys?: SelectedKeyTypes) => {
        const selected = selectedKeys?.join(',') || '';

        const updatedSchedules = updateSchedulesToSaveValues(schedulesToSave, 'daysOfWeek', selected)

        dispatch(actions.setSchedulesToSave(updatedSchedules));
        setSelectedDaysKeys(selectedKeys);
    };

    const timeZoneOptions: any = timezones.map(timezone => ({
        key: timezone.ianaName,
        text: timezone.label,
        data: timezone.ianaName
    }));

    const onSelectedTimezone = (value?: IPickerItem) => {
        var selected: string = value?.data;

        const updatedSchedules = updateSchedulesToSaveValues(schedulesToSave, 'timeZone', selected)

        dispatch(actions.setSchedulesToSave(updatedSchedules));
        if (value) {
            setSelectedTimezoneKeys(value.key);
        }
    };

    const onSelectStartHour = (value?: IPickerItem) => {
        startTimeBuilder.set('hour', value?.data);
        var selected: string = startTimeBuilder.format('HH:mm').toString();

        const updatedSchedules = updateSchedulesToSaveValues(schedulesToSave, 'start', selected)

        dispatch(actions.setSchedulesToSave(updatedSchedules));
        if (value) {
            setSelectedStartHourKeys(value.key);
        }
    };
  
    const onSelectStartMinutes = (value?: IPickerItem) => {
        startTimeBuilder.set('minute', value?.data);
        var selected: string = startTimeBuilder.format('HH:mm').toString();

        const updatedSchedules = updateSchedulesToSaveValues(schedulesToSave, 'start', selected)

        dispatch(actions.setSchedulesToSave(updatedSchedules));
        if (value) {
            setSelectedStartMinuteKeys(value.key);
        }
    };
  
    const onSelectEndHour = (value?: IPickerItem) => {
        endTimeBuilder.set('hour', value?.data);
        var selected: string = endTimeBuilder.format('HH:mm').toString();

        const updatedSchedules = updateSchedulesToSaveValues(schedulesToSave, 'end', selected)

        dispatch(actions.setSchedulesToSave(updatedSchedules));
        if (value) {
            setSelectedEndHourKeys(value.key);
        }
    };

    const onSelectEndMinutes = (value?: IPickerItem) => {
        endTimeBuilder.set('minute', value?.data);
        var selected: string = endTimeBuilder.format('HH:mm').toString();
        
        const updatedSchedules = updateSchedulesToSaveValues(schedulesToSave, 'end', selected)

        dispatch(actions.setSchedulesToSave(updatedSchedules));
        if (value) {
            setSelectedEndMinutesKeys(value.key);
        }
    };

    return (
        <form className="team-settings-tile">
            <h2>
                <div className="component-title">{title}</div>
            </h2>
            <ElxPicker
                label="Days of the week"
                multiSelect={true}
                items={daysOfWeekOptions}
                disabled={false}
                onMultiSelectChange={onSelectDaysOfWeek}
                selectedKeys={selectedDaysKeys}
                calloutWidth={PANEL_INPUT_WIDTH}
                placeHolder={daysPlaceholder}
            />
            <ElxPicker
                label="Time zone"
                multiSelect={false}
                items={timeZoneOptions}
                disabled={false}
                onChange={onSelectedTimezone}
                selectedKey={selectedTimezoneKeys}
                calloutWidth={PANEL_INPUT_WIDTH}
                placeHolder={timezonePlaceholder}
            />
            <div className="timeSelectionLabel ms-fontWeight-semibold">Start time</div>
            <div className="timeSelection">
                <div aria-label="Select start time hour">
                    <ElxPicker
                        multiSelect={false}
                        items={hourOptions}
                        disabled={false}
                        onChange={onSelectStartHour}
                        selectedKey={selectedStartHourKeys}
                        calloutWidth={PANEL_INPUT_WIDTH}
                        placeHolder={startTimePlaceholder}
                        aria-label="Select start time hour"
                    />
                </div>
                <div className="timeColon">:</div>
                <div aria-label="Select start time minutes">
                    <ElxPicker
                        multiSelect={false}
                        items={minuteOptions}
                        disabled={false}
                        onChange={onSelectStartMinutes}
                        selectedKey={selectedStartMinuteKeys}
                        calloutWidth={PANEL_INPUT_WIDTH}
                        placeHolder={startTimePlaceholder}
                        aria-label="Select start time minutes"
                    />
                </div>
            </div>
            <div className="timeSelectionLabel ms-fontWeight-semibold">End time</div>
            <div className="timeSelection">
                <div aria-label="Select end time hour">
                    <ElxPicker
                        multiSelect={false}
                        items={hourOptions}
                        disabled={false}
                        onChange={onSelectEndHour}
                        selectedKey={selectedEndHourKeys}
                        calloutWidth={PANEL_INPUT_WIDTH}
                        message={'12:00' >= '23:00' ? {
                            type: InputMessageTypes.Error,
                            content: 'err msg',
                        } : undefined}
                        placeHolder={endTimePlaceholder}
                        aria-label="Select end time hour"
                    />
                </div>
                <div className="timeColon">:</div>
                <div aria-label="Select end time minutes">
                    <ElxPicker
                        multiSelect={false}
                        items={minuteOptions}
                        disabled={false}
                        onChange={onSelectEndMinutes}
                        selectedKey={selectedEndMinutesKeys}
                        calloutWidth={PANEL_INPUT_WIDTH}
                        placeHolder={endTimePlaceholder}
                        aria-label="Select end time minutes"
                    />
                </div>
            </div>
        </form>
    );
}

/**
 * Creates the array of selecteable hours
 */
function createHours(): string[] {
    const day = moment.duration(1, 'day').as('h');
    const hours = [];
    const start = moment('00:00', 'HH:mm');

    for (let i = 0; i < day; ++i) {
        hours.push(start.format('HH'));
        start.add(1, 'hour');
    }

    return hours;
}

/**
 * Creates the days of the week options for Ava
 */
function createDaysOfWeek(): IPickerItem[] {
    const options: IPickerItem[] = moment.weekdays().map((day) => {
        const abbrv = day.substring(0, 3);

        return ({
            key: abbrv,
            text: day,
            data: abbrv
        })
    });

    return options;
}

function createMinutes(): string[] {
    const hour = moment.duration(1, 'hour').as('m');
    const minutes = [];
    const start = moment('00:00', 'HH:mm');

    for (let i = 0; i < hour; i+=1) {
        minutes.push(start.format('mm'));
        start.add(1, 'm');
    }

    return minutes; 
}