import React from "react";
import {
  ElxMasterDetailPanel,
  IElxMasterDetailTabProps,
} from "@elixir/components";
import { useDispatch, useSelector } from "react-redux";

import {
  IGeneralSettingsPanelProps,
  GeneralSettings,
  DEFAULT_CASE_NOTIFICATION_TIMES,
} from "../../../Models/teamsettings.data";
import { teamSettingsSelectors } from "../../../Store/Selectors";
import { updateGeneralSettingsAction } from "../../../Store/Actions";
import { actions } from "../../../Store/Slice";
import { coreSelectors } from "../../../../Core/Store/Selectors";
import { NotificationIntervalsTab } from "./NotificationIntervalsTab/NotificationIntervalsTab";
import { AutoArchiveTab } from "./AutoArchiveTab/AutoArchiveTab";
import { ScheduleNotificationTab } from "./ScheduleNotificationTab/ScheduleNotificationTab";
import { PermissionsTab } from "./PermissionsTab/PermissionsTab";

export const GeneralSettingsPanel = (
  props: IGeneralSettingsPanelProps
): JSX.Element => {
  const [selectedTab, setSelectedTab] = React.useState<number>(
    props.selectedTabIndex
  );
  const generalSettings = useSelector(teamSettingsSelectors.getGeneralSettings);

  const dispatch = useDispatch();
  const activeTeam = useSelector(coreSelectors.getActiveTeam);
  const panelMessage = useSelector(teamSettingsSelectors.getPanelMessage);
  const caseNotificationTimes = useSelector(
    teamSettingsSelectors.getTeamSettingsCaseNotificationTimes
  );
  const scheduleNotificationChannels = useSelector(
    teamSettingsSelectors.getScheduleNotificationChannels
  );
  const autoArchiveDetails = useSelector(
    teamSettingsSelectors.getAutoArchiveDetails
  );
  const notificationDelayDetails = useSelector(
    teamSettingsSelectors.getNotificationDelayDetails
  );
  const selectedPermissions = useSelector(
    teamSettingsSelectors.getSelectedPermissions
  );
  const rolesCollection = useSelector(teamSettingsSelectors.getTeamRoles);
  const showGeneralPanel = useSelector(
    teamSettingsSelectors.getGeneralSettingsPanel
  );
  const isLoading = useSelector(coreSelectors.getPageLoading);

  const [permissions, setPermissions] = React.useState<any>(
    generalSettings?.permissions || []
  );

  React.useEffect(() => {
    setSelectedTab(props.selectedTabIndex);
  }, [props.selectedTabIndex]);

  React.useEffect(() => {
    if (generalSettings?.permissions) {
      setPermissions(generalSettings?.permissions);
    }
  }, [generalSettings]);

  React.useEffect(() => {
    if (!showGeneralPanel) {
      setSelectedTab(0);
    }
  }, [showGeneralPanel]);

  const isOpen = useSelector(teamSettingsSelectors.getGeneralSettingsPanel);

  const roles = rolesCollection.roleDetails;

  const onTabChange = (
    tabIndex: number,
    currentTab: IElxMasterDetailTabProps,
    ev?: React.SyntheticEvent<HTMLElement>
  ) => {
    setSelectedTab(tabIndex);
  };

  const onSave = () => {
    const updatedTeamSettings: GeneralSettings = {
      autoArchive: autoArchiveDetails?.isEnabled ?? false,
      autoArchiveHours: autoArchiveDetails?.hours ?? 96,
      notificationDelay: notificationDelayDetails?.isEnabled ?? false,
      notificationDelayMinutes: notificationDelayDetails?.minutes ?? 0,
      teamSettingsCaseNotificationTimes:
        caseNotificationTimes || DEFAULT_CASE_NOTIFICATION_TIMES,
      permissions: selectedPermissions ?? [],
      scheduleNotificationDetails: scheduleNotificationChannels ?? [],
    };
    dispatch(
      updateGeneralSettingsAction(activeTeam?.teamsTeamId, updatedTeamSettings)
    );
  };

  const onDismiss = () => {
    dispatch(actions.clearGeneralPanel());
    setSelectedTab(0);
    dispatch(actions.showGeneralPanel(false));
  };

  const tabs: IElxMasterDetailTabProps[] = [
    {
      key: "Notification_Intervals",
      name: "Notification intervals",
      component: <NotificationIntervalsTab {...props} />,
      primaryButton: {
        disabled: false,
      },
      secondaryButton: {
        text: "Save and close",
        onClick: onSave,
        disabled: isLoading,
      },
    },
    {
      key: "Auto_Archive",
      name: "Auto archive",
      component: <AutoArchiveTab {...props} />,
      primaryButton: {
        disabled: false,
      },
      secondaryButton: {
        text: "Save and close",
        onClick: onSave,
        disabled: isLoading,
      },
    },
    {
      key: "Permissions",
      name: "Permissions",
      disabled: false,
      component: <PermissionsTab roles={roles} permissions={permissions} />,
      primaryButton: {
        disabled: false,
      },
      secondaryButton: {
        text: "Save and close",
        disabled: isLoading,
        onClick: onSave,
      },
    },
    {
      key: "ScheduleNotifications",
      name: "Schedule notifications",
      component: <ScheduleNotificationTab {...props} />,
      primaryButton: {
        disabled: false,
      },
      secondaryButton: {
        text: "Save and close",
        onClick: onSave,
        disabled: isLoading,
      },
    },
  ];

  return (
    <ElxMasterDetailPanel
      isOpen={isOpen}
      selectedTabIndex={selectedTab}
      headerText="Team settings"
      subHeaderText="Use this form to configure team settings"
      tabs={tabs}
      onDismiss={onDismiss}
      onTabChange={onTabChange}
      message={panelMessage}
    />
  );
};
