import { put } from '@redux-saga/core/effects';
import {UserDetails, AvaPermissions, permissionDenied, avaContainers} from '../Modules/Core/core.data';
import {
    setDisplayError
} from '../Modules/Core/Store/Slice';
import { IScope } from '@elixir/fx';

export function scopedAuthenticationValue(user: UserDetails | undefined, scope: IScope): any {
    let permission: AvaPermissions | undefined;
    if (scope.name.includes(avaContainers.AVA_DASHBOARD)) {
        permission = AvaPermissions.viewDashboard;
    }
    if (scope.name.includes(avaContainers.AVA_GENERAL_SETTINGS)) {
        permission = AvaPermissions.viewTeamSettings;
    }
    if (scope.name.includes(avaContainers.AVA_USER_SETTINGS)) {
        permission = AvaPermissions.viewUserManagement;
    }
    if (scope.name.includes(avaContainers.AVA_ROLE_SETTINGS)) {
        permission = AvaPermissions.viewTeamRoles;
    }

    if(user && !user?.isAdmin && permission && !user?.role.viewPermissions?.find(vp => vp.permission === permission)?.isEnabled) {
        return false;
    }
    return true;
}

export function* scopedAuthentication(user: UserDetails | undefined, scope: IScope): any {
    let permission: AvaPermissions | undefined;
    if (scope.name.includes(avaContainers.AVA_DASHBOARD)) {
        permission = AvaPermissions.viewDashboard;
    }
    if (scope.name.includes(avaContainers.AVA_GENERAL_SETTINGS)) {
        permission = AvaPermissions.viewTeamSettings;
    }
    if (scope.name.includes(avaContainers.AVA_USER_SETTINGS)) {
        permission = AvaPermissions.viewUserManagement;
    }
    if (scope.name.includes(avaContainers.AVA_ROLE_SETTINGS)) {
        permission = AvaPermissions.viewTeamRoles;
    }

    if(user && !user?.isAdmin && permission && !user?.role.viewPermissions?.find(vp => vp.permission === permission)?.isEnabled) {
        yield put(setDisplayError({display: true, message: permissionDenied}));
        return;
    }
}