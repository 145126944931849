// eslint-disable-next-line react-hooks/exhaustive-deps
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RoleDetails, PermissionDetails } from '../../../../../Core/core.data';
import { ElxDropdown, SelectedKeyTypes } from '@elixir/components';
import { IDropdownOption } from '@fluentui/react';
import { actions } from '../../../../Store/Slice';
import { teamSettingsSelectors } from '../../../../Store/Selectors';
import '../GeneralSettingsPanel.scss';

interface IPermissionsTabProps {
    permissions: PermissionDetails[],
    roles: RoleDetails[],
}

export const PermissionsTab = (props: IPermissionsTabProps) => {
    const dispatch = useDispatch();

    const [selectedOptions, setSelectedOptions] = React.useState<Map<string, string[]>>(new Map());
    const [permissionOptions, setPermissionOptions] = React.useState<Map<string, IDropdownOption[]>>(new Map());

    const selectedPermissions = useSelector(teamSettingsSelectors.getSelectedPermissions) || props.permissions;

    React.useEffect(() => {
        const options: Map<string, IDropdownOption[]> = new Map();
        const optionsSelected: Map<string, string[]> = new Map();
        
        selectedPermissions.forEach(perm => {
            let dropdownOptions = options.get(perm.roleName) || new Array<IDropdownOption>();
            dropdownOptions.push(
                {
                    key: `${perm.roleName}_${perm.description}`,
                    text: perm.description,
                    data: perm
                }
            );
            options.set(perm.roleName, dropdownOptions);
    
            if(perm.isEnabled) {
                let selected = optionsSelected.get(perm.roleName) || new Array<string>();
                selected.push(`${perm.roleName}_${perm.description}`);
                optionsSelected.set(perm.roleName, selected);
            }
        });

        setPermissionOptions(options);
        setSelectedOptions(optionsSelected);
    }, [selectedPermissions]);

    const onSelect = (selectedKeys?: SelectedKeyTypes) => {
        let currentOptions = new Map(selectedOptions);

        // Parse the role from the selected keys.
        const firstEntry = selectedKeys && selectedKeys[0] ? selectedKeys[0] as string : '';
        const expression = firstEntry.match('.*(?=_)') || [];
        const selectedRole = expression[0] || '';

        const keysSelected = (selectedKeys ?? []) as string[];

        // Selected permission id's
        const selectedPerms = permissionOptions.get(selectedRole)?.filter(option => 
            keysSelected.includes(option.key as string)).map(option => option.data.permission as number) || [];

        // New permissions that needs to be set.
        let updatedPermissions = new Array<PermissionDetails>();
        permissionOptions.forEach(options => {
            let permissions: PermissionDetails[] = options.map(o => o.data);
            updatedPermissions = [...updatedPermissions, ...permissions];
        });

        let index = 0;
        for (index = 0; index < updatedPermissions.length; index++) {
            let perm = updatedPermissions[index];
            if (perm.roleName === selectedRole) {
                if (selectedPerms.includes(perm.permission)) {
                    updatedPermissions[index] = { ...updatedPermissions[index], isEnabled: true };
                } else {
                    updatedPermissions[index] = { ...updatedPermissions[index], isEnabled: false };
                }
            }
        }

        currentOptions.set(selectedRole, (selectedKeys ?? []) as string[]);
        setSelectedOptions(currentOptions);
        dispatch(actions.setSelectedPermissions(updatedPermissions));
    };

    return(
        <form className='general-settings-tile' >
            {renderDropdowns(props.roles, permissionOptions, selectedOptions, onSelect)}
        </form>
    );
}

/**
 * Method to render dropdowns based on the roles and permissions.
 * @param roles All the existing roles for a team.
 * @param permissionOptions Permission options for each role.
 * @param selectedOptions Selected options for each role.
 * @param onSelect function to execute on select of dropdown.
 */
function renderDropdowns(roles: RoleDetails[], permissionOptions: Map<string, IDropdownOption[]>,
    selectedOptions: Map<string, string[]>, onSelect: (selectedKeys?: SelectedKeyTypes) => void): JSX.Element[] {

    const dropdowns: JSX.Element[] = [];

    roles.forEach((role: RoleDetails) => {
        const label = `What permission do ${role.name} have?`;

        dropdowns.push(<ElxDropdown
            label={label}
            multiSelect={true}
            options={permissionOptions.get(role.name) ?? []}
            disabled={false}
            enableSelectAll={true}
            selectedKeys={selectedOptions.get(role.name)}
            onMultiSelectChange={onSelect}
        />
        );
    });

    return dropdowns;
}
