import React from 'react';
import './QuestionsPendingSection.scss';
import { QuestionsPending } from '../../Models/dashboard.data';
import { CaseOfferingCount } from '../../../Core/core.data';

/**
 * Properties of questions pending
 */
interface IQuestionsPendingProps {
    /**
     * A map of severity to CaseOfferingCount
     */
    questionsPending: QuestionsPending,
    questionsPendingWithExpiredTimers: QuestionsPending,
}

/**
 * The questions pending section seen on the dashboard
 * @param props Questions pending takes in a map mapping of severity to CaseOfferingCount
 */
export const QuestionsPendingSection = (props: IQuestionsPendingProps): JSX.Element => {

    const questionsPending = props.questionsPending;
    const expiredCount = props.questionsPendingWithExpiredTimers;

    return (
        <div className="ms-Grid-col ms-sm12" dir="ltr">
            <div className="ms-Grid-row ms-sm12 tile-section">
                <div className="ms-Grid-row ms-fontSize-13">
                    <h2 className="header">Questions pending</h2>
                </div>
                <div className="ms-Grid-row">
                    <div>
                        {renderTile("1", questionsPending["1"], expiredCount["1"])}
                        {renderTile("A", questionsPending.a, expiredCount.a)}
                        {renderTile("B", questionsPending.b, expiredCount.b)}
                        {renderTile("C", questionsPending.c, expiredCount.c)}
                    </div>
                </div>
            </div>
        </div>);
}

function renderTile(tileTitle: string, questionsPending: CaseOfferingCount, expiredCount: CaseOfferingCount): JSX.Element {
    const arr = questionsPending?.arr || 0;
    const premier = questionsPending?.premier || 0;
    const broad = questionsPending?.broad || 0;

    const expiredArr = expiredCount?.arr > 0 ? <span className="expired-count"> ({expiredCount.arr} expired)</span> : undefined;
    const expiredBroad = expiredCount?.broad > 0 ? <span className="expired-count"> ({expiredCount.broad} expired)</span> : undefined;
    const expiredPremier = expiredCount?.premier > 0 ? <span className="expired-count"> ({expiredCount.premier} expired)</span> : undefined;

    return (
      <div className="ms-Grid-col ms-md12 ms-lg6 ms-xxl3 tile" data-testid="severity-card">
        <div className="ms-fontSize-13 tile-content">
          <h2 className="ms-fontWeight-semibold">Sev {tileTitle}</h2>
          <div className="tile-container">
            <div className="tile-description">
              <span data-testid="arr">ARR: {arr}</span> 
              {expiredArr}
            </div>
            <div className="tile-description">
              <span data-testid="premier">Premier: {premier}</span> 
              {expiredPremier}
            </div>
            <div className="tile-description">
              <span data-testid="broad">Broad Commercial: {broad}</span>
              {expiredBroad}
            </div>
          </div>
        </div>
      </div>
    );
};
