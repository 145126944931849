import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IDropdownOption } from "@fluentui/react";
import { ElxTextField, ElxDropdown } from "@elixir/components";
import {
  IGeneralSettingsPanelProps,
  NotificationDelayDetails,
} from "../../../../Models/teamsettings.data";
import { teamSettingsSelectors } from "../../../../Store/Selectors";
import { actions } from "../../../../Store/Slice";
import _ from "lodash";

import "./NotificationIntervalsTab.scss";
import "../GeneralSettingsPanel.scss";

export const NotificationIntervalsTab = (
  props: IGeneralSettingsPanelProps
): JSX.Element => {
  const dispatch = useDispatch();
  const textFieldPlaceholderText = "Enter #";
  const minValue = 1;
  const maxValue = 100000;
  const notificationDelayDetails = useSelector(
    teamSettingsSelectors.getNotificationDelayDetails
  );
  const currentNotificationDelayDetails = notificationDelayDetails || {
    isEnabled: props.settings?.notificationDelay || false,
    minutes: props.settings?.notificationDelayMinutes || 0,
  };

  const teamSettingsCaseNotificationTimes = _.cloneDeep(
    useSelector(teamSettingsSelectors.getTeamSettingsCaseNotificationTimes) ||
      props.settings?.teamSettingsCaseNotificationTimes
  );

  function onChange_Sev1_Chat(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    if (teamSettingsCaseNotificationTimes) {
      teamSettingsCaseNotificationTimes.chat.sev1 = parseInt(newValue || "0");
      dispatch(
        actions.setTeamSettingsCaseNotificationTimes(
          teamSettingsCaseNotificationTimes
        )
      );
    }
  }

  function onChange_Sev1_ARR(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    if (teamSettingsCaseNotificationTimes) {
      teamSettingsCaseNotificationTimes.arr.sev1 = parseInt(newValue || "0");
      dispatch(
        actions.setTeamSettingsCaseNotificationTimes(
          teamSettingsCaseNotificationTimes
        )
      );
    }
  }

  function onChange_Sev1_Premier(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    if (teamSettingsCaseNotificationTimes) {
      teamSettingsCaseNotificationTimes.premier.sev1 = parseInt(
        newValue || "0"
      );
      dispatch(
        actions.setTeamSettingsCaseNotificationTimes(
          teamSettingsCaseNotificationTimes
        )
      );
    }
  }

  function onChange_Sev1_BC(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    if (teamSettingsCaseNotificationTimes) {
      teamSettingsCaseNotificationTimes.bc.sev1 = parseInt(newValue || "0");
      dispatch(
        actions.setTeamSettingsCaseNotificationTimes(
          teamSettingsCaseNotificationTimes
        )
      );
    }
  }

  function onChange_SevA_Chat(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    if (teamSettingsCaseNotificationTimes) {
      teamSettingsCaseNotificationTimes.chat.sevA = parseInt(newValue || "0");
      dispatch(
        actions.setTeamSettingsCaseNotificationTimes(
          teamSettingsCaseNotificationTimes
        )
      );
    }
  }

  function onChange_SevA_ARR(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    if (teamSettingsCaseNotificationTimes) {
      teamSettingsCaseNotificationTimes.arr.sevA = parseInt(newValue || "0");
      dispatch(
        actions.setTeamSettingsCaseNotificationTimes(
          teamSettingsCaseNotificationTimes
        )
      );
    }
  }

  function onChange_SevA_Premier(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    if (teamSettingsCaseNotificationTimes) {
      teamSettingsCaseNotificationTimes.premier.sevA = parseInt(
        newValue || "0"
      );
      dispatch(
        actions.setTeamSettingsCaseNotificationTimes(
          teamSettingsCaseNotificationTimes
        )
      );
    }
  }

  function onChange_SevA_BC(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    if (teamSettingsCaseNotificationTimes) {
      teamSettingsCaseNotificationTimes.bc.sevA = parseInt(newValue || "0");
      dispatch(
        actions.setTeamSettingsCaseNotificationTimes(
          teamSettingsCaseNotificationTimes
        )
      );
    }
  }

  function onChange_SevB_Chat(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    if (teamSettingsCaseNotificationTimes) {
      teamSettingsCaseNotificationTimes.chat.sevB = parseInt(newValue || "0");
      dispatch(
        actions.setTeamSettingsCaseNotificationTimes(
          teamSettingsCaseNotificationTimes
        )
      );
    }
  }

  function onChange_SevB_ARR(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    if (teamSettingsCaseNotificationTimes) {
      teamSettingsCaseNotificationTimes.arr.sevB = parseInt(newValue || "0");
      dispatch(
        actions.setTeamSettingsCaseNotificationTimes(
          teamSettingsCaseNotificationTimes
        )
      );
    }
  }

  function onChange_SevB_Premier(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    if (teamSettingsCaseNotificationTimes) {
      teamSettingsCaseNotificationTimes.premier.sevB = parseInt(
        newValue || "0"
      );
      dispatch(
        actions.setTeamSettingsCaseNotificationTimes(
          teamSettingsCaseNotificationTimes
        )
      );
    }
  }

  function onChange_SevB_BC(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    if (teamSettingsCaseNotificationTimes) {
      teamSettingsCaseNotificationTimes.bc.sevB = parseInt(newValue || "0");
      dispatch(
        actions.setTeamSettingsCaseNotificationTimes(
          teamSettingsCaseNotificationTimes
        )
      );
    }
  }

  function onChange_SevC_Chat(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    if (teamSettingsCaseNotificationTimes) {
      teamSettingsCaseNotificationTimes.chat.sevC = parseInt(newValue || "0");
      dispatch(
        actions.setTeamSettingsCaseNotificationTimes(
          teamSettingsCaseNotificationTimes
        )
      );
    }
  }

  function onChange_SevC_ARR(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    if (teamSettingsCaseNotificationTimes) {
      teamSettingsCaseNotificationTimes.arr.sevC = parseInt(newValue || "0");
      dispatch(
        actions.setTeamSettingsCaseNotificationTimes(
          teamSettingsCaseNotificationTimes
        )
      );
    }
  }

  function onChange_SevC_Premier(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    if (teamSettingsCaseNotificationTimes) {
      teamSettingsCaseNotificationTimes.premier.sevC = parseInt(
        newValue || "0"
      );
      dispatch(
        actions.setTeamSettingsCaseNotificationTimes(
          teamSettingsCaseNotificationTimes
        )
      );
    }
  }

  function onChange_SevC_BC(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    if (teamSettingsCaseNotificationTimes) {
      teamSettingsCaseNotificationTimes.bc.sevC = parseInt(newValue || "0");
      dispatch(
        actions.setTeamSettingsCaseNotificationTimes(
          teamSettingsCaseNotificationTimes
        )
      );
    }
  }

  function onChange_High_Community(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    if (teamSettingsCaseNotificationTimes) {
      teamSettingsCaseNotificationTimes.community.high = parseInt(
        newValue || "0"
      );
      dispatch(
        actions.setTeamSettingsCaseNotificationTimes(
          teamSettingsCaseNotificationTimes
        )
      );
    }
  }

  function onChange_High_NoId(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    if (teamSettingsCaseNotificationTimes) {
      teamSettingsCaseNotificationTimes.noID.high = parseInt(newValue || "0");
      dispatch(
        actions.setTeamSettingsCaseNotificationTimes(
          teamSettingsCaseNotificationTimes
        )
      );
    }
  }

  function onChange_Normal_Community(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    if (teamSettingsCaseNotificationTimes) {
      teamSettingsCaseNotificationTimes.community.normal = parseInt(
        newValue || "0"
      );
      dispatch(
        actions.setTeamSettingsCaseNotificationTimes(
          teamSettingsCaseNotificationTimes
        )
      );
    }
  }

  function onChange_Normal_NoId(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    if (teamSettingsCaseNotificationTimes) {
      teamSettingsCaseNotificationTimes.noID.normal = parseInt(newValue || "0");
      dispatch(
        actions.setTeamSettingsCaseNotificationTimes(
          teamSettingsCaseNotificationTimes
        )
      );
    }
  }

  React.useEffect(() => {
    if (notificationDelayDetails) {
      dispatch(actions.setNotificationDelayDetails(notificationDelayDetails));
    }
  }, [dispatch, notificationDelayDetails]);

  const notificationDelayOptions: IDropdownOption[] = [
    {
      key: "notify_delay_yes",
      data: "true",
      title: "Yes",
      text: "Yes",
      selected: currentNotificationDelayDetails.isEnabled,
    },
    {
      key: "notify_delay_no",
      data: "false",
      title: "No",
      text: "No",
      selected: currentNotificationDelayDetails.isEnabled === false,
    },
  ];

  const onSelectNotificationDelay = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ) => {
    const enabled = option?.data === "true";
    let notificationDetails = {
      isEnabled: enabled,
      minutes: currentNotificationDelayDetails.minutes,
    } as NotificationDelayDetails;
    dispatch(actions.setNotificationDelayDetails(notificationDetails));
  };

  const getErrorMessage = (value: string): string => {
    let message = "";

    if (value === "") {
      return "Required when notification delay is enabled";
    } else {
      const numericValue = parseInt(value);
      if (numericValue <= 0) {
        return "Value should be greater than 0";
      }

      let notificationDetails = {
        isEnabled: currentNotificationDelayDetails.isEnabled,
        minutes: numericValue,
      } as NotificationDelayDetails;
      dispatch(actions.setNotificationDelayDetails(notificationDetails));
    }
    return message;
  };

  return (
    <form className="notification-intervals general-settings-tile">
      <div className="intervals-tab-tile">
        <ElxDropdown
          label="Do you want Ava to wait before sending the first notification to experts?"
          options={notificationDelayOptions}
          emptyOption={false}
          disabled={false}
          onChange={onSelectNotificationDelay}
        />
      </div>
      <div className="intervals-tab-tile">
        <ElxTextField
          label="How long should Ava wait before sending the first notification? (in minutes)"
          disabled={!currentNotificationDelayDetails.isEnabled}
          required={currentNotificationDelayDetails.isEnabled}
          validateOnLoad={false}
          onGetErrorMessage={getErrorMessage}
          defaultValue={String(currentNotificationDelayDetails.minutes)}
          type="number"
        />
      </div>
      <div className="intervals-tab-title" role="heading" aria-level={2}>
        Set notification intervals (in minutes)
      </div>
      <div
        className="intervals-tab-table"
        role="table"
        aria-label="Set notification intervals"
      >
        <div role="row">
          <div role="columnheader">Severity</div>
          <div role="columnheader">Chat</div>
          <div role="columnheader">ARR</div>
          <div role="columnheader">Premier</div>
          <div role="columnheader">Broad Commercial</div>
        </div>
        <div role="row">
          <div role="cell">Sev 1</div>
          <div role="cell">
            <ElxTextField
              required={true}
              ariaLabel="Chat Sev 1"
              type="number"
              min={minValue}
              max={maxValue}
              defaultValue={String(
                teamSettingsCaseNotificationTimes?.chat.sev1
              )}
              placeholder={textFieldPlaceholderText}
              onChange={onChange_Sev1_Chat}
            />
          </div>
          <div role="cell">
            <ElxTextField
              required={true}
              ariaLabel="ARR Sev 1"
              type="number"
              min={minValue}
              max={maxValue}
              defaultValue={String(teamSettingsCaseNotificationTimes?.arr.sev1)}
              placeholder={textFieldPlaceholderText}
              onChange={onChange_Sev1_ARR}
            />
          </div>
          <div role="cell">
            <ElxTextField
              required={true}
              ariaLabel="Premier Sev 1"
              type="number"
              min={minValue}
              max={maxValue}
              defaultValue={String(
                teamSettingsCaseNotificationTimes?.premier.sev1
              )}
              placeholder={textFieldPlaceholderText}
              onChange={onChange_Sev1_Premier}
            />
          </div>
          <div role="cell">
            <ElxTextField
              required={true}
              ariaLabel="Broad Commercial Sev 1"
              type="number"
              min={minValue}
              max={maxValue}
              defaultValue={String(teamSettingsCaseNotificationTimes?.bc.sev1)}
              placeholder={textFieldPlaceholderText}
              onChange={onChange_Sev1_BC}
            />
          </div>
        </div>
        <div role="row">
          <div role="cell">Sev A</div>
          <div role="cell">
            <ElxTextField
              required={true}
              ariaLabel="Chat Sev A"
              type="number"
              min={minValue}
              max={maxValue}
              defaultValue={String(
                teamSettingsCaseNotificationTimes?.chat.sevA
              )}
              placeholder={textFieldPlaceholderText}
              onChange={onChange_SevA_Chat}
            />
          </div>
          <div role="cell">
            <ElxTextField
              required={true}
              ariaLabel="ARR Sev A"
              type="number"
              min={minValue}
              max={maxValue}
              defaultValue={String(teamSettingsCaseNotificationTimes?.arr.sevA)}
              placeholder={textFieldPlaceholderText}
              onChange={onChange_SevA_ARR}
            />
          </div>
          <div role="cell">
            <ElxTextField
              required={true}
              ariaLabel="Premier Sev A"
              type="number"
              min={minValue}
              max={maxValue}
              defaultValue={String(
                teamSettingsCaseNotificationTimes?.premier.sevA
              )}
              placeholder={textFieldPlaceholderText}
              onChange={onChange_SevA_Premier}
            />
          </div>
          <div role="cell">
            <ElxTextField
              required={true}
              ariaLabel="Broad Commercial Sev A"
              type="number"
              min={minValue}
              max={maxValue}
              defaultValue={String(teamSettingsCaseNotificationTimes?.bc.sevA)}
              placeholder={textFieldPlaceholderText}
              onChange={onChange_SevA_BC}
            />
          </div>
        </div>
        <div role="row">
          <div role="cell">Sev B</div>
          <div role="cell">
            <ElxTextField
              required={true}
              ariaLabel="Chat Sev B"
              type="number"
              min={minValue}
              max={maxValue}
              defaultValue={String(
                teamSettingsCaseNotificationTimes?.chat.sevB
              )}
              placeholder={textFieldPlaceholderText}
              onChange={onChange_SevB_Chat}
            />
          </div>
          <div role="cell">
            <ElxTextField
              required={true}
              ariaLabel="ARR Sev B"
              type="number"
              min={minValue}
              max={maxValue}
              defaultValue={String(teamSettingsCaseNotificationTimes?.arr.sevB)}
              placeholder={textFieldPlaceholderText}
              onChange={onChange_SevB_ARR}
            />
          </div>
          <div role="cell">
            <ElxTextField
              required={true}
              ariaLabel="Premier Sev B"
              type="number"
              min={minValue}
              max={maxValue}
              defaultValue={String(
                teamSettingsCaseNotificationTimes?.premier.sevB
              )}
              placeholder={textFieldPlaceholderText}
              onChange={onChange_SevB_Premier}
            />
          </div>
          <div role="cell">
            <ElxTextField
              required={true}
              ariaLabel="Broad Commercial Sev B"
              type="number"
              min={minValue}
              max={maxValue}
              defaultValue={String(teamSettingsCaseNotificationTimes?.bc.sevB)}
              placeholder={textFieldPlaceholderText}
              onChange={onChange_SevB_BC}
            />
          </div>
        </div>
        <div role="row">
          <div role="cell">Sev C</div>
          <div role="cell">
            <ElxTextField
              required={true}
              ariaLabel="Chat Sev C"
              type="number"
              min={minValue}
              max={maxValue}
              defaultValue={String(
                teamSettingsCaseNotificationTimes?.chat.sevC
              )}
              placeholder={textFieldPlaceholderText}
              onChange={onChange_SevC_Chat}
            />
          </div>
          <div role="cell">
            <ElxTextField
              required={true}
              ariaLabel="ARR Sev C"
              type="number"
              min={minValue}
              max={maxValue}
              defaultValue={String(teamSettingsCaseNotificationTimes?.arr.sevC)}
              placeholder={textFieldPlaceholderText}
              onChange={onChange_SevC_ARR}
            />
          </div>
          <div role="cell">
            <ElxTextField
              required={true}
              ariaLabel="Premier Sev C"
              type="number"
              min={minValue}
              max={maxValue}
              defaultValue={String(
                teamSettingsCaseNotificationTimes?.premier.sevC
              )}
              placeholder={textFieldPlaceholderText}
              onChange={onChange_SevC_Premier}
            />
          </div>
          <div role="cell">
            <ElxTextField
              required={true}
              ariaLabel="Broad Commercial Sev C"
              type="number"
              min={minValue}
              max={maxValue}
              defaultValue={String(teamSettingsCaseNotificationTimes?.bc.sevC)}
              placeholder={textFieldPlaceholderText}
              onChange={onChange_SevC_BC}
            />
          </div>
        </div>
      </div>

      <div className="intervals-tab-title" role="heading" aria-level={2}>
        Set notification intervals for cases without a Support Request (in
        minutes)
      </div>
      <div
        className="intervals-tab-table"
        role="table"
        aria-label="Set notification intervals for cases without a Support Request"
      >
        <div role="row">
          <div role="columnheader">Priority</div>
          <div role="columnheader">Community</div>
          <div role="columnheader">No ID</div>
        </div>
        <div role="row">
          <div role="cell">High</div>
          <div role="cell">
            <ElxTextField
              required={true}
              ariaLabel="High Community"
              type="number"
              min={minValue}
              max={maxValue}
              defaultValue={String(
                teamSettingsCaseNotificationTimes?.community.high
              )}
              placeholder={textFieldPlaceholderText}
              onChange={onChange_High_Community}
            />
          </div>
          <div role="cell">
            <ElxTextField
              required={true}
              ariaLabel="High No ID"
              type="number"
              min={minValue}
              max={maxValue}
              defaultValue={String(
                teamSettingsCaseNotificationTimes?.noID.high
              )}
              placeholder={textFieldPlaceholderText}
              onChange={onChange_High_NoId}
            />
          </div>
        </div>
        <div role="row">
          <div role="cell">Normal</div>
          <div role="cell">
            <ElxTextField
              required={true}
              ariaLabel="Normal Community"
              type="number"
              min={minValue}
              max={maxValue}
              defaultValue={String(
                teamSettingsCaseNotificationTimes?.community.normal
              )}
              placeholder={textFieldPlaceholderText}
              onChange={onChange_Normal_Community}
            />
          </div>
          <div role="cell">
            <ElxTextField
              required={true}
              ariaLabel="Normal No ID"
              type="number"
              min={minValue}
              max={maxValue}
              defaultValue={String(
                teamSettingsCaseNotificationTimes?.noID.normal
              )}
              placeholder={textFieldPlaceholderText}
              onChange={onChange_Normal_NoId}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
