import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ElxMasterDetailPanel, IElxMasterDetailTabProps, PanelSize } from '@elixir/components';
import _ from "lodash";

import {
  ISchedulePanelProps,
  ScheduleDetailsCollection,
  ScheduleDetails,
} from "../../Models/teamsettings.data";
import { teamSettingsSelectors } from "../../Store/Selectors";
import { saveSchedules } from "../../Store/Actions";
import { actions } from "../../Store/Slice";
import { BasicDetails } from "./BasicDetailsTab/BasicDetails";
import { ScheduleDetailsTab } from "./ScheduleDetailsTab/ScheduleDetailsTab";
import { coreSelectors } from "../../../Core/Store/Selectors";
import { createScheduleDisplay } from "./Schedules.utility";

export const SchedulesPanel = (props: ISchedulePanelProps): JSX.Element => {
  let userDisplay = "";
  let title = { headerElement: <></> };

  if (props.schedule) {
    const uniqueUserSet = new Set(props.schedule.map((s) => s.user));
    userDisplay = createScheduleDisplay(
      props.schedule[0].user,
      Array.from(uniqueUserSet).length
    );
  }

  const team = useSelector(coreSelectors.getActiveTeam);
  if (props.schedule) {
    title.headerElement = <h1>{`Edit schedule for ${userDisplay}`}</h1>;
  } else {
    title.headerElement = <h1>{"Create schedule"}</h1>;
  }

  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const [scheduleDetailsDisabled, setScheduleDetailsDisabled] =
    React.useState<boolean>(true);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (props.schedule) {
      const incomingSchedules: ScheduleDetailsCollection[] = [];

      props.schedule.forEach((s) => {
        incomingSchedules.push(s.data);
      });

      dispatch(actions.setSchedulesToSave(incomingSchedules));
    } else {
      dispatch(actions.setSchedulesToSave([]));
    }
  }, [props.schedule, dispatch]);

  const isPageLoading = useSelector(coreSelectors.getPageLoading);

  const panelMessage = useSelector(teamSettingsSelectors.getPanelMessage);
  const schedulesToSave = useSelector(teamSettingsSelectors.getSchedulesToSave);
  const unselectedSchedules = useSelector(
    teamSettingsSelectors.getUnselectedSchedules
  );

  const isOpen = useSelector(teamSettingsSelectors.getSchedulePanel);
  const [enabled, setEnabled] = React.useState<boolean | undefined>(false);
  const saveEnabled =
    (useSelector(teamSettingsSelectors.canSaveSchedule) || false) &&
    !isPageLoading;

  React.useEffect(() => {
    if (schedulesToSave) {
      setScheduleDetailsDisabled(!enabled);
    }
  }, [enabled, schedulesToSave]);

  React.useEffect(() => {
    const isEnabled =
      schedulesToSave &&
      schedulesToSave.length > 0 &&
      schedulesToSave?.every(
        (s) =>
          s.user &&
          s.scheduleDetails &&
          s.scheduleDetails.length > 0 &&
          s.scheduleDetails.every(
            (details) =>
              details.channel && details.offering && details.scheduleType !== ""
          )
      );
    setEnabled(isEnabled);
  }, [schedulesToSave, setEnabled]);

  const onDismiss = () => {
    dispatch(actions.clearSchedulePanel());

    setSelectedTab(0);
    setScheduleDetailsDisabled(true);
  };

  const onTabChange = (
    tabIndex: number,
    currentTab: IElxMasterDetailTabProps,
    ev?: React.SyntheticEvent<HTMLElement>
  ) => {
    setSelectedTab(tabIndex);
  };

  React.useEffect(() => {
    setSelectedTab(0);
  }, [isOpen]);

  const onSave = () => {
    dispatch(
      saveSchedules(
        team?.teamsTeamId,
        { schedules: schedulesToSave },
        { schedules: unselectedSchedules }
      )
    );
  };

  const tabs: IElxMasterDetailTabProps[] = [
    {
      key: "User_and_Channel",
      name: "Basic",
      component: <BasicDetails {...props} />,
      primaryButton: {
        text: "Next",
        disabled: !enabled,
        primary: true,
        onClickCapture: () => {
          setScheduleDetailsDisabled(false);
        },
      },
    },
    {
      key: "Schedule_Details",
      name: "Schedule details",
      disabled: scheduleDetailsDisabled,
      component: <ScheduleDetailsTab {...props} />,
      primaryButton: {
        text: "Save",
        disabled: !saveEnabled,
        primary: true,
        onClick: onSave,
      },
    },
  ];

  return (
    <ElxMasterDetailPanel
      isOpen={isOpen}
      selectedTabIndex={selectedTab}
      onRenderHeader={() => title.headerElement}
      tabs={tabs}
      size={PanelSize.large}
      onDismiss={onDismiss}
      onTabChange={onTabChange}
      isLoading={isPageLoading}
      message={panelMessage}
    />
  );
};

/**
 * Assigns passed value to the specified fields of the given list.
 *
 * @param schedulesToSave What schedules are being saved
 * @param selected What are the selected values being saved
 * @param field What field of the schedules are being updated
 */
export function updateSchedulesToSaveValues(
  schedulesToSave: ScheduleDetailsCollection[],
  field: keyof ScheduleDetails,
  selected: string
): ScheduleDetailsCollection[] {
  const updatedSchedules: ScheduleDetailsCollection[] =
    _.cloneDeep(schedulesToSave) || [];

  updatedSchedules?.forEach((s) => {
    s.scheduleDetails.forEach((details: ScheduleDetails) => {
      if (
        field === "daysOfWeek" ||
        field === "timeZone" ||
        field === "start" ||
        field === "end"
      ) {
        details[field] = selected;
      }
    });
  });

  return updatedSchedules;
}
