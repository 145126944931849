import React from "react";
import { ElxPanel, PanelSize, IContainerAction } from '@elixir/components';

import { Dispatch } from "redux";
import { useDispatch, useSelector } from 'react-redux';
import { teamSettingsSelectors } from "../../Store/Selectors";
import { actions } from '../../Store/Slice';
import { ISchedulePanelProps } from "../../Models/teamsettings.data";
import { IcmOnCallSchedule } from "../../Components/IcmOnCallSchedule/IcmOnCallSchedule";
import './ReviewIcmSchedulePanel.scss';

/**
 * This panel is used to review the user's upcoming IcM on-call schedule.
 * @param props Initial properties to be set in the panel.
 */
export const ReviewIcmSchedulePanel = (props: ISchedulePanelProps): JSX.Element => {
    const showPanel = useSelector(teamSettingsSelectors.getShowReviewIcmSchedulePanel);
    const dispatch = useDispatch();
    let userAndTeamText =  'IcM team info not found';
    let details = null;
    let icmScheduleProps = null;
    
    if (props.schedule && props.schedule.length > 0) {
        details = props.schedule[0].data.scheduleDetails[0];

        if (details.icmSchedule?.icmOnCallShifts && details.icmSchedule?.icmOnCallShifts?.length > 0) {
            userAndTeamText = `${props.schedule[0].user} - ${details.icmSchedule?.icmTeam?.tenant.name}/${details.icmSchedule?.icmTeam?.name}/${details.icmSchedule?.icmOnCallRole}`;
        }
        else {
            userAndTeamText = `${props.schedule[0].user} has no scheduled ${details.icmSchedule?.icmOnCallRole} on-call shifts on ${details.icmSchedule?.icmTeam?.tenant.name}/${details.icmSchedule?.icmTeam?.name}.`;
        }
    
        icmScheduleProps = {
            title: userAndTeamText,
            icmOnCallShifts: details.icmSchedule?.icmOnCallShifts
        };
    }

    return (
        <ElxPanel
            className="icm-schedule-panel"
            headerText="View Schedule"
            subHeaderText="See the upcoming scheduled times from IcM"
            isOpen={showPanel}
            size={PanelSize.medium}
            actions={renderPanelActions(dispatch)}
            onDismiss={() => onDismiss(dispatch)}
        >
            <IcmOnCallSchedule {...icmScheduleProps} />
        </ElxPanel>
    );
}

/**
 * Function to render panel action buttons.
 * @param dispatch Dispatch function.
 */
function renderPanelActions(dispatch: Dispatch): IContainerAction[] {
    return [
        {
            key: '1',
            text: 'Close',
            onClick: () => onDismiss(dispatch)
        },
    ];
}

/**
 * This functions dismisses the panel.
 */
function onDismiss(dispatch: Dispatch) {
    dispatch(actions.showReviewIcmSchedulePanel(false));
}